var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "img-upload-box" },
    [
      _c(
        "el-dialog",
        {
          attrs: { title: "查看", width: "50%", visible: _vm.show },
          on: {
            "update:visible": function ($event) {
              _vm.show = $event
            },
            close: _vm.closeDialog,
          },
        },
        [
          _c("div", { staticClass: "up-img-box" }, [
            _c("div", { staticClass: "tip-box" }, [
              _c("p", [
                _vm._v(
                  "当前裂变商品数 " +
                    _vm._s(_vm.$parent.splitNumber) +
                    "，已选" +
                    _vm._s(_vm.linkImgList.length) +
                    " 张图片"
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  " 如已选图片数小于裂变商品数，剩下的裂变商品会取原商品的第一张图片"
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "如已选图片数大于裂变商品数，飞鸟会自动截取裂变商品对应数量的图片"
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "img-box" }, [
              _c(
                "div",
                { staticClass: "img-item" },
                _vm._l(_vm.linkImgList, function (item) {
                  return _c("img", {
                    staticClass: "img-pic",
                    staticStyle: { "object-fit": "contain" },
                    attrs: { src: item.thumbUrl, alt: "" },
                  })
                }),
                0
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
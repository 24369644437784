"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addAuthCodeApi = addAuthCodeApi;
exports.applyRequest = applyRequest;
exports.bindStoreApi = bindStoreApi;
exports.bindStorePreApi = bindStorePreApi;
exports.checkLoginStatusApi = checkLoginStatusApi;
exports.delStoreApi = delStoreApi;
exports.deleteStroeApi = deleteStroeApi;
exports.extensionReport = extensionReport;
exports.getAuthCodeApi = getAuthCodeApi;
exports.getCreateUrlApi = getCreateUrlApi;
exports.getIntegralApi = getIntegralApi;
exports.getPddCallbackApi = getPddCallbackApi;
exports.getQrCode = getQrCode;
exports.getUserStores = getUserStores;
exports.hotCategoryApi = hotCategoryApi;
exports.hotCategoryListApi = hotCategoryListApi;
exports.integralConfig = integralConfig;
exports.openDelInApi = openDelInApi;
exports.pddAuthLoginApi = pddAuthLoginApi;
exports.pretectApiRecomendGoodsRequest = pretectApiRecomendGoodsRequest;
exports.pretectApiRecomendRequest = pretectApiRecomendRequest;
exports.pretectApiRequest = pretectApiRequest;
exports.pretectapplyRequest = pretectapplyRequest;
exports.storeDetail = storeDetail;
exports.storeReport = storeReport;
exports.storeStatistic = storeStatistic;
exports.todoData = todoData;
exports.todoList = todoList;
exports.videoUplodApi = videoUplodApi;
exports.videoUplodInfoApi = videoUplodInfoApi;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * 获取积分
 */
function getIntegralApi(data) {
  return (0, _request.default)({
    url: '/v3/admin/pdd/admin/getUserInfo',
    method: 'POST',
    data: data
  });
}

/**
 * 申诉请求接口
 */
function applyRequest(data) {
  return (0, _request.default)({
    url: '/admin/pdd/admin/ASS',
    method: 'POST',
    data: data
  });
}

/**
 * 申诉与请求接口
 */
function pretectapplyRequest(data) {
  return (0, _request.default)({
    url: '/admin/pdd/common/proHttpSS',
    method: 'POST',
    data: data
  });
}

/**
 *
 */
function getCreateUrlApi() {
  return (0, _request.default)({
    url: '/admin/pdd/store/createUrl',
    method: 'GET'
  });
}

/**
 *
 */
function getPddCallbackApi(params) {
  return (0, _request.default)({
    url: '/admin/pdd/store/pddCallback',
    method: 'GET',
    params: params
  });
}

/**
 * 获取爆款分类
 */
function hotCategoryApi(params) {
  return (0, _request.default)({
    url: '/admin/pdd/topSellingList/getFirstCate',
    method: 'GET',
    params: params
  });
}

/**
 * 刪除店鋪
 */
function delStoreApi(params) {
  return (0, _request.default)({
    url: '/v3/admin/pdd/userMall/deleteUserMall',
    method: 'GET',
    params: params
  });
}
/**
 *
 */
function pddAuthLoginApi(params) {
  return (0, _request.default)({
    url: '/v3/admin/pdd/admin/pddAuthLogin',
    method: 'GET',
    params: params
  });
}

/**
 * 获取爆款列表
 */
function hotCategoryListApi(params) {
  return (0, _request.default)({
    url: '/admin/pdd/topSellingList/getPage',
    method: 'GET',
    params: params
  });
}

/**
 * 检测视频状态
 */
function videoUplodInfoApi(data) {
  return (0, _request.default)({
    url: '/admin/pdd/goods/fileInfo',
    method: 'POST',
    data: data
  });
}

/**
 * 上传视频到视频空间
 */
function videoUplodApi(data, id) {
  return (0, _request.default)({
    url: '/admin/pdd/goods/videoUpload?mallId=' + id,
    method: 'POST',
    data: data
  });
}

/**
 * 预请求小二推荐类目
 */
function pretectApiRecomendRequest(data) {
  return (0, _request.default)({
    url: '/admin/pdd/common/proHttpTJLM',
    method: 'POST',
    data: data
  });
}

/**
 * 预请求小二推荐商品
 */
function pretectApiRecomendGoodsRequest(data) {
  return (0, _request.default)({
    url: '/admin/pdd/common/proHttpTJSP',
    method: 'POST',
    data: data
  });
}

/**
 * 预请求接口
 */
function pretectApiRequest(data) {
  return (0, _request.default)({
    url: '/admin/pdd/common/proHttp',
    method: 'POST',
    data: data
  });
}

/**
 * 开通扣除积分
 * @param prams
 */
function openDelInApi(data) {
  return (0, _request.default)({
    url: 'admin/pdd/storeActivateFunction/activate',
    method: 'POST',
    data: data
  });
}

/**
 * 获取云端店铺列表
 * @param prams
 */
function getUserStores(data) {
  return (0, _request.default)({
    url: '/admin/pdd/admin/userStores',
    method: 'POST',
    data: data
  });
}

/**
 * 获取授权码
 * @param prams
 */
function addAuthCodeApi(params) {
  return (0, _request.default)({
    url: '/v3/admin/pdd/admin/bindAuthCode',
    method: 'GET',
    params: params
  });
}

/**
 * 获取授权码
 * @param prams
 */
function getAuthCodeApi(params) {
  return (0, _request.default)({
    url: '/v3/admin/pdd/admin/pddCallback',
    method: 'GET',
    params: params
  });
}

/**
 * 检测登录状态
 * @param prams
 */
function checkLoginStatusApi(data) {
  return (0, _request.default)({
    url: '/v3/admin/pdd/admin/checkStoreStatus',
    method: 'POST',
    data: data
  });
}

/**
 * 删除店铺
 */
function deleteStroeApi(data) {
  return (0, _request.default)({
    url: '/admin/pdd/admin/deleteStore',
    method: 'POST',
    data: data
  });
}

/**
 * 绑定店铺与请求
 */
function bindStorePreApi(data) {
  return (0, _request.default)({
    url: '/admin/pdd/common/proHttpStore',
    method: 'POST',
    data: data
  });
}

/**
 * 绑定店铺
 */
function bindStoreApi(data) {
  return (0, _request.default)({
    url: '/admin/pdd/admin/bindStore',
    method: 'POST',
    data: data
  });
}

/**
 * 工单列表统计
 * @param prams
 */
function todoData(data) {
  return (0, _request.default)({
    url: '/admin/pdd/admin/todoData',
    method: 'POST',
    data: data
  });
}

/**
 * 工单列表统计
 * @param prams
 */
function todoList(data) {
  return (0, _request.default)({
    url: '/admin/pdd/admin/todoList',
    method: 'POST',
    data: data
  });
}

/**
 * 店铺统计
 * @param prams
 */
function storeStatistic(data) {
  return (0, _request.default)({
    url: '/admin/pdd/admin/storeStatistic',
    method: 'POST',
    data: data
  });
}

/**
 * 店铺明细
 * @param prams
 */
function storeDetail(data) {
  return (0, _request.default)({
    url: '/admin/pdd/admin/storeDetail',
    method: 'POST',
    data: data
  });
}

/**
 * 推广统计
 * @param prams
 */
function extensionReport(data) {
  return (0, _request.default)({
    url: '/admin/pdd/admin/extensionReport',
    method: 'POST',
    data: data
  });
}

/**
 * 运营报表
 * @param prams
 */
function storeReport(data) {
  return (0, _request.default)({
    url: '/admin/pdd/admin/storeReport',
    method: 'POST',
    data: data
  });
}

/**
 * 积分配置列表
 * @param prams
 */
function integralConfig() {
  return (0, _request.default)({
    url: '/admin/pdd/integralConfig/list',
    method: 'get'
  });
}
/**
 * 获取支付宝二维码
 * 
 */
function getQrCode(data) {
  return (0, _request.default)({
    url: '/admin/pdd/alipay/getQrCode',
    method: 'POST',
    data: data
  });
}
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: { title: "编辑商品库存", width: "60%", visible: true },
          on: { close: _vm.dialogClose },
        },
        [
          _c("div", { staticClass: "dialog-content" }, [
            _c("div", [_vm._v("已选商品数" + _vm._s(_vm.list.length))]),
            _vm._v(" "),
            _c("div", { staticClass: "edit-item" }, [
              _c("div", { staticClass: "edit-item-title" }, [
                _vm._v("统一设置库存"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "edit-item-info" },
                [
                  _c("el-input-number", {
                    staticStyle: { width: "150px" },
                    attrs: {
                      size: "mini",
                      "controls-position": "right",
                      placeholder: "请输入",
                      min: 0,
                    },
                    model: {
                      value: _vm.unifiedInventoryNum,
                      callback: function ($$v) {
                        _vm.unifiedInventoryNum = $$v
                      },
                      expression: "unifiedInventoryNum",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        size: "mini",
                        disabled: !_vm.unifiedInventoryNum,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.executeHandle(1)
                        },
                      },
                    },
                    [_vm._v("执行")]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "edit-item" }, [
              _c("div", { staticClass: "edit-item-title" }, [
                _vm._v("统一加库存"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "edit-item-info" },
                [
                  _c("el-input-number", {
                    staticStyle: { width: "150px" },
                    attrs: {
                      size: "mini",
                      "controls-position": "right",
                      placeholder: "请输入",
                      min: 0,
                    },
                    model: {
                      value: _vm.addInventoryNum,
                      callback: function ($$v) {
                        _vm.addInventoryNum = $$v
                      },
                      expression: "addInventoryNum",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini", disabled: !_vm.addInventoryNum },
                      on: {
                        click: function ($event) {
                          return _vm.executeHandle(2)
                        },
                      },
                    },
                    [_vm._v("执行")]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "edit-item" }, [
              _c("div", { staticClass: "edit-item-title" }, [
                _vm._v("统一减库存"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "edit-item-info" },
                [
                  _c("el-input-number", {
                    staticStyle: { width: "150px" },
                    attrs: {
                      size: "mini",
                      "controls-position": "right",
                      placeholder: "请输入",
                      min: 0,
                    },
                    model: {
                      value: _vm.minusInventoryNum,
                      callback: function ($$v) {
                        _vm.minusInventoryNum = $$v
                      },
                      expression: "minusInventoryNum",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini", disabled: !_vm.minusInventoryNum },
                      on: {
                        click: function ($event) {
                          return _vm.executeHandle(3)
                        },
                      },
                    },
                    [_vm._v("执行")]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "edit-item" }, [
              _c("div", { staticClass: "edit-item-title" }, [
                _vm._v("批量设置规格值包含"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "edit-item-info" },
                [
                  _c("el-input", {
                    staticStyle: { width: "150px" },
                    attrs: { size: "mini", placeholder: "请输入" },
                    model: {
                      value: _vm.skuValue,
                      callback: function ($$v) {
                        _vm.skuValue = $$v
                      },
                      expression: "skuValue",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v("的SKU库存设置为")]),
                  _vm._v(" "),
                  _c("el-input", {
                    staticStyle: { width: "100px" },
                    attrs: { size: "mini", placeholder: "请输入" },
                    model: {
                      value: _vm.setInventoryNum,
                      callback: function ($$v) {
                        _vm.setInventoryNum = $$v
                      },
                      expression: "setInventoryNum",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini" },
                      on: {
                        click: function ($event) {
                          return _vm.executeHandle(4)
                        },
                      },
                    },
                    [_vm._v("执行")]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticStyle: { "margin-top": "8px" } }, [
              _c("div", { staticClass: "list-head" }, [
                _c("div", { staticClass: "list-head-item" }, [_vm._v("商品")]),
                _vm._v(" "),
                _c("div", { staticClass: "list-head-item" }, [
                  _vm._v("SKU名称(规格值)"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "list-head-item" }, [_vm._v("库存")]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                _vm._l(_vm.list, function (item, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "list-body" },
                    _vm._l(item.skuList, function (skuItem, skuIndex) {
                      return _c(
                        "div",
                        { key: skuIndex, staticClass: "list-body-item" },
                        [
                          _c("div", { staticClass: "item-goods" }, [
                            _c("img", {
                              attrs: { src: skuItem.thumbUrl, alt: "" },
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "goods-info" }, [
                              _c("div", { staticClass: "goods-name" }, [
                                _vm._v(_vm._s(item.goodsName)),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "goods-id" }, [
                                _vm._v("商品ID:" + _vm._s(item.goodsId)),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "item-sku" },
                            _vm._l(
                              skuItem.spec,
                              function (specItem, specIndex) {
                                return _c("div", { key: specIndex }, [
                                  _vm._v(
                                    _vm._s(specItem.parentName) +
                                      ": " +
                                      _vm._s(specItem.specName)
                                  ),
                                ])
                              }
                            ),
                            0
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "item-inventory" },
                            [
                              _c("el-input-number", {
                                attrs: {
                                  size: "mini",
                                  "controls-position": "right",
                                  placeholder: "请输入",
                                  min: 0,
                                },
                                model: {
                                  value: skuItem.quantityNum,
                                  callback: function ($$v) {
                                    _vm.$set(skuItem, "quantityNum", $$v)
                                  },
                                  expression: "skuItem.quantityNum",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    }),
                    0
                  )
                }),
                0
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { size: "mini" }, on: { click: _vm.dialogClose } },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.editConfirm },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
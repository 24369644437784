"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "batchEditDetailText",
  props: {
    tabSelect: {
      type: Array
    },
    mallId: {
      type: String
    }
  },
  watch: {
    tabSelect: {
      handler: function handler(newVal) {
        this.list = newVal;
      }
    },
    mallId: {
      handler: function handler(newVal) {
        this.id = newVal;
      }
    }
  },
  data: function data() {
    return {
      batchTextDialog: false,
      id: '',
      list: []
    };
  },
  methods: {}
};
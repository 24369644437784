"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  name: 'MenuItem',
  functional: true,
  props: {
    icon: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    }
  },
  render: function render(h, context) {
    var _context$props = context.props,
      icon = _context$props.icon,
      title = _context$props.title;
    var vnodes = [];
    var src = '';
    if (icon) {
      if (icon == 's-home') {
        src = require('@/assets/imgs/Home112.png');
      }
      if (icon == 's-shop') {
        src = require('@/assets/imgs/shop11.png');
      }
      if (icon == 's-operation') {
        src = require('@/assets/imgs/hot111.png');
      }
      if (icon == 's-cooperation') {
        src = require('@/assets/imgs/102.png');
      }
      if (icon == 's-order') {
        src = require('@/assets/imgs/s-operation.png');
      }
      if (icon == 'postcard') {
        src = require('@/assets/imgs/lll.png');
      }
      if (icon == 'suitcase') {
        src = require('@/assets/imgs/s-shop.png');
      }
      if (icon == 'folder-opened') {
        src = require('@/assets/imgs/151.png');
      }
      if (icon == 's-grid') {
        src = require('@/assets/imgs/6661.png');
      }
      if (icon == 'printer') {
        src = require('@/assets/imgs/toool1.png');
      }
      vnodes.push(h("img", {
        "attrs": {
          "src": src
        },
        "style": "width:20px;height:20px;margin-right:6px",
        "class": "icon-l"
      }));
    }
    if (title) {
      vnodes.push(h("span", {
        "slot": "title"
      }, [title]));
    }
    return vnodes;
  }
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: { title: "编辑运费模板", width: "60%", visible: true },
          on: { close: _vm.dialogClose },
        },
        [
          _c("div", { staticClass: "dialog-content" }, [
            _c("div", [_vm._v("已选商品数" + _vm._s(_vm.list.length))]),
            _vm._v(" "),
            _c("div", { staticClass: "edit-item" }, [
              _c("div", { staticClass: "edit-item-title" }, [
                _c("span", [_vm._v("*")]),
                _vm._v("运费模板"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "edit-item-info" },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { size: "mini", placeholder: "请选择" },
                      model: {
                        value: _vm.templateId,
                        callback: function ($$v) {
                          _vm.templateId = $$v
                        },
                        expression: "templateId",
                      },
                    },
                    _vm._l(_vm.templateList, function (item) {
                      return _c("el-option", {
                        key: item.templateId,
                        attrs: {
                          label: item.templateName,
                          value: item.templateId,
                        },
                      })
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini" },
                      on: { click: _vm.executeHandle },
                    },
                    [_vm._v("执行")]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "info-title" }, [_vm._v("商品详细信息")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "goods-info" },
              _vm._l(_vm.list, function (item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "goods-info-item" },
                  [
                    _c("div", { staticClass: "goods-info-item-head" }, [
                      _c("div", { staticClass: "item-head-left" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "item-goods-info" }, [
                        _c("div", { staticClass: "goods-name" }, [
                          _vm._v(_vm._s(item.goodsName)),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "goods-id" }, [
                          _vm._v("商品ID:" + _vm._s(item.goodsId)),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "goods-info-item-title" }, [
                      _c("div", { staticClass: "item-title-title" }, [
                        _c("span", [_vm._v("*")]),
                        _vm._v("运费模板："),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "item-title-info" },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { size: "mini", placeholder: "请选择" },
                              model: {
                                value: item.costTemplateId,
                                callback: function ($$v) {
                                  _vm.$set(item, "costTemplateId", $$v)
                                },
                                expression: "item.costTemplateId",
                              },
                            },
                            _vm._l(_vm.templateList, function (item) {
                              return _c("el-option", {
                                key: item.templateId,
                                attrs: {
                                  label: item.templateName,
                                  value: item.templateId,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]
                )
              }),
              0
            ),
          ]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { size: "mini" }, on: { click: _vm.dialogClose } },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.editConfirm },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: { title: "编辑满件折扣", width: "60%", visible: true },
          on: { close: _vm.dialogClose },
        },
        [
          _c("div", { staticClass: "dialog-content" }, [
            _c("div", [_vm._v("已选商品数" + _vm._s(_vm.list.length))]),
            _vm._v(" "),
            _c("div", { staticClass: "edit-item" }, [
              _c("div", { staticClass: "edit-item-title" }, [
                _vm._v("统一设置满件折扣 "),
                _c("span", { staticStyle: { "margin-left": "10px" } }, [
                  _vm._v("满2件"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "edit-item-info" },
                [
                  _c("el-input-number", {
                    staticStyle: { width: "90px" },
                    attrs: {
                      size: "mini",
                      "controls-position": "right",
                      placeholder: "请输入",
                      min: 5.5,
                      max: 9.9,
                      step: _vm.step,
                    },
                    on: { change: _vm.changeDiscount },
                    model: {
                      value: _vm.discount,
                      callback: function ($$v) {
                        _vm.discount = $$v
                      },
                      expression: "discount",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini" },
                      on: { click: _vm.executeHandle },
                    },
                    [_vm._v("执行")]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticStyle: { "margin-top": "8px" } },
              [
                _c(
                  "el-button",
                  { attrs: { size: "mini" }, on: { click: _vm.clearDiscount } },
                  [_vm._v("清空满减折扣")]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "info-title" }, [_vm._v("商品详细信息")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "goods-info" },
              _vm._l(_vm.list, function (item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "goods-info-item" },
                  [
                    _c("div", { staticClass: "goods-info-item-head" }, [
                      _c("div", { staticClass: "item-head-left" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "item-goods-info" }, [
                        _c("div", { staticClass: "goods-name" }, [
                          _vm._v(_vm._s(item.goodsName)),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "goods-id" }, [
                          _vm._v("商品ID:" + _vm._s(item.goodsId)),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "goods-info-item-title" }, [
                      _c("div", { staticClass: "item-title-title" }, [
                        _c("span", [_vm._v("*")]),
                        _vm._v("满件折扣："),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "item-title-info" },
                        [
                          !item.discount
                            ? _c(
                                "span",
                                { staticStyle: { color: "#FF0000" } },
                                [_vm._v("暂无折扣")]
                              )
                            : _c("el-input-number", {
                                staticStyle: { width: "120px" },
                                attrs: {
                                  size: "mini",
                                  "controls-position": "right",
                                  placeholder: "请输入",
                                  min: 0.9,
                                  max: 9.9,
                                  step: _vm.step,
                                },
                                model: {
                                  value: item.discount,
                                  callback: function ($$v) {
                                    _vm.$set(item, "discount", $$v)
                                  },
                                  expression: "item.discount",
                                },
                              }),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticStyle: { "margin-left": "50px" } },
                            [_vm._v("注：商品满2件折扣与店铺满2件折扣不会叠加")]
                          ),
                          _vm._v(" "),
                          item.discount
                            ? _c(
                                "el-button",
                                {
                                  attrs: { size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.cancelDiscount(item)
                                    },
                                  },
                                },
                                [_vm._v("取消折扣")]
                              )
                            : _c(
                                "el-button",
                                {
                                  attrs: { size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.setDiscount(item)
                                    },
                                  },
                                },
                                [_vm._v("设置折扣")]
                              ),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "goods-info-item-title" }, [
                      _c("div", { staticClass: "item-title-title" }),
                      _vm._v(" "),
                      item.discount
                        ? _c(
                            "div",
                            {
                              staticClass: "item-title-info",
                              staticStyle: { color: "red" },
                            },
                            [
                              _vm._v(
                                "\n              折后拼单价：" +
                                  _vm._s(
                                    _vm.handleDecimal(
                                      _vm.multiply(
                                        item.minGroupPrice,
                                        _vm.divide(item.discount, 10)
                                      ),
                                      2
                                    )
                                  ) +
                                  " ~ " +
                                  _vm._s(
                                    _vm.handleDecimal(
                                      _vm.multiply(
                                        item.maxGroupPrice,
                                        _vm.divide(item.discount, 10)
                                      ),
                                      2
                                    )
                                  ) +
                                  "元\n            "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ]
                )
              }),
              0
            ),
          ]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { size: "mini" }, on: { click: _vm.dialogClose } },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.editConfirm },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loadingPage,
          expression: "loadingPage",
        },
      ],
      staticClass: "divBox",
    },
    [
      _c(
        "div",
        { staticClass: "_container" },
        [
          _c(
            "div",
            { staticClass: "topBtn" },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    "font-weight": "bold",
                    "font-size": "14px",
                    margin: "0 24px 0 0",
                  },
                },
                [
                  _vm._v("店铺名称：\n      "),
                  _c("el-input", {
                    staticStyle: { width: "180px" },
                    attrs: { size: "mini", placeholder: "请输入店铺名称" },
                    model: {
                      value: _vm.shopName,
                      callback: function ($$v) {
                        _vm.shopName = $$v
                      },
                      expression: "shopName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: {
                    "font-weight": "bold",
                    "font-size": "14px",
                    margin: "0 18px 0 10px",
                  },
                },
                [
                  _vm._v("店铺编码：\n      "),
                  _c("el-input", {
                    staticStyle: { width: "180px" },
                    attrs: { size: "mini", placeholder: "请输入店铺编码" },
                    model: {
                      value: _vm.mallCode,
                      callback: function ($$v) {
                        _vm.mallCode = $$v
                      },
                      expression: "mallCode",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "mini",
                    icon: "el-icon-search",
                  },
                  on: { click: _vm.getCloudsShopList },
                },
                [_vm._v("搜索")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "flex-direction": "row",
                "justify-content": "space-between",
                "margin-bottom": "25px",
              },
            },
            [
              _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        size: "mini",
                        icon: "el-icon-upload",
                      },
                      on: { click: _vm.authHandle },
                    },
                    [_vm._v("新增授权店铺")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        size: "mini",
                        icon: "el-icon-plus",
                      },
                      on: {
                        click: function ($event) {
                          _vm.authCodedialogVisible = true
                        },
                      },
                    },
                    [_vm._v("新增店铺\n      ")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { color: "black", "font-weight": "bold" },
                      attrs: { type: "warning", size: "mini" },
                      on: { click: _vm.getCloudsShopList },
                    },
                    [_vm._v("刷新店铺信息")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "mini" },
                      on: { click: _vm.expportAuthCode },
                    },
                    [_vm._v("导出授权码")]
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%", "margin-top": "20px" },
              attrs: {
                data: _vm.shopData,
                height: "calc(100vh - 280px)",
                border: "",
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "selection",
                  label: "",
                  align: "center",
                  width: "60",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  label: "序号",
                  align: "center",
                  width: "60",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "mallAccount",
                  align: "center",
                  label: "头像",
                  width: "80",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("img", {
                          staticStyle: {
                            background: "#eee",
                            width: "40px",
                            height: "40px",
                          },
                          attrs: { src: scope.row.logo, alt: "" },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "mallId",
                  label: "店铺编码",
                  align: "center",
                  width: "110",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "mallName",
                  align: "center",
                  width: "80",
                  label: "店铺名称",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { align: "center", width: "80", label: "授权状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        new Date(
                          scope.row.acExpireTime
                            .substring(0, 19)
                            .replace(/-/g, "/")
                        ).getTime() < new Date().getTime()
                          ? _c("span", [_vm._v("已过期")])
                          : _c("span", [_vm._v("未过期")]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "acExpireTime",
                  align: "center",
                  width: "180",
                  label: "授权到期时间",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "box",
                            staticStyle: {
                              display: "flex",
                              "align-items": "center",
                            },
                          },
                          [
                            !new Date(
                              scope.row.acExpireTime
                                .substring(0, 19)
                                .replace(/-/g, "/")
                            ).getTime() < new Date().getTime()
                              ? _c("div", [
                                  _vm._v(
                                    _vm._s(scope.row.acExpireTime) +
                                      "\n            "
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            new Date(
                              scope.row.acExpireTime
                                .substring(0, 19)
                                .replace(/-/g, "/")
                            ).getTime() < new Date().getTime()
                              ? _c(
                                  "el-button",
                                  {
                                    staticStyle: { "margin-left": "20px" },
                                    attrs: { size: "mini", type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.goShop()
                                      },
                                    },
                                  },
                                  [_vm._v("去续费软件\n            ")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "authCode",
                  align: "center",
                  width: "150",
                  label: "授权码",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "box",
                            staticStyle: {
                              display: "flex",
                              "align-items": "center",
                            },
                          },
                          [
                            _c("div", [_vm._v(_vm._s(scope.row.authCode))]),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                staticStyle: { "margin-left": "20px" },
                                attrs: {
                                  disabled:
                                    new Date(
                                      scope.row.acExpireTime
                                        .substring(0, 19)
                                        .replace(/-/g, "/")
                                    ).getTime() < new Date().getTime(),
                                  size: "mini",
                                  type: "text",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.copyAuthCode(scope.row.authCode)
                                  },
                                },
                              },
                              [_vm._v("复制\n            ")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "categoryMain",
                  align: "center",
                  width: "100",
                  label: "积分",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: { effect: "light", placement: "right" },
                          },
                          [
                            _c(
                              "div",
                              { attrs: { slot: "content" }, slot: "content" },
                              [
                                _c("div", [
                                  _c("span", [
                                    _vm._v(
                                      "积分畅用剩余：" +
                                        _vm._s(Number(scope.row.giftIntegral))
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _vm.isBeforeBusinessTime(
                                    scope.row.businessTime
                                  )
                                    ? _c("span", [
                                        _vm._v(
                                          "(重置清零时间:" +
                                            _vm._s(
                                              scope.row.businessTime.slice(10)
                                            ) +
                                            ")"
                                        ),
                                      ])
                                    : _vm._e(),
                                ]),
                                _vm._v(" "),
                                _c("div", [
                                  _vm._v(
                                    "\n                其他积分剩余：" +
                                      _vm._s(Number(scope.row.integral)) +
                                      "\n              "
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  Number(scope.row.integral) +
                                    Number(scope.row.giftIntegral)
                                ) + "积分"
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              disabled:
                                new Date(
                                  scope.row.acExpireTime
                                    .substring(0, 19)
                                    .replace(/-/g, "/")
                                ).getTime() < new Date().getTime(),
                              size: "mini",
                              type: "text",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handlePayChange(scope.row)
                              },
                            },
                          },
                          [_vm._v("去充值\n          ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c(
                "el-table-column",
                {
                  attrs: { align: "center", label: "当前版本？", width: "170" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm.isBeforeBusinessTime(scope.row.businessTime)
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "light",
                                        placement: "right",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: { width: "200px" },
                                          attrs: { slot: "content" },
                                          slot: "content",
                                        },
                                        [
                                          _c("p", [
                                            _vm._v(
                                              "到期时间：" +
                                                _vm._s(scope.row.businessTime)
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                color: "#409EFF",
                                                "margin-top": "10px",
                                                cursor: "pointer",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handlePayHYChange(
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                  去充值\n                "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("span", { staticClass: "box-hover" }, [
                                        _vm._v("积分畅用生效中"),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _c(
                                "div",
                                {
                                  staticStyle: {
                                    cursor: "pointer",
                                    color: "#409EFF",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handlePayHYChange(scope.row)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n            去开通，享受积分畅用\n          "
                                  ),
                                ]
                              ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "template",
                    { slot: "header" },
                    [
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content: "时间计算标准：30天/月；365天/年",
                            placement: "top",
                          },
                        },
                        [_c("span", [_vm._v("当前版本")])]
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { align: "center", label: "运费模板", width: "250" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100px" },
                            attrs: { placeholder: "请选择", size: "mini" },
                            model: {
                              value: scope.row.templateId,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "templateId", $$v)
                              },
                              expression: "scope.row.templateId",
                            },
                          },
                          _vm._l(scope.row.templateList, function (item) {
                            return _c("el-option", {
                              key: item.templateId,
                              attrs: {
                                label: item.templateName,
                                value: item.templateId,
                                size: "mini",
                              },
                            })
                          }),
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              disabled:
                                new Date(
                                  scope.row.acExpireTime
                                    .substring(0, 19)
                                    .replace(/-/g, "/")
                                ).getTime() < new Date().getTime(),
                              type: "text",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.saveTemplate(scope.row)
                              },
                            },
                          },
                          [_vm._v("保存\n          ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { width: "250", align: "center", label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              disabled:
                                new Date(
                                  scope.row.acExpireTime
                                    .substring(0, 19)
                                    .replace(/-/g, "/")
                                ).getTime() < new Date().getTime(),
                              size: "mini",
                              type: "primary",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.refreshAuthCode(scope.row)
                              },
                            },
                          },
                          [_vm._v("刷新授权\n          ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              disabled:
                                new Date(
                                  scope.row.acExpireTime
                                    .substring(0, 19)
                                    .replace(/-/g, "/")
                                ).getTime() < new Date().getTime(),
                              size: "mini",
                              type: "danger",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.delStoreHandle(
                                  scope.row,
                                  scope.$index
                                )
                              },
                            },
                          },
                          [_vm._v("删除\n          ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "请输入授权码",
            visible: _vm.authCodedialogVisible,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.authCodedialogVisible = $event
            },
          },
        },
        [
          _c("el-input", {
            attrs: {
              type: "textarea",
              rows: 2,
              autosize: { minRows: 4, maxRows: 4 },
              resize: "none",
              placeholder: "请输入授权码，多个授权码用逗号隔开",
            },
            model: {
              value: _vm.authVal,
              callback: function ($$v) {
                _vm.authVal = $$v
              },
              expression: "authVal",
            },
          }),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.authCodedialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.addStoreAuth } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "充值",
            visible: _vm.openPay,
            width: "1248px",
            "custom-class": "pay-dialog",
          },
          on: {
            "update:visible": function ($event) {
              _vm.openPay = $event
            },
          },
        },
        [
          _c("payment", {
            ref: "payments",
            attrs: { shopData: _vm.shopDetail },
            on: {
              close: function ($event) {
                _vm.openPay = false
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.showBus
        ? _c("div", { staticClass: "bg-ms" }, [
            _c("div", { staticClass: "bg-c" }, [
              _c("div", { staticClass: "nav" }, [
                _c("span", [_vm._v("升级店铺")]),
                _vm._v(" "),
                _c("i", {
                  staticClass: "el-icon-close",
                  staticStyle: {
                    "font-size": "20px",
                    "font-weight": "600",
                    "margin-right": "-15px",
                    position: "relative",
                    top: "-20px",
                  },
                  on: {
                    click: function ($event) {
                      _vm.showBus = false
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c("img", {
                staticClass: "img",
                attrs: {
                  src: "https://oss.feiniao123.com/file/feiNiaoProductManagement/poster/49c14c3cfd30dbcb0252479bd08cf08.png",
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "contanier-b" }, [
                _c(
                  "div",
                  { staticClass: "left-c" },
                  [
                    _c("span", { staticStyle: { "font-weight": "700" } }, [
                      _vm._v("使用兑换码"),
                    ]),
                    _vm._v(" "),
                    _c("el-input", {
                      staticStyle: {
                        "margin-left": "30px",
                        width: "220px",
                        height: "30px",
                        "border-radius": "14px",
                        border: "none",
                        background: "#EBEBEB",
                        padding: "0 12px",
                      },
                      attrs: { placeholder: "请输入兑换码" },
                      model: {
                        value: _vm.formPay.exchangeCode,
                        callback: function ($$v) {
                          _vm.$set(_vm.formPay, "exchangeCode", $$v)
                        },
                        expression: "formPay.exchangeCode",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "btn-c",
                        on: { click: _vm.exchangeCodeHandle },
                      },
                      [_vm._v("确定")]
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "margin-top": "30px",
                          "font-weight": "700",
                          "margin-left": "2px",
                        },
                      },
                      [_vm._v("付款详情")]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "margin-left": "7px",
                          "margin-top": "10px",
                        },
                      },
                      [_vm._v("充值店铺：")]
                    ),
                    _vm._v(" "),
                    _c("el-input", {
                      staticStyle: {
                        width: "180px",
                        height: "30px",
                        "border-radius": "14px",
                        border: "none",
                        background: "#EBEBEB",
                        padding: "0 12px",
                      },
                      attrs: { placeholder: "请选择店铺" },
                      model: {
                        value: _vm.storeVal,
                        callback: function ($$v) {
                          _vm.storeVal = $$v
                        },
                        expression: "storeVal",
                      },
                    }),
                    _vm._v(" "),
                    _c("p", { staticStyle: { "margin-top": "30px" } }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "rig-c" }, [
                  _c("div", { staticClass: "payImg" }, [
                    _c("img", {
                      staticStyle: {
                        width: "184px",
                        height: "200px",
                        "background-color": "#eee",
                      },
                      attrs: { src: _vm.aliPayCode, alt: "" },
                    }),
                    _vm._v(" "),
                    _vm._m(0),
                  ]),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "新建运费模板",
            visible: _vm.isCreateTem,
            width: "80%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.isCreateTem = $event
            },
          },
        },
        [
          _c("div", { staticClass: "box-info" }, [
            _c("div", { staticClass: "info-top" }, [
              _c(
                "div",
                {
                  staticClass: "smbol",
                  staticStyle: { "margin-bottom": "20px" },
                },
                [_vm._v("模板基础信息")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "in-d" }, [
                _c(
                  "p",
                  { staticStyle: { "margin-bottom": "10px" } },
                  [
                    _c("span", [_vm._v("模板名称：")]),
                    _vm._v(" "),
                    _c("el-input", {
                      staticStyle: { width: "60%" },
                      attrs: {
                        size: "mini",
                        type: "text",
                        "show-word-limit": "",
                        minlength: "6",
                        maxlength: "50",
                        placeholder: "请输入",
                      },
                      model: {
                        value: _vm.text,
                        callback: function ($$v) {
                          _vm.text = $$v
                        },
                        expression: "text",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("p", [
                  _c("span", [_vm._v("发货地：")]),
                  _vm._v(" "),
                  _c(
                    "i",
                    {
                      staticStyle: {
                        "font-size": "12px",
                        "font-weight": "500",
                      },
                    },
                    [_vm._v("发货地与您的实际发货地不符，可能会导致物物流投诉")]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "block",
                    staticStyle: { "margin-top": "10px" },
                  },
                  [
                    _c("el-cascader", {
                      staticStyle: { width: "60%", "margin-left": "75px" },
                      attrs: {
                        size: "mini",
                        "show-word-limit": "",
                        maxlength: "50",
                        props: { value: "id", label: "regionName" },
                        placeholder: "选择省/市/区",
                        options: _vm.addre1,
                      },
                      model: {
                        value: _vm.addreval,
                        callback: function ($$v) {
                          _vm.addreval = $$v
                        },
                        expression: "addreval",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "city-box" },
                [
                  _c(
                    "p",
                    {
                      staticClass: "smbol",
                      staticStyle: { "margin-top": "40px" },
                    },
                    [
                      _vm._v("包邮地区\n            "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content: "Top Left 提示文字",
                            placement: "top-start",
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "el-icon-question icon-color",
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-checkbox",
                    {
                      staticStyle: {
                        "margin-left": "10px",
                        "margin-top": "30px",
                      },
                      on: { change: _vm.handleCheckedCitiesChange },
                      model: {
                        value: _vm.checkAll,
                        callback: function ($$v) {
                          _vm.checkAll = $$v
                        },
                        expression: "checkAll",
                      },
                    },
                    [_vm._v("全选\n          ")]
                  ),
                  _vm._v(" "),
                  _c("span", { staticStyle: { "margin-left": "1200px" } }, [
                    _vm._v("已选择" + _vm._s(_vm.num) + "个区域"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticStyle: { margin: "15px 0" } }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "cityStyle" },
                    _vm._l(_vm.addre1, function (v, i) {
                      return _c("span", { key: i }, [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              width: "170px",
                              display: "inline-block",
                            },
                          },
                          [
                            _c("el-checkbox", {
                              staticStyle: { "margin-bottom": "20px" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckAllChange($event, v)
                                },
                              },
                              model: {
                                value: v.check,
                                callback: function ($$v) {
                                  _vm.$set(v, "check", $$v)
                                },
                                expression: "v.check",
                              },
                            }),
                            _vm._v(
                              "\n                    " +
                                _vm._s(v.regionName) +
                                "\n                    "
                            ),
                            v.regionName == "内蒙古自治区" ||
                            v.regionName == "青海省" ||
                            v.regionName == "西藏自治区" ||
                            v.regionName == "新疆维吾尔自治区"
                              ? _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      content: "Top Left 提示文字",
                                      placement: "top-start",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-question icon-color",
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c("span", {
                              style:
                                v.regionName == "黑龙江省" ||
                                v.regionName == "内蒙古自治区" ||
                                v.regionName == "青海省" ||
                                v.regionName == " 西藏自治区" ||
                                v.regionName == "新疆维吾尔自治区"
                                  ? "margin: 10px 0px 20px 96px;"
                                  : "margin: 10px 0px 20px 110px;",
                            }),
                          ],
                          1
                        ),
                      ])
                    }),
                    0
                  ),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.subTemHandle } },
                [_vm._v("提 交")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.centerDialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "payText",
        staticStyle: {
          display: "flex",
          "align-items": "center",
          "align-content": "center",
        },
      },
      [
        _c("img", {
          staticStyle: {
            width: "50px",
            height: "50px",
            "margin-right": "10px",
          },
          attrs: { src: require("../../assets/imgs/scan-pic.png"), alt: "" },
        }),
        _vm._v(" "),
        _c("div", [
          _c("div", [_vm._v("打开手机支付宝")]),
          _vm._v(" "),
          _c("div", [_vm._v("扫一扫继续付款")]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }
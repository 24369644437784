var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: { title: "铺货设置", visible: _vm.showSet, width: "80%" },
      on: {
        "update:visible": function ($event) {
          _vm.showSet = $event
        },
        close: _vm.closeDialog,
      },
    },
    [
      _c("div", { staticClass: "openset-content-box" }, [
        _c(
          "div",
          { staticClass: "row-box" },
          [
            _vm._v("\n      假一赔十：\n      "),
            _c(
              "el-radio",
              {
                attrs: { label: "1" },
                model: {
                  value: _vm.oneTen,
                  callback: function ($$v) {
                    _vm.oneTen = $$v
                  },
                  expression: "oneTen",
                },
              },
              [_vm._v("否")]
            ),
            _vm._v(" "),
            _c(
              "el-radio",
              {
                attrs: { label: "0" },
                model: {
                  value: _vm.oneTen,
                  callback: function ($$v) {
                    _vm.oneTen = $$v
                  },
                  expression: "oneTen",
                },
              },
              [_vm._v("是")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "row-box" },
          [
            _vm._v("\n      七天无理由：\n      "),
            _c(
              "el-radio",
              {
                attrs: { label: "1" },
                model: {
                  value: _vm.sevenDay,
                  callback: function ($$v) {
                    _vm.sevenDay = $$v
                  },
                  expression: "sevenDay",
                },
              },
              [_vm._v("支持7天无理由退货")]
            ),
            _vm._v(" "),
            _c(
              "el-radio",
              {
                attrs: { label: "0" },
                model: {
                  value: _vm.sevenDay,
                  callback: function ($$v) {
                    _vm.sevenDay = $$v
                  },
                  expression: "sevenDay",
                },
              },
              [_vm._v("不支持7天无理由退货")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "row-box" },
          [
            _vm._v("\n      保密发货：\n      "),
            _c(
              "el-radio",
              {
                attrs: { label: "1" },
                model: {
                  value: _vm.privacyDelivery,
                  callback: function ($$v) {
                    _vm.privacyDelivery = $$v
                  },
                  expression: "privacyDelivery",
                },
              },
              [_vm._v("支持")]
            ),
            _vm._v(" "),
            _c(
              "el-radio",
              {
                attrs: { label: "0" },
                model: {
                  value: _vm.privacyDelivery,
                  callback: function ($$v) {
                    _vm.privacyDelivery = $$v
                  },
                  expression: "privacyDelivery",
                },
              },
              [_vm._v("不支持")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "row-box" },
          [
            _vm._v("\n      是否二手：\n      "),
            _c(
              "el-radio",
              {
                attrs: { label: "1" },
                model: {
                  value: _vm.secondHand,
                  callback: function ($$v) {
                    _vm.secondHand = $$v
                  },
                  expression: "secondHand",
                },
              },
              [_vm._v("否")]
            ),
            _vm._v(" "),
            _c(
              "el-radio",
              {
                attrs: { label: "0" },
                model: {
                  value: _vm.secondHand,
                  callback: function ($$v) {
                    _vm.secondHand = $$v
                  },
                  expression: "secondHand",
                },
              },
              [_vm._v("是")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "row-box" },
          [
            _vm._v("\n      发布模式：\n      "),
            _c(
              "el-radio",
              {
                attrs: { label: "1" },
                model: {
                  value: _vm.releaseMode,
                  callback: function ($$v) {
                    _vm.releaseMode = $$v
                  },
                  expression: "releaseMode",
                },
              },
              [_vm._v("立即上架")]
            ),
            _vm._v(" "),
            _c(
              "el-radio",
              {
                attrs: { label: "2" },
                model: {
                  value: _vm.releaseMode,
                  callback: function ($$v) {
                    _vm.releaseMode = $$v
                  },
                  expression: "releaseMode",
                },
              },
              [_vm._v("放入已下架")]
            ),
            _vm._v(" "),
            _c(
              "el-radio",
              {
                attrs: { label: "3" },
                model: {
                  value: _vm.releaseMode,
                  callback: function ($$v) {
                    _vm.releaseMode = $$v
                  },
                  expression: "releaseMode",
                },
              },
              [_vm._v("放入草稿箱")]
            ),
            _vm._v(
              "\n                                \n      发货时间：\n      "
            ),
            _c(
              "el-radio",
              {
                attrs: { label: "1" },
                model: {
                  value: _vm.deliveryTime,
                  callback: function ($$v) {
                    _vm.deliveryTime = $$v
                  },
                  expression: "deliveryTime",
                },
              },
              [_vm._v("24小时")]
            ),
            _vm._v(" "),
            _c(
              "el-radio",
              {
                attrs: { label: "2" },
                model: {
                  value: _vm.deliveryTime,
                  callback: function ($$v) {
                    _vm.deliveryTime = $$v
                  },
                  expression: "deliveryTime",
                },
              },
              [_vm._v("48小时")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "row-box", staticStyle: { display: "flex" } },
          [
            _c("span", [_vm._v("每人限购：")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "limit-bought" },
              [
                _c("el-input", {
                  attrs: { size: "mini" },
                  model: {
                    value: _vm.personCount,
                    callback: function ($$v) {
                      _vm.personCount = $$v
                    },
                    expression: "personCount",
                  },
                }),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "row-box" }, [
          _c("div", { staticClass: "top-box" }),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "bottom-box " },
            [
              _vm._v("\n        当标题超过60个字符时：\n        "),
              _c(
                "el-radio",
                {
                  attrs: { label: "1" },
                  model: {
                    value: _vm.moreTile,
                    callback: function ($$v) {
                      _vm.moreTile = $$v
                    },
                    expression: "moreTile",
                  },
                },
                [_vm._v("不操作")]
              ),
              _vm._v(" "),
              _c(
                "el-radio",
                {
                  attrs: { label: "2" },
                  model: {
                    value: _vm.moreTile,
                    callback: function ($$v) {
                      _vm.moreTile = $$v
                    },
                    expression: "moreTile",
                  },
                },
                [_vm._v("删除前面的")]
              ),
              _vm._v(" "),
              _c(
                "el-radio",
                {
                  attrs: { label: "3" },
                  model: {
                    value: _vm.moreTile,
                    callback: function ($$v) {
                      _vm.moreTile = $$v
                    },
                    expression: "moreTile",
                  },
                },
                [_vm._v("删除后面的")]
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "row-box", staticStyle: { display: "flex" } },
          [
            _c(
              "div",
              { staticClass: "row-left", staticStyle: { display: "flex" } },
              [
                _c("span", [_vm._v("编码设置：")]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "limit-bought",
                    staticStyle: { width: "300px" },
                  },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.codeSetting,
                          callback: function ($$v) {
                            _vm.codeSetting = $$v
                          },
                          expression: "codeSetting",
                        },
                      },
                      [
                        _c(
                          "el-radio",
                          {
                            staticStyle: { "margin-bottom": "10px" },
                            attrs: { label: "1" },
                          },
                          [_vm._v("不复制商品编码")]
                        ),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: "2" } }, [
                          _vm._v("复制商品编码"),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.skuCodeSetting,
                          callback: function ($$v) {
                            _vm.skuCodeSetting = $$v
                          },
                          expression: "skuCodeSetting",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: "3" } }, [
                          _vm._v("不复制SKU编码"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: "4" } }, [
                          _vm._v("复制SKU编码"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "row-right", staticStyle: { display: "flex" } },
              [_vm._v("\n                                   \n        ")]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "row-box", staticStyle: { display: "flex" } },
          [
            _c(
              "div",
              { staticClass: "row-left", staticStyle: { display: "flex" } },
              [
                _c("span", { staticClass: "yssjBox" }, [_vm._v("预售时间：")]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "limit-bought",
                    staticStyle: { width: "300px" },
                  },
                  [
                    _c("el-date-picker", {
                      attrs: {
                        "value-format": "yyyy-MM-dd",
                        type: "date",
                        "picker-options": _vm.pickerOptions,
                        placeholder: "选择预售日期",
                      },
                      model: {
                        value: _vm.datePicker,
                        callback: function ($$v) {
                          _vm.datePicker = $$v
                        },
                        expression: "datePicker",
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { "text-align": "right" } },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.showSet = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.saveSetting } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
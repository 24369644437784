var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "编辑商品标题",
            width: "60%",
            visible: _vm.batchTextDialog,
          },
          on: {
            "update:visible": function ($event) {
              _vm.batchTextDialog = $event
            },
          },
        },
        [
          _c("template", { slot: "title" }, [
            _c(
              "span",
              { staticStyle: { "font-size": "16px", "line-height": "25px" } },
              [_vm._v("编辑商品详情顶部文本")]
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                staticStyle: {
                  color: "red",
                  "margin-left": "20px",
                  "font-size": "16px",
                  "line-height": "25px",
                },
              },
              [_vm._v("部分商品可能不生效")]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "dialog-content" }, [
            _c("div", [_vm._v("已选商品数" + _vm._s(_vm.list.length))]),
            _vm._v(" "),
            _c("div", { staticClass: "edit-item" }, [
              _c("div", { staticClass: "edit-item-title" }, [
                _vm._v("文字替换:"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "edit-item-info" },
                [
                  _c("el-input", {
                    staticStyle: { width: "90px" },
                    attrs: { size: "mini", placeholder: "请输入" },
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v("为")]),
                  _vm._v(" "),
                  _c("el-input", {
                    staticStyle: { width: "90px" },
                    attrs: { size: "mini", placeholder: "请输入" },
                  }),
                  _vm._v(" "),
                  _c("el-button", { attrs: { size: "mini" } }, [
                    _vm._v("执行"),
                  ]),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "edit-item" }, [
              _c("div", { staticClass: "edit-item-title" }, [
                _vm._v("开头加:"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "edit-item-info" },
                [
                  _c("el-input", {
                    staticStyle: { width: "150px" },
                    attrs: { size: "mini", placeholder: "请输入" },
                  }),
                  _vm._v(" "),
                  _c("el-button", { attrs: { size: "mini" } }, [
                    _vm._v("执行"),
                  ]),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "edit-item" }, [
              _c("div", { staticClass: "edit-item-title" }, [
                _vm._v("结尾加:"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "edit-item-info" },
                [
                  _c("el-input", {
                    staticStyle: { width: "150px" },
                    attrs: { size: "mini", placeholder: "请输入" },
                  }),
                  _vm._v(" "),
                  _c("el-button", { attrs: { size: "mini" } }, [
                    _vm._v("执行"),
                  ]),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticStyle: { "margin-top": "8px" } },
              [_c("el-button", { attrs: { size: "mini" } }, [_vm._v("清除")])],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "info-title" }, [_vm._v("商品详细信息")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "goods-info" },
              _vm._l(_vm.list, function (item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "goods-info-item" },
                  [
                    _c("div", { staticClass: "goods-info-item-head" }, [
                      _c("div", { staticClass: "item-head-left" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "item-goods-info" }, [
                        _c("div", { staticClass: "goods-name" }, [
                          _vm._v(_vm._s(item.goodsName)),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "goods-id" }, [
                          _vm._v("商品ID:" + _vm._s(item.goodsId)),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "goods-info-item-title" }, [
                      _c("div", { staticClass: "item-title-title" }, [
                        _c("span", [_vm._v("*")]),
                        _vm._v("商品详情顶部文本："),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "item-title-info" },
                        [
                          _c("el-input", {
                            attrs: {
                              size: "mini",
                              placeholder: "请输入",
                              maxlength: "60",
                              "show-word-limit": "",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                )
              }),
              0
            ),
          ]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.batchTextDialog = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.batchTextDialog = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
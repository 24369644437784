var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "wrapper" }, [
      _c(
        "div",
        { staticClass: "box-content" },
        [
          _c("img", {
            staticStyle: { width: "180px", height: "60px" },
            attrs: {
              src: "https://oss.feiniao123.com/file/feiNiaoProductManagement/logo/e803711f0f2bc48c6c482a19ba5e4b1.png",
              alt: "",
            },
          }),
          _vm._v(" "),
          _c("p", { staticClass: "conTitle" }, [
            _vm._v("欢迎您授权飞鸟店铺管家"),
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.codeUrl,
                expression: "codeUrl",
              },
            ],
            staticClass: "input",
            attrs: { disabled: "" },
            domProps: { value: _vm.codeUrl },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.codeUrl = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "auth-btn",
              attrs: { size: "small" },
              on: { click: _vm.copyText },
            },
            [_vm._v("\n        复制授权码\n      ")]
          ),
          _vm._v(" "),
          _c(
            "p",
            { staticStyle: { "margin-top": "20px" } },
            [
              _c(
                "el-button",
                {
                  staticClass: "auth-btn",
                  attrs: { size: "small" },
                  on: { click: _vm.downApp },
                },
                [_vm._v("\n          下载客户端\n        ")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "auth-btn",
                  attrs: { size: "small" },
                  on: { click: _vm.downApp },
                },
                [_vm._v("\n          备用地址下载\n        ")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { staticClass: "auth-btn", attrs: { size: "small" } },
                [_vm._v("\n          联系客服\n        ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
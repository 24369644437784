"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _dashboard = require("@/api/dashboard");
var _shop = require("@/api/shop");
var _jsAPI = require("svgo/lib/svgo/jsAPI");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      app_isClient: this.$store.state.user.app_isClient,
      userName: this.$store.getters.name,
      bannerList: [],
      noticeList: [],
      iconList: [{
        id: 1,
        img: require('@/assets/imgs/icon01.png'),
        title: '商品批量修改',
        path: '/goodsManagement/AllGoodsEdit'
      }, {
        id: 2,
        img: require('@/assets/imgs/icon02.png'),
        title: '全店商品导出',
        path: '/goodsManagement/AllGoodsExport'
      }, {
        id: 9,
        img: require('@/assets/imgs/Home112.png'),
        title: '首页',
        path: '/dashboard'
      }, {
        id: 8,
        img: require('@/assets/imgs/icon08.png'),
        title: 'AI商品运营',
        path: '/goodsManagement/AIGoodsOperation'
      }, {
        id: 3,
        img: require('@/assets/imgs/icon03.png'),
        title: '授权店铺复制',
        path: '/goodsManagement/AuthStoreCopy'
      }, {
        id: 4,
        img: require('@/assets/imgs/toool1.png'),
        title: '令牌购买',
        path: '/updateTool'
      }, {
        id: 11,
        img: require('@/assets/imgs/lll.png'),
        title: 'SKU改文字',
        path: '/goodsManagement/AIskuTxt'
      }, {
        id: 5,
        img: require('@/assets/imgs/icon05.png'),
        title: '店铺管理',
        path: '/shopManagement/index'
      }, {
        id: 10,
        img: require('@/assets/imgs/hot111.png'),
        title: '爆款裂变',
        path: '/goodsManagement/goodSplit'
      }, {
        id: 12,
        img: require('@/assets/imgs/icon07.png'),
        title: '机会商品裂变',
        path: '/goodsManagement/OppotunitySplit'
      }],
      businessState: false,
      shopNum: 0
    };
  },
  created: function created() {
    this.getBanner();
    this.getNotice();
    this.getcheckLoginStatusApi();
  },
  methods: {
    // 立即升级
    upHandle: function upHandle() {
      // console.log(2342342340000, this.$store.state.user.businessState)
      if (this.$store.state.user.businessCode > 0) {
        this.$router.push('/shopManagement/index');
      } else {
        this.$router.push('/updateTool/index');
      }
    },
    // 立即下载
    upDownHandle: function upDownHandle() {
      console.log(2344242222222);
      window.open('https://wwue.lanzoue.com/iCr952htgmbi');
    },
    // 点击开通
    openSet: function openSet() {
      if (this.$store.state.user.businessCode > 0) {
        this.$router.push('/shopManagement/index');
      } else {
        this.$router.push('/updateTool/index');
      }
    },
    // 点击icon跳转页面
    handlePageClick: function handlePageClick(item) {
      console.log(item);
      this.$router.push(item.path);
    },
    // 获取轮播图数据
    getBanner: function getBanner() {
      var _this = this;
      (0, _dashboard.bannerList)().then(function (res) {
        // console.log(res);
        _this.bannerList = res;
      });
    },
    // 获取公告数据
    getNotice: function getNotice() {
      var _this2 = this;
      (0, _dashboard.noticeList)({
        key: 'notice'
      }).then(function (res) {
        // console.log(res);
        _this2.noticeList = res.split('|');
      });
    },
    getcheckLoginStatusApi: function getcheckLoginStatusApi() {
      var _this3 = this;
      (0, _shop.checkLoginStatusApi)({}).then(function (res) {
        // console.log(res);
        if (res) {
          _this3.shopNum = res.length;
        }
        // 查找数组对象res中isBusiness是否有等于1的值
        var isBusiness = res.find(function (item) {
          return item.isBusiness == 1;
        });
        if (isBusiness) {
          _this3.businessState = true;
        } else {
          _this3.businessState = false;
        }
      });
    }
  }
};
"use strict";

var Decimal = require('decimal.js');
var decimal = function decimal(value) {
  return new Decimal(value);
};
var add = function add(a, b) {
  return decimal(a).add(decimal(b)).toNumber();
};
var sub = function sub(a, b) {
  return decimal(a).sub(decimal(b)).toNumber();
};
var mul = function mul(a, b) {
  return decimal(a).mul(decimal(b)).toNumber();
};
var div = function div(a, b) {
  return decimal(a).div(decimal(b)).toNumber();
};
var round = function round(value) {
  var places = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
  return decimal(value).toDecimalPlaces(places);
};
var toString = function toString(value) {
  return decimal(value).toString();
};
module.exports = {
  decimal: decimal,
  add: add,
  sub: sub,
  mul: mul,
  div: div,
  round: round,
  toString: toString
};
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      menuList: [{
        name: '全部',
        id: 0
      }, {
        name: '在售中',
        id: 1
      }, {
        name: '已下架',
        id: 2
      }],
      accordData: [],
      currentIndex: 0,
      exportCurrentIndex: 0,
      activeName: '2',
      catList: [{
        name: '自定义修改',
        id: 0
      }, {
        name: '该库存',
        id: 1
      }, {
        name: 'vs啊',
        id: 2
      }, {
        name: '是法国',
        id: 3
      }, {
        name: 'ssdf',
        id: 4
      }, {
        name: 'gs',
        id: 5
      }, {
        name: '的身份噶地方',
        id: 6
      }, {
        name: '钱钱钱',
        id: 7
      }, {
        name: '岁的法国',
        id: 8
      }, {
        name: '啊飒飒东风卫视',
        id: 9
      }, {
        name: '是的',
        id: 11
      }, {
        name: 'afgga',
        id: 12
      }, {
        name: '公司的',
        id: 13
      }, {
        name: '十大但是',
        id: 14
      }, {
        name: '等富含淀粉v从',
        id: 15
      }, {
        name: '实现从程序文案',
        id: 16
      }],
      pageLoading: false
    };
  },
  methods: {
    // 顶部tab切换事件
    handleClick: function handleClick() {
      console.log('click');
    },
    // 分类点击事件
    catHandle: function catHandle(tab, val) {
      console.log(tab, val);
      this.currentIndex = tab;
    },
    // 导出的商品
    catExportHandle: function catExportHandle(tab, val) {
      console.log(tab, val);
      this.exportCurrentIndex = tab;
    }
  }
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: { title: "编辑预售", width: "60%", visible: true },
          on: { open: _vm.dialogOpen, close: _vm.dialogClose },
        },
        [
          _c("div", { staticClass: "dialog-content" }, [
            _c("div", [_vm._v("已选商品数" + _vm._s(_vm.list.length))]),
            _vm._v(" "),
            _c("div", { staticClass: "edit-item" }, [
              _c("div", { staticClass: "edit-item-title" }, [
                _c("span", [_vm._v("*")]),
                _vm._v("是否预售："),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "edit-item-info" },
                [
                  _c(
                    "el-radio-group",
                    {
                      on: { change: _vm.changePreSale },
                      model: {
                        value: _vm.preSale,
                        callback: function ($$v) {
                          _vm.preSale = $$v
                        },
                        expression: "preSale",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("非预售"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v("定时预售"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 3 } }, [
                        _vm._v("时段预售"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 4 } }, [
                        _vm._v("规格预售"),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini" },
                      on: {
                        click: function ($event) {
                          return _vm.executeHandle(1)
                        },
                      },
                    },
                    [_vm._v("执行")]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _vm.preSale === 2 || _vm.preSale === 4
              ? _c("div", { staticClass: "edit-item" }, [
                  _c("div", { staticClass: "edit-item-title" }, [
                    _c("span", [_vm._v("*")]),
                    _vm._v("预售时间："),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "edit-item-info" },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          size: "mini",
                          type: "date",
                          "picker-options": _vm.pickerOptions,
                          "value-format": "timestamp",
                          placeholder: "选择日期",
                        },
                        model: {
                          value: _vm.preSaleTime,
                          callback: function ($$v) {
                            _vm.preSaleTime = $$v
                          },
                          expression: "preSaleTime",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", { staticStyle: { "margin-left": "10px" } }, [
                        _vm._v(
                          "需在" +
                            _vm._s(_vm.formatDate(_vm.preSaleTime, 1)) +
                            " 23:59:59前完成发货"
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini" },
                          on: {
                            click: function ($event) {
                              return _vm.executeHandle(2)
                            },
                          },
                        },
                        [_vm._v("执行")]
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.preSale === 3
              ? _c("div", { staticClass: "edit-item" }, [
                  _c("div", { staticClass: "edit-item-title" }, [
                    _c("span", [_vm._v("*")]),
                    _vm._v("预售时间："),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "edit-item-info" },
                    [
                      _c("span", [_vm._v("支付成功后")]),
                      _vm._v(" "),
                      _c(
                        "el-select",
                        {
                          attrs: { size: "mini", placeholder: "请选择" },
                          model: {
                            value: _vm.timeSale,
                            callback: function ($$v) {
                              _vm.timeSale = $$v
                            },
                            expression: "timeSale",
                          },
                        },
                        _vm._l(15, function (item, i) {
                          return _c("el-option", {
                            attrs: {
                              label: item + "天",
                              size: "mini",
                              value: item,
                            },
                          })
                        }),
                        1
                      ),
                      _vm._v(" "),
                      _c("span", { staticStyle: { "margin-left": "10px" } }, [
                        _vm._v("天内发货"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini" },
                          on: {
                            click: function ($event) {
                              return _vm.executeHandle(3)
                            },
                          },
                        },
                        [_vm._v("执行")]
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.preSale !== 3
              ? _c("div", { staticClass: "edit-item" }, [
                  _c("div", { staticClass: "edit-item-title" }, [
                    _c("span", [_vm._v("*")]),
                    _vm._v("发货时间："),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "edit-item-info" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.deliveryTimeType,
                            callback: function ($$v) {
                              _vm.deliveryTimeType = $$v
                            },
                            expression: "deliveryTimeType",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("24小时"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("48小时"),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini" },
                          on: {
                            click: function ($event) {
                              return _vm.executeHandle(4)
                            },
                          },
                        },
                        [_vm._v("执行")]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticStyle: { color: "red", "margin-left": "10px" },
                        },
                        [_vm._v("时段预售设置为其它预售需重新设置发货时间")]
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "edit-item" }, [
              _c("div", { staticClass: "edit-item-title" }, [
                _c("span", [_vm._v("*")]),
                _vm._v("批量设置规格值包含："),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "edit-item-info" },
                [
                  _c("el-input", {
                    staticStyle: { width: "120px" },
                    attrs: {
                      size: "mini",
                      placeholder: "请输入",
                      "aria-placeholder": "",
                    },
                    model: {
                      value: _vm.skuVal,
                      callback: function ($$v) {
                        _vm.skuVal = $$v
                      },
                      expression: "skuVal",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v("的SKU的预售时间")]),
                  _vm._v(" "),
                  _c("el-date-picker", {
                    attrs: {
                      size: "mini",
                      type: "date",
                      "picker-options": _vm.pickerOptions,
                      "value-format": "timestamp",
                      placeholder: "选择日期",
                    },
                    model: {
                      value: _vm.skuDate,
                      callback: function ($$v) {
                        _vm.skuDate = $$v
                      },
                      expression: "skuDate",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini" },
                      on: {
                        click: function ($event) {
                          return _vm.executeHandle(5)
                        },
                      },
                    },
                    [_vm._v("执行")]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "info-title" }, [_vm._v("商品")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "goods-detail" },
              _vm._l(_vm.list, function (item, index) {
                return _c(
                  "el-collapse",
                  {
                    key: index,
                    on: { change: _vm.handleChange },
                    model: {
                      value: _vm.activeNames,
                      callback: function ($$v) {
                        _vm.activeNames = $$v
                      },
                      expression: "activeNames",
                    },
                  },
                  [
                    _c(
                      "el-collapse-item",
                      { attrs: { title: "", name: index } },
                      [
                        _c("template", { slot: "title" }, [
                          _c("div", { staticClass: "goods-detail-info" }, [
                            _c("img", {
                              attrs: { src: item.goodsPicture, alt: "" },
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "goods-detail-text" }, [
                              _c("div", { staticClass: "goods-name" }, [
                                _vm._v(_vm._s(item.goodsName)),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "goods-id" }, [
                                _vm._v("商品ID:" + _vm._s(item.goodsId)),
                              ]),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "edit-box" }, [
                          _c(
                            "div",
                            { staticClass: "option" },
                            [
                              _c("div", { staticClass: "option-title" }, [
                                _c("span", [_vm._v("*")]),
                                _vm._v("是否预售:"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-radio-group",
                                {
                                  on: {
                                    change: function (e) {
                                      _vm.changeRadio(e, item)
                                    },
                                  },
                                  model: {
                                    value: item.preSale,
                                    callback: function ($$v) {
                                      _vm.$set(item, "preSale", $$v)
                                    },
                                    expression: "item.preSale",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: 1 } }, [
                                    _vm._v("非预售"),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-radio", { attrs: { label: 2 } }, [
                                    _vm._v("定时预售"),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-radio", { attrs: { label: 3 } }, [
                                    _vm._v("时段预售"),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-radio", { attrs: { label: 4 } }, [
                                    _vm._v("规格预售"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          item.preSale === 2
                            ? _c("div", { staticClass: "option" }, [
                                _c("div", { staticClass: "option-title" }, [
                                  _c("span", [_vm._v("*")]),
                                  _vm._v("预售时间:"),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  [
                                    _c("el-date-picker", {
                                      attrs: {
                                        type: "date",
                                        placeholder: "选择日期",
                                        "picker-options": _vm.pickerOptions,
                                        "value-format": "timestamp",
                                      },
                                      on: { change: _vm.changeDate },
                                      model: {
                                        value: item.time,
                                        callback: function ($$v) {
                                          _vm.$set(item, "time", $$v)
                                        },
                                        expression: "item.time",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: { "margin-left": "10px" },
                                      },
                                      [
                                        _vm._v(
                                          "需在" +
                                            _vm._s(
                                              _vm.formatDate(item.time, 1)
                                            ) +
                                            " 23:59:59前完成发货"
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          item.preSale === 3
                            ? _c("div", { staticClass: "option" }, [
                                _c("div", { staticClass: "option-title" }, [
                                  _c("span", [_vm._v("*")]),
                                  _vm._v("预售时间:"),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  [
                                    _c("span", [_vm._v("支付成功后")]),
                                    _vm._v(" "),
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          size: "mini",
                                          placeholder: "请选择",
                                        },
                                        model: {
                                          value: item.timeSale,
                                          callback: function ($$v) {
                                            _vm.$set(item, "timeSale", $$v)
                                          },
                                          expression: "item.timeSale",
                                        },
                                      },
                                      _vm._l(15, function (item, i) {
                                        return _c("el-option", {
                                          attrs: {
                                            label: item + "天",
                                            size: "mini",
                                            value: item,
                                          },
                                        })
                                      }),
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("span", [_vm._v("天内发货")]),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          item.preSale === 4
                            ? _c("div", { staticClass: "unit-box" }, [
                                _c("div", { staticClass: "unit-head" }, [
                                  _c("div", { staticClass: "head-title" }, [
                                    _vm._v("SKU 名称（规格值）"),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "head-title" }, [
                                    _vm._v("预售时间"),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "unit-body" },
                                  _vm._l(
                                    item.skuList,
                                    function (skuItem, skuIndex) {
                                      return _c(
                                        "div",
                                        { key: skuIndex, staticClass: "row" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "name" },
                                            _vm._l(
                                              skuItem.spec,
                                              function (specItem, specIndex) {
                                                return _c(
                                                  "div",
                                                  { key: specIndex },
                                                  [
                                                    _vm._v(
                                                      _vm._s(specItem.specName)
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "date" },
                                            [
                                              _c("el-date-picker", {
                                                attrs: {
                                                  size: "mini",
                                                  type: "date",
                                                  placeholder: "选择日期",
                                                  "picker-options":
                                                    _vm.pickerOptions,
                                                },
                                                model: {
                                                  value: skuItem.skuPreSaleTime,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      skuItem,
                                                      "skuPreSaleTime",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "skuItem.skuPreSaleTime",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ],
                      2
                    ),
                  ],
                  1
                )
              }),
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { size: "mini" }, on: { click: _vm.dialogClose } },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.editConfirm },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
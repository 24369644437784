"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: ["show"],
  data: function data() {
    return {
      activeName: "1",
      linkVal: null,
      linkImgList: [] //链接生成的图片
    };
  },
  methods: {
    delPic: function delPic(i) {
      this.linkImgList.splice(i, 1);
      this.$parent.uploadNum = this.linkImgList.length;
    },
    getImgList: function getImgList(v) {
      console.log(v, "getImgList");
      // if (v) {
      //   v = v.map(item => item.thumbUrl)
      // }
      this.linkImgList = v;
      console.log(this.$parent, 8988898, this.linkImgList);
      // this.linkImgList = v
    },
    // 关闭弹窗
    closeDialog: function closeDialog() {
      this.$emit("update:show", false);
    }
  }
};
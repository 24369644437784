var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$store.state.user.app_isClient == 1
    ? _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.pageLoading,
              expression: "pageLoading",
            },
          ],
          staticClass: "divBox",
        },
        [
          _c(
            "div",
            { staticClass: "tab-box" },
            [
              _c(
                "el-tabs",
                {
                  on: { "tab-click": _vm.handleClick },
                  model: {
                    value: _vm.activeName,
                    callback: function ($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName",
                  },
                },
                [
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "全部", name: "0" } },
                    [
                      _c("div", { staticClass: "_container" }, [
                        _c("div", { staticClass: "copy-box" }, [
                          _c("div", { staticClass: "bottom-table-box" }, [
                            _c("div", { staticClass: "row-bottom" }, [
                              _c("div", { staticClass: "search-box" }, [
                                _c(
                                  "div",
                                  { staticClass: "search-item" },
                                  [
                                    _c("span", { staticClass: "title" }, [
                                      _vm._v("选择复制店铺："),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "el-select",
                                      {
                                        staticClass: "custom-select",
                                        staticStyle: { width: "200px" },
                                        attrs: {
                                          "popper-append-to-body": false,
                                          placeholder: "请选择",
                                          size: "mini",
                                        },
                                        on: { change: _vm.storeChange1 },
                                        model: {
                                          value: _vm.valueSelect1,
                                          callback: function ($$v) {
                                            _vm.valueSelect1 = $$v
                                          },
                                          expression: "valueSelect1",
                                        },
                                      },
                                      _vm._l(
                                        _vm.optionsSelect,
                                        function (item) {
                                          return _c(
                                            "el-option",
                                            {
                                              key: item.id,
                                              attrs: {
                                                disabled:
                                                  item.onlineStatus == 0,
                                                label: item.mallName,
                                                value: item.mallId,
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    float: "left",
                                                  },
                                                },
                                                [_vm._v(_vm._s(item.mallName))]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                    _vm._v(" "),
                                    _vm.$store.state.user.app_isClient == 1
                                      ? _c("i", {
                                          class: [
                                            _vm.rotate1
                                              ? "el-icon-refresh go"
                                              : "el-icon-refresh",
                                          ],
                                          staticStyle: {
                                            margin: "0 5px 0 5px",
                                            "font-size": "18px",
                                            position: "relative",
                                            cursor: "pointer",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.getCloudsShopList(1)
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.getIntegral !== ""
                                      ? _c("span", { staticClass: "title" }, [
                                          _vm._v(
                                            " 积分：" + _vm._s(_vm.getIntegral)
                                          ),
                                        ])
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "search-item" },
                                  [
                                    _c("span", { staticClass: "title" }, [
                                      _vm._v("商品名称："),
                                    ]),
                                    _vm._v(" "),
                                    _c("el-input", {
                                      staticStyle: { width: "180px" },
                                      attrs: {
                                        placeholder: "请输入商品名称",
                                        size: "mini",
                                      },
                                      model: {
                                        value: _vm.goodsName,
                                        callback: function ($$v) {
                                          _vm.goodsName = $$v
                                        },
                                        expression: "goodsName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "search-item" },
                                  [
                                    _c("span", { staticClass: "title" }, [
                                      _vm._v("发布时间："),
                                    ]),
                                    _vm._v(" "),
                                    _c("el-date-picker", {
                                      staticStyle: { width: "240px" },
                                      attrs: {
                                        "end-placeholder": "结束日期",
                                        "range-separator": "至",
                                        size: "mini",
                                        "start-placeholder": "开始日期",
                                        type: "daterange",
                                        "value-format": "timestamp",
                                      },
                                      model: {
                                        value: _vm.postTime,
                                        callback: function ($$v) {
                                          _vm.postTime = $$v
                                        },
                                        expression: "postTime",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          icon: "el-icon-search",
                                          size: "mini",
                                          type: "primary",
                                        },
                                        on: { click: _vm.searchHandle },
                                      },
                                      [_vm._v("搜索")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "row-bottom" }, [
                              _c("div", { staticClass: "search-box" }, [
                                _c(
                                  "div",
                                  { staticClass: "search-item" },
                                  [
                                    _c("span", { staticClass: "title" }, [
                                      _vm._v("选择复制到店铺："),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "el-select",
                                      {
                                        staticClass: "custom-select",
                                        staticStyle: { width: "200px" },
                                        attrs: {
                                          "popper-append-to-body": false,
                                          placeholder: "请选择",
                                          size: "mini",
                                        },
                                        on: { change: _vm.storeChange },
                                        model: {
                                          value: _vm.valueSelect3,
                                          callback: function ($$v) {
                                            _vm.valueSelect3 = $$v
                                          },
                                          expression: "valueSelect3",
                                        },
                                      },
                                      _vm._l(
                                        _vm.optionsSelect,
                                        function (item) {
                                          return _c(
                                            "el-option",
                                            {
                                              key: item.id,
                                              attrs: {
                                                disabled:
                                                  item.opportunityGoodsIsUsable ==
                                                  0,
                                                label: item.mallName,
                                                value: item.mallId,
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    float: "left",
                                                  },
                                                },
                                                [_vm._v(_vm._s(item.mallName))]
                                              ),
                                              _vm._v(" "),
                                              item.opportunityGoodsIsUsable != 1
                                                ? [
                                                    item.opportunityGoodsIsUsable ==
                                                    1
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticStyle: {
                                                              "font-size":
                                                                "13px",
                                                              "margin-left":
                                                                "10px",
                                                            },
                                                          },
                                                          [_vm._v("已开通")]
                                                        )
                                                      : _c(
                                                          "span",
                                                          {
                                                            staticStyle: {
                                                              color: "#f01",
                                                              "font-size":
                                                                "13px",
                                                              "margin-left":
                                                                "10px",
                                                            },
                                                          },
                                                          [_vm._v("未开通")]
                                                        ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "el-link",
                                                      {
                                                        staticStyle: {
                                                          "margin-top": "-4px",
                                                        },
                                                        attrs: {
                                                          type: "primary",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            return _vm.openStore(
                                                              item
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "去开通\n                          "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                : _vm._e(),
                                            ],
                                            2
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                    _vm._v(" "),
                                    _vm.$store.state.user.app_isClient == 1
                                      ? _c("i", {
                                          class: [
                                            _vm.rotate2
                                              ? "el-icon-refresh go"
                                              : "el-icon-refresh",
                                          ],
                                          staticStyle: {
                                            margin: "0 5px 0 5px",
                                            "font-size": "18px",
                                            position: "relative",
                                            cursor: "pointer",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.getCloudsShopList(2)
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.getIntegral2 !== ""
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "title",
                                            staticStyle: {
                                              "margin-right": "10px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " 积分：" +
                                                _vm._s(_vm.getIntegral2)
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "search-item" },
                                  [
                                    _c("span", { staticClass: "title" }, [
                                      _vm._v("运费模板："),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: {
                                          width: "180px",
                                          "margin-right": "10px",
                                        },
                                        attrs: {
                                          placeholder: "请选择",
                                          size: "mini",
                                        },
                                        on: { change: _vm.getPostTemplate },
                                        model: {
                                          value: _vm.costTemplateId,
                                          callback: function ($$v) {
                                            _vm.costTemplateId = $$v
                                          },
                                          expression: "costTemplateId",
                                        },
                                      },
                                      _vm._l(
                                        _vm.templatePostList,
                                        function (item) {
                                          return _c("el-option", {
                                            key: item.templateId,
                                            attrs: {
                                              label: item.templateName,
                                              value: item.templateId,
                                              size: "mini",
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticStyle: { "margin-right": "24px" } },
                                  [
                                    _c(
                                      "el-popover",
                                      {
                                        staticStyle: { color: "red" },
                                        attrs: {
                                          title: "提示",
                                          width: "400",
                                          trigger: "hover",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticStyle: { color: "red" } },
                                          [
                                            _vm._v(
                                              "\n                        首次使用，需要登录店铺，选择机会商品，后续在本店铺裂变/搬家其他产品，仅需查看店铺状态，保证店铺在线，即可进行其他产品机会商品裂变/搬家。\n                      "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-button",
                                          {
                                            staticStyle: {
                                              color: "black",
                                              "font-weight": "bold",
                                            },
                                            attrs: {
                                              slot: "reference",
                                              size: "mini",
                                              type: "warning",
                                              icon: "el-icon-warning",
                                            },
                                            on: { click: _vm.chooseGoods },
                                            slot: "reference",
                                          },
                                          [
                                            _vm._v(
                                              "选择机会商品\n                      "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _vm.opobjList != null
                                      ? _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              color: "#0C8AFF",
                                              cursor: "pointer",
                                              "margin-left": "10px",
                                            },
                                            on: {
                                              click: function ($event) {
                                                _vm.opShow = true
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "查看在线状态\n                    "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticStyle: { "margin-right": "24px" } },
                                  [
                                    !_vm.copyLoading
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              loading: _vm.copyLoading,
                                              size: "mini",
                                              type: "primary",
                                            },
                                            on: { click: _vm.copyNow },
                                          },
                                          [
                                            _vm._v(
                                              "\n                      立刻复制\n                    "
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              size: "mini",
                                              type: "danger",
                                            },
                                            on: { click: _vm.stopNow },
                                          },
                                          [_vm._v("取消复制")]
                                        ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _vm.opptunityObj
                                  ? _c("div", { staticClass: "op-goods-box" }, [
                                      _c("img", {
                                        staticClass: "op-img",
                                        attrs: {
                                          src: _vm.opptunityObj
                                            .selectedChanceGoods.pic_url,
                                          alt: "",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("span", { staticClass: "op-title" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.opptunityObj.selectedChanceGoods
                                              .keywords
                                          )
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "row-bottom" }, [
                              _c("div", { staticClass: "search-box" }, [
                                _c(
                                  "div",
                                  { staticClass: "search-item" },
                                  [
                                    _c("span", { staticClass: "title" }, [
                                      _vm._v("商品发布模式："),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "el-radio",
                                      {
                                        attrs: { label: "0" },
                                        model: {
                                          value: _vm.releaseMode,
                                          callback: function ($$v) {
                                            _vm.releaseMode = $$v
                                          },
                                          expression: "releaseMode",
                                        },
                                      },
                                      [_vm._v("立即上架\n                    ")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-radio",
                                      {
                                        attrs: { label: "1" },
                                        model: {
                                          value: _vm.releaseMode,
                                          callback: function ($$v) {
                                            _vm.releaseMode = $$v
                                          },
                                          expression: "releaseMode",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "放入已下架\n                    "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "table-box" },
                              [
                                _c(
                                  "el-table",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      data: _vm.goodsList,
                                      "header-cell-style": {
                                        background: "#eef1f6",
                                      },
                                      height: "calc(100vh - 390px)",
                                      "row-key": "goodsIdCopy",
                                    },
                                    on: {
                                      "selection-change":
                                        _vm.handleSelectionChange,
                                    },
                                  },
                                  [
                                    _c("el-table-column", {
                                      attrs: {
                                        "reserve-selection": true,
                                        type: "selection",
                                        width: "80",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        align: "center",
                                        label: "序号",
                                        type: "index",
                                        width: "80",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        label: "商品信息",
                                        prop: "date",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c(
                                                  "div",
                                                  { staticClass: "info-box" },
                                                  [
                                                    _c("img", {
                                                      staticClass: "img-g",
                                                      attrs: {
                                                        src: scope.row
                                                          .goodsPicture,
                                                        alt: "",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "info-txt",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "txt-til",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                            " +
                                                                _vm._s(
                                                                  scope.row
                                                                    .goodsName
                                                                ) +
                                                                "\n                          "
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "g-id",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "商品ID:\n                            " +
                                                                _vm._s(
                                                                  scope.row
                                                                    .goodsIdCopy
                                                                ) +
                                                                "\n                          "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        3069640067
                                      ),
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        label: "商品链接",
                                        prop: "name",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _vm._v(
                                                  "\n                      https://mobile.yangkeduo.com/goods.html?goods_id= " +
                                                    _vm._s(
                                                      scope.row.goodsIdCopy
                                                    ) +
                                                    "\n                    "
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        2418613688
                                      ),
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        align: "center",
                                        label: "任务进度",
                                        prop: "message",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(scope.row.message)
                                                  ),
                                                ]),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        1968779917
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "pagetation-box" },
                              [
                                _c("el-pagination", {
                                  attrs: {
                                    "current-page": _vm.currentPage,
                                    "page-size": _vm.limitSize,
                                    "page-sizes": [10, 20, 50, 100],
                                    total: _vm.total,
                                    layout:
                                      "total, sizes, prev, pager, next, jumper",
                                  },
                                  on: {
                                    "size-change": _vm.handleSizeChange,
                                    "current-change": _vm.handleCurrentChange,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-dialog",
                        {
                          attrs: {
                            visible: _vm.opShow,
                            "custom-class": "pay-dialog",
                            title: "查看店铺",
                            width: "60%",
                          },
                          on: {
                            "update:visible": function ($event) {
                              _vm.opShow = $event
                            },
                          },
                        },
                        [
                          _c(
                            "el-table",
                            { attrs: { data: _vm.opobjList, border: "" } },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  label: "图片",
                                  property: "logo",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("img", {
                                            staticStyle: {
                                              width: "50px",
                                              height: "50px",
                                            },
                                            attrs: {
                                              src: scope.row.logo,
                                              alt: "",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2123572454
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  label: "店铺名称",
                                  property: "mallName",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  label: "店铺id",
                                  property: "mallId",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  label: "是否在线",
                                  property: "isOnline",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          scope.row.isOnline
                                            ? _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    color: "#00C050",
                                                  },
                                                },
                                                [_vm._v("在线")]
                                              )
                                            : _c("span", [_vm._v("离线")]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  422859345
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "功能介绍", name: "1" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "_container fundesp",
                          staticStyle: { height: "100%" },
                        },
                        [
                          _c("h3", { staticStyle: { "font-size": "20px" } }, [
                            _vm._v("温馨提示"),
                          ]),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(
                              "1、此功能现在是公测阶段，成功复制一条收取5积分，不成功不收费"
                            ),
                          ]),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(
                              "\n            2、如需使用，请添加客服，领取积分卡，免费开通！\n          "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          _c(
                            "p",
                            { staticStyle: { "margin-bottom": "20px" } },
                            [
                              _vm._v(
                                "\n            3、机会商品多店搬家复制，复制成功的链接都有机会商品标，初始权重较高，100%流量加成！"
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              _c(
                                "el-button",
                                {
                                  staticStyle: { "margin-bottom": "10px" },
                                  attrs: { round: "", type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      _vm.activeName = "0"
                                    },
                                  },
                                },
                                [_vm._v("去使用")]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("el-image", {
                            staticStyle: { border: "#2279E3 1px solid" },
                            attrs: {
                              src: require("@/assets/imgs/图片_059_06.png"),
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "btn-box",
                              staticStyle: {
                                "margin-top": "20px",
                                "margin-bottom": "20px",
                                height: "170px",
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { round: "", type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleClickBtn("recharge")
                                    },
                                  },
                                },
                                [_vm._v("联系客服免费开通")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: { round: "", type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleClickBtn("contactServer")
                                    },
                                  },
                                },
                                [_vm._v("直接充值积分使用")]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-dialog",
                            {
                              attrs: {
                                visible: _vm.centerDialogVisible,
                                center: "",
                                title: "扫码联系客服",
                                width: "30%",
                              },
                              on: {
                                "update:visible": function ($event) {
                                  _vm.centerDialogVisible = $event
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    width: "520px",
                                    display: "flex",
                                    "justify-content": "center",
                                  },
                                },
                                [
                                  _c("img", {
                                    staticStyle: { width: "50%" },
                                    attrs: {
                                      alt: "",
                                      src: "https://oss.feiniao123.com/file/feiNiaoProductManagement/icon/ddb13cb5e0044044fa9c25fb2425a8f.png",
                                    },
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "dialog-footer",
                                  attrs: { slot: "footer" },
                                  slot: "footer",
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      on: {
                                        click: function ($event) {
                                          _vm.centerDialogVisible = false
                                        },
                                      },
                                    },
                                    [_vm._v("取 消")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary" },
                                      on: {
                                        click: function ($event) {
                                          _vm.centerDialogVisible = false
                                        },
                                      },
                                    },
                                    [_vm._v("确 定")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-dialog",
                            {
                              attrs: {
                                visible: _vm.openPay,
                                "custom-class": "pay-dialog",
                                title: "充值",
                                width: "1248px",
                              },
                              on: {
                                "update:visible": function ($event) {
                                  _vm.openPay = $event
                                },
                              },
                            },
                            [
                              _c("payment", {
                                ref: "payments",
                                attrs: { shopData: _vm.shopData },
                                on: {
                                  close: function ($event) {
                                    _vm.openPay = false
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      )
    : _c(
        "div",
        { staticClass: "_container c-bg", on: { click: _vm.downloadHandle } },
        [
          _c("div", { staticClass: "left-bg-box" }),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.opShow,
                "custom-class": "pay-dialog",
                title: "查看店铺",
                width: "60%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.opShow = $event
                },
              },
            },
            [
              _c(
                "el-table",
                { attrs: { data: _vm.opobjList, border: "" } },
                [
                  _c("el-table-column", {
                    attrs: { align: "center", label: "图片", property: "logo" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("img", {
                              staticStyle: { width: "50px", height: "50px" },
                              attrs: { src: scope.row.logo, alt: "" },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "店铺名称",
                      property: "mallName",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "店铺id",
                      property: "mallId",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "是否在线",
                      property: "isOnline",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.isOnline
                              ? _c(
                                  "span",
                                  { staticStyle: { color: "#00C050" } },
                                  [_vm._v("在线")]
                                )
                              : _c("span", [_vm._v("离线")]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.pageLoading,
          expression: "pageLoading",
        },
      ],
      staticClass: "divBox",
    },
    [
      _c("div", { staticClass: "notice" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "_container" },
        [
          _c(
            "div",
            { staticClass: "tab-box" },
            [
              _c(
                "el-tabs",
                {
                  on: { "tab-click": _vm.handleClick },
                  model: {
                    value: _vm.activeName,
                    callback: function ($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName",
                  },
                },
                [
                  _c("el-tab-pane", { attrs: { label: "全部", name: "2" } }),
                  _vm._v(" "),
                  _c("el-tab-pane", { attrs: { label: "在售中", name: "1" } }),
                  _vm._v(" "),
                  _c("el-tab-pane", { attrs: { label: "已下架", name: "0" } }),
                  _vm._v(" "),
                  _c("el-tab-pane", {
                    attrs: { label: "编辑记录", name: "3" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.activeName == "3"
            ? _c(
                "div",
                [
                  [
                    _c(
                      "div",
                      {
                        staticClass: "search-box",
                        staticStyle: { "margin-bottom": "10px" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "search-item" },
                          [
                            _c("span", { staticClass: "title" }, [
                              _vm._v("选择店铺："),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-select",
                              {
                                staticClass: "custom-select",
                                staticStyle: { width: "250px" },
                                attrs: {
                                  "popper-append-to-body": false,
                                  "collapse-tags": "",
                                  multiple: "",
                                  placeholder: "请选择",
                                  size: "mini",
                                },
                                on: {
                                  change: _vm.storeChange2,
                                  "remove-tag": _vm.removeTag1,
                                },
                                model: {
                                  value: _vm.valueSelect2,
                                  callback: function ($$v) {
                                    _vm.valueSelect2 = $$v
                                  },
                                  expression: "valueSelect2",
                                },
                              },
                              [
                                _vm._l(_vm.optionsSelect1, function (item) {
                                  return _c(
                                    "el-option",
                                    {
                                      key: item.id,
                                      attrs: {
                                        disabled: item.onlineStatus == 0,
                                        label: item.mallName,
                                        value: item.mallId,
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "options-list",
                                          staticStyle: { width: "240px" },
                                        },
                                        [
                                          _c(
                                            "el-checkbox",
                                            {
                                              staticStyle: { width: "112%" },
                                              attrs: {
                                                disabled:
                                                  item.onlineStatus == 0,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.checkboxChange1(
                                                    item
                                                  )
                                                },
                                              },
                                              model: {
                                                value: item.check,
                                                callback: function ($$v) {
                                                  _vm.$set(item, "check", $$v)
                                                },
                                                expression: "item.check",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    display: "inline-block",
                                                    width: "130px",
                                                    overflow: "hidden",
                                                    "vertical-align": "middle",
                                                  },
                                                },
                                                [_vm._v(_vm._s(item.mallName))]
                                              ),
                                              _vm._v(
                                                "\n                       \n                      "
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    color: "#f01",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "  积分:" +
                                                      _vm._s(
                                                        Number(item.integral) +
                                                          Number(
                                                            item.giftIntegral
                                                          )
                                                      )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "checkboxWrapper" },
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        on: { change: _vm.checkChangeAll1 },
                                        model: {
                                          value: _vm.checkedAll1,
                                          callback: function ($$v) {
                                            _vm.checkedAll1 = $$v
                                          },
                                          expression: "checkedAll1",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                    全选\n                  "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c("i", {
                              class: [
                                _vm.rotate
                                  ? "el-icon-refresh go"
                                  : "el-icon-refresh",
                              ],
                              staticStyle: {
                                margin: "0 5px 0 5px",
                                "font-size": "18px",
                                position: "relative",
                                cursor: "pointer",
                              },
                              on: { click: _vm.getCloudsShopList1 },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  size: "mini",
                                  type: "primary",
                                  icon: "el-icon-search",
                                },
                                on: { click: _vm.searcAccordHandle },
                              },
                              [_vm._v("搜索")]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  _vm._v(" "),
                  _c(
                    "el-table",
                    {
                      staticStyle: { "margin-bottom": "10px" },
                      attrs: {
                        data: _vm.taskListDataEdit,
                        border: "",
                        height: "calc(100vh - 280px)",
                        "header-cell-style": { background: "#eef1f6" },
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          type: "index",
                          label: "序号",
                          align: "center",
                          width: "50",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { align: "center", label: "商品来源店铺" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(scope.row.authStoreName) +
                                      "\n            "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3154259245
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "editType",
                          label: "任务类型",
                          align: "center",
                          width: "110px",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.type(scope.row.editType)) +
                                      "\n            "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2658587840
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "allCount",
                          label: "总数量",
                          align: "center",
                          width: "110px",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "successCount",
                          label: "成功数量",
                          align: "center",
                          width: "110px",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: ".failCount",
                          width: "110px",
                          align: "center",
                          label: "失败数量",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "createTime",
                          align: "center",
                          width: "260px",
                          label: "任务创建时间",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "status",
                          width: "110px",
                          align: "center",
                          label: "任务状态",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.status == 0
                                    ? _c("span", [_vm._v("待处理")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  scope.row.status == 1
                                    ? _c("span", [_vm._v("处理中")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  scope.row.status == 2
                                    ? _c("span", [_vm._v("已完成")])
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3054665557
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "操作", align: "center", width: "220" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary", size: "small" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.showtaskHandle(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("查看日志")]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          236147284
                        ),
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-pagination", {
                    attrs: {
                      "current-page": _vm.currentPageRecord,
                      "page-sizes": [10, 20, 50, 100],
                      "page-size": _vm.limitRecord,
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.totalRecord,
                    },
                    on: {
                      "size-change": _vm.handleSizeRecordChange,
                      "current-change": _vm.handleCurrentRecordChange,
                    },
                  }),
                ],
                2
              )
            : _vm.activeName == "4"
            ? _c("div", { staticClass: "list-table-box" }, [
                _c("div", { staticClass: "content-box" }, [
                  _c("div", { staticClass: "content-item" }, [
                    _c("span", { staticClass: "title" }, [_vm._v("SKU文字")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "text-box" },
                      [
                        _c("el-input", {
                          attrs: {
                            type: "textarea",
                            autosize: { minRows: 13, maxRows: 13 },
                            resize: "none",
                            placeholder: "请输入内容",
                          },
                          model: {
                            value: _vm.sku,
                            callback: function ($$v) {
                              _vm.sku = $$v
                            },
                            expression: "sku",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "bottom-box" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "primary" },
                            on: { click: _vm.generateSku },
                          },
                          [_vm._v("开始生成\n              ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "margin-left": "10px",
                              color: "red",
                            },
                          },
                          [
                            _vm._v(
                              "预计消耗" +
                                _vm._s(
                                  (
                                    0.03 * _vm.sku.trim().split("\n").length
                                  ).toFixed(2)
                                ) +
                                "个积分"
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content-item" }, [
                    _c("span", { staticClass: "title" }, [
                      _vm._v("AI自动生成SKU文字"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "text-box",
                        staticStyle: { padding: "5px" },
                      },
                      _vm._l(_vm.geneList, function (item) {
                        return _c(
                          "div",
                          { key: item, staticClass: "item-text" },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(item) +
                                "\n              "
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "bottom-box" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "mini" },
                            on: { click: _vm.copy },
                          },
                          [_vm._v("一键复制\n              ")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ])
            : [
                _c("div", { staticClass: "search-box" }, [
                  _vm.$store.state.user.app_isClient == 1
                    ? _c(
                        "div",
                        { staticClass: "search-item" },
                        [
                          _c("span", { staticClass: "title" }, [
                            _vm._v("店铺："),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-select",
                            {
                              staticClass: "custom-select",
                              staticStyle: { width: "200px" },
                              attrs: {
                                "popper-append-to-body": false,
                                placeholder: "请选择",
                                size: "mini",
                              },
                              on: { change: _vm.storeChange },
                              model: {
                                value: _vm.valueSelect,
                                callback: function ($$v) {
                                  _vm.valueSelect = $$v
                                },
                                expression: "valueSelect",
                              },
                            },
                            _vm._l(_vm.optionsSelect, function (item) {
                              return _c(
                                "el-option",
                                {
                                  key: item.id,
                                  attrs: {
                                    disabled: item.onlineStatus == 0,
                                    label: item.mallName,
                                    value: item.id,
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { float: "left" } },
                                    [_vm._v(_vm._s(item.mallName))]
                                  ),
                                ]
                              )
                            }),
                            1
                          ),
                          _vm._v(" "),
                          _c("i", {
                            class: [
                              _vm.rotate
                                ? "el-icon-refresh go"
                                : "el-icon-refresh",
                            ],
                            staticStyle: {
                              margin: "0 5px 0 5px",
                              "font-size": "18px",
                              position: "relative",
                              cursor: "pointer",
                            },
                            on: { click: _vm.getCloudsShopList },
                          }),
                          _vm._v(" "),
                          _vm.getIntegral !== ""
                            ? _c("span", { staticClass: "title" }, [
                                _vm._v(" 积分：" + _vm._s(_vm.getIntegral)),
                              ])
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "search-item" },
                    [
                      _c("span", { staticClass: "title" }, [
                        _vm._v("商品ID："),
                      ]),
                      _vm._v(" "),
                      _c("el-input", {
                        staticStyle: { width: "180px" },
                        attrs: { size: "mini", placeholder: "请输入商品id" },
                        model: {
                          value: _vm.goodsId,
                          callback: function ($$v) {
                            _vm.goodsId = $$v
                          },
                          expression: "goodsId",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "search-item" },
                    [
                      _c("span", { staticClass: "title" }, [
                        _vm._v("发布时间："),
                      ]),
                      _vm._v(" "),
                      _c("el-date-picker", {
                        staticStyle: { width: "280px" },
                        attrs: {
                          size: "mini",
                          "value-format": "timestamp",
                          type: "daterange",
                          "range-separator": "至",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                        },
                        model: {
                          value: _vm.postTime,
                          callback: function ($$v) {
                            _vm.postTime = $$v
                          },
                          expression: "postTime",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "btn" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            size: "mini",
                            icon: "el-icon-search",
                          },
                          on: { click: _vm.searchHandle },
                        },
                        [_vm._v("搜索")]
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "options-box",
                    staticStyle: { "margin-top": "15px" },
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.aiHandleClick(1)
                          },
                        },
                      },
                      [_vm._v("AI 改SKU文字")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.aiHandleClick(2)
                          },
                        },
                      },
                      [_vm._v("AI 四宫格")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.aiHandleClick(3)
                          },
                        },
                      },
                      [_vm._v("AI 加水印")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.aiHandleClick(4)
                          },
                        },
                      },
                      [_vm._v("AI 加边框")]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-drawer",
                  {
                    attrs: {
                      title: "AI改SKU文字",
                      size: "50%",
                      visible: _vm.drawer,
                      "before-close": _vm.drawerCloseCallBack,
                    },
                    on: {
                      "update:visible": function ($event) {
                        _vm.drawer = $event
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.skuLchanloding,
                            expression: "skuLchanloding",
                          },
                        ],
                        staticClass: "container",
                      },
                      [
                        _c("div", { staticClass: "introduce" }, [
                          _c("div", { staticClass: "top" }, [
                            _c("i", { staticClass: "el-icon-warning" }),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                "AI换SKU文字功能的使用会消耗积分，每换1个规格将消耗1个积分。"
                              ),
                            ]),
                            _vm._v(" "),
                            _vm.$store.state.user.app_isClient == 0
                              ? _c(
                                  "span",
                                  { staticStyle: { color: "#1890FF" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        Number(
                                          _vm.$store.state.user.userInfo
                                            .integral
                                        ) +
                                          Number(
                                            _vm.$store.state.user.userInfo
                                              .giftIntegral
                                          )
                                      ) + "积分"
                                    ),
                                  ]
                                )
                              : _c(
                                  "span",
                                  { staticStyle: { color: "#1890FF" } },
                                  [_vm._v(_vm._s(_vm.getIntegral) + "积分")]
                                ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "btn",
                                on: { click: _vm.goIntegral },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/imgs/再次购买.png"),
                                    alt: "",
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", [_vm._v("积分充值")]),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "bootom" }, [
                            _vm._v(
                              "若成功修改SKU后要立即对同一商品再次进行修改SKU，请刷新页面后再修改，否则可能导致修改失败。\n              "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "content" },
                          [
                            _c("div", { staticClass: "title" }, [
                              _vm._v(
                                "商品1:" + _vm._s(_vm.chooseGoodInfo.goodsName)
                              ),
                            ]),
                            _vm._v(" "),
                            _vm._l(_vm.specAndIdList, function (item) {
                              return _c(
                                "div",
                                { key: item.id, staticClass: "sku-box" },
                                [
                                  _c("div", { staticClass: "top-container" }, [
                                    _c("div", { staticClass: "lef" }, [
                                      _c("span", [
                                        _vm._v(
                                          "(主)" + _vm._s(Object.keys(item)[0])
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("span", [_vm._v("容量")]),
                                      _vm._v(" "),
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            item[Object.keys(item)[0]].length
                                          ) + "个"
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "right" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "btn",
                                          on: {
                                            click: function ($event) {
                                              _vm.AIChangeSkuAll(
                                                item,
                                                item[Object.keys(item)[0]],
                                                _vm.index
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "AI一键修改SKU文字\n                    "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "btn",
                                          on: {
                                            click: function ($event) {
                                              _vm.skuReStore(
                                                item[Object.keys(item)[0]]
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-refresh",
                                          }),
                                          _vm._v(
                                            "\n                      一键还原\n                    "
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "bootom-container" },
                                    _vm._l(
                                      item[Object.keys(item)[0]],
                                      function (item2) {
                                        return _c(
                                          "div",
                                          {
                                            key: item2.id,
                                            staticClass: "item-box",
                                          },
                                          [
                                            _c("el-input", {
                                              staticStyle: { width: "350px" },
                                              attrs: {
                                                maxlength: 30,
                                                "show-word-limit": "",
                                                placeholder: "请输入内容",
                                                size: "mini",
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.changeInputSkuText(
                                                    $event,
                                                    item2.id
                                                  )
                                                },
                                              },
                                              model: {
                                                value: item2.value,
                                                callback: function ($$v) {
                                                  _vm.$set(item2, "value", $$v)
                                                },
                                                expression: "item2.value",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("div", { staticClass: "btn" }, [
                                              _c("i", {
                                                staticClass: "el-icon-refresh",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.skuSingleRestore(
                                                      item2
                                                    )
                                                  },
                                                },
                                              }),
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "btn",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.AIChangeSku(
                                                      item2
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("AI")]
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]
                              )
                            }),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "bootom-btn" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: { click: _vm.submitHandle },
                              },
                              [_vm._v("开始执行")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: { click: _vm.drawerCloseCallBack },
                              },
                              [_vm._v("取消")]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {},
                  [
                    _c(
                      "el-table",
                      {
                        ref: "multipleTable",
                        staticStyle: { width: "100%", "margin-top": "20px" },
                        attrs: {
                          data: _vm.tableData,
                          height: "calc(100vh - 320px)",
                          "header-cell-style": {
                            background: "#eef1f6",
                            fontWeight: "700",
                          },
                        },
                        on: { "selection-change": _vm.handleSelectionChange },
                      },
                      [
                        _c("el-table-column", {
                          attrs: { type: "selection", width: "55" },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            label: "商品信息",
                            align: "center",
                            width: "300",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("div", { staticClass: "info-box" }, [
                                    _c("img", {
                                      staticClass: "img-g",
                                      attrs: {
                                        src: scope.row.goodsPicture,
                                        alt: "",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "info-txt" }, [
                                      _c("div", { staticClass: "txt-til" }, [
                                        _vm._v(
                                          "\n                      " +
                                            _vm._s(scope.row.goodsName) +
                                            "\n                    "
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "g-id" }, [
                                        _vm._v(
                                          "商品ID:\n                      " +
                                            _vm._s(scope.row.goodsId) +
                                            "\n                    "
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                ]
                              },
                            },
                          ]),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            prop: "price",
                            align: "center",
                            width: "250",
                            label: "价格",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("div", { staticClass: "price" }, [
                                    _c("div", { staticClass: "price-box" }, [
                                      _c(
                                        "p",
                                        { staticStyle: { color: "#FF003E" } },
                                        [
                                          _vm._v(
                                            "拼单价：" +
                                              _vm._s(scope.row.minGroupPrice) +
                                              "-" +
                                              _vm._s(scope.row.maxGroupPrice) +
                                              "￥"
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("p", [
                                        _vm._v(
                                          "单买价：" +
                                            _vm._s(
                                              scope.row.minSinglePurchasePrice
                                            ) +
                                            "-" +
                                            _vm._s(
                                              scope.row.maxSinglePurchasePrice
                                            ) +
                                            "￥"
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("p", [
                                        _vm._v(
                                          "参考价：" +
                                            _vm._s(scope.row.marketPrice) +
                                            "￥"
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                ]
                              },
                            },
                          ]),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { label: "SKU", align: "center" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(scope.row.sku) +
                                      "\n              "
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { label: "状态", align: "center" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.isOnsale == 0
                                    ? _c(
                                        "el-tag",
                                        { attrs: { type: "danger" } },
                                        [_vm._v("已下架")]
                                      )
                                    : _c(
                                        "el-tag",
                                        { attrs: { type: "success" } },
                                        [_vm._v("上架中")]
                                      ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            prop: "goodsQuantity",
                            label: "库存",
                            align: "center",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            prop: "presaleWay",
                            label: "是否预售",
                            align: "center",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            label: "操作",
                            width: "250",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-dropdown",
                                    {
                                      attrs: { trigger: "click" },
                                      on: {
                                        command: function ($event) {
                                          return _vm.edithandle(
                                            $event,
                                            scope.row
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "mini",
                                            type: "primary",
                                          },
                                        },
                                        [
                                          _vm._v("\n                    编辑"),
                                          _c("i", {
                                            staticClass:
                                              "el-icon-arrow-down el-icon--right",
                                          }),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-dropdown-menu",
                                        {
                                          attrs: { slot: "dropdown" },
                                          slot: "dropdown",
                                        },
                                        _vm._l(_vm.editList, function (item) {
                                          return _c(
                                            "el-dropdown-item",
                                            {
                                              key: item.id,
                                              attrs: { command: item },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(item.title) +
                                                  "\n                    "
                                              ),
                                            ]
                                          )
                                        }),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  scope.row.isOnsale == 1
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "primary",
                                            size: "mini",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.setGoodsStatus(
                                                scope.row,
                                                0
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                  下架\n                "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  scope.row.isOnsale == 0
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "primary",
                                            size: "mini",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.setGoodsStatus(
                                                scope.row,
                                                1
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                  上架\n                "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { "margin-left": "0" },
                                      attrs: { type: "primary", size: "mini" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.delHandle(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("删除\n                ")]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "pagetation-box" },
                  [
                    _c("el-pagination", {
                      attrs: {
                        "current-page": _vm.currentPage,
                        "page-size": _vm.limit,
                        layout: "total, prev, pager, next",
                        total: _vm.total,
                      },
                      on: {
                        "update:currentPage": function ($event) {
                          _vm.currentPage = $event
                        },
                        "update:current-page": function ($event) {
                          _vm.currentPage = $event
                        },
                        "current-change": _vm.handleCurrentChange,
                      },
                    }),
                  ],
                  1
                ),
              ],
          _vm._v(" "),
          _c("imageEdit", {
            ref: "imgEditRef",
            attrs: { show: _vm.imgEditVisible },
            on: {
              "update:show": function ($event) {
                _vm.imgEditVisible = $event
              },
            },
          }),
          _vm._v(" "),
          _c("imageInimages", {
            ref: "imageInimagesRef",
            attrs: { show: _vm.imageInimagesVisible },
            on: {
              "update:show": function ($event) {
                _vm.imageInimagesVisible = $event
              },
            },
          }),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.editModalTitle,
                visible: _vm.editDialogVisible,
                width: "1000px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.editDialogVisible = $event
                },
              },
            },
            [
              _vm.editModalType != 6
                ? _c("span", { staticStyle: { color: "red" } }, [
                    _vm._v(
                      "\n        本功能对商品分类有要求，部分分类下的商品不支持修改！\n      "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.editModalType == 11
                ? _c(
                    "div",
                    { staticClass: "edit-box" },
                    [
                      _c("br"),
                      _vm._v(" "),
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("*"),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticStyle: { "margin-top": "20px" } }, [
                        _vm._v("是否二手："),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.selectedItem.secondHand,
                            callback: function ($$v) {
                              _vm.$set(_vm.selectedItem, "secondHand", $$v)
                            },
                            expression: "selectedItem.secondHand",
                          },
                        },
                        [_vm._v("二手")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.editModalType == 10
                ? _c(
                    "div",
                    { staticClass: "edit-box" },
                    [
                      _c("br"),
                      _vm._v(" "),
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("*"),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticStyle: { "margin-top": "20px" } }, [
                        _vm._v("单次限购量："),
                      ]),
                      _vm._v(" "),
                      _c("el-input", {
                        staticStyle: { width: "150px" },
                        attrs: { type: "number", size: "mini" },
                        model: {
                          value: _vm.selectedItem.orderLimit,
                          callback: function ($$v) {
                            _vm.$set(_vm.selectedItem, "orderLimit", $$v)
                          },
                          expression: "selectedItem.orderLimit",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.sameLimit,
                            callback: function ($$v) {
                              _vm.sameLimit = $$v
                            },
                            expression: "sameLimit",
                          },
                        },
                        [_vm._v("同时设置限购")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.editModalType == 12
                ? _c(
                    "div",
                    { staticClass: "edit-box" },
                    [
                      _c("br"),
                      _vm._v(" "),
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("*"),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticStyle: { "margin-top": "20px" } }, [
                        _vm._v("7天无理由退换货："),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.selectedItem.isRefundable,
                            callback: function ($$v) {
                              _vm.$set(_vm.selectedItem, "isRefundable", $$v)
                            },
                            expression: "selectedItem.isRefundable",
                          },
                        },
                        [_vm._v("是")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.editModalType == 14
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.AITititleLoading,
                          expression: "AITititleLoading",
                        },
                      ],
                      staticClass: "edit-box",
                    },
                    [
                      _c("br"),
                      _vm._v(" "),
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            data: _vm.titleArr,
                            "header-cell-style": {
                              background: "#eef1f6",
                              fontWeight: "700",
                            },
                            stripe: "",
                            height: "200px",
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: "图片",
                              align: "center",
                              width: "150",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("img", {
                                        staticStyle: {
                                          width: "55px",
                                          height: "50px",
                                        },
                                        attrs: { src: scope.row.src, alt: "" },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3879680298
                            ),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "原标题",
                              width: "180",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "white-space": "nowrap",
                                            overflow: "hidden",
                                            "text-overflow": "ellipsis",
                                          },
                                        },
                                        [_vm._v(_vm._s(scope.row.goodsName))]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3646604294
                            ),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { align: "center", label: "新标题" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-select",
                                        {
                                          ref: "seRef",
                                          staticStyle: { width: "260px" },
                                          attrs: {
                                            "show-limit": "",
                                            maxLength: "60",
                                            size: "mini",
                                            placeholder: "请选择",
                                          },
                                          on: {
                                            "visible-change":
                                              _vm.handleVisibleChange,
                                          },
                                          model: {
                                            value: _vm.newGoodsName,
                                            callback: function ($$v) {
                                              _vm.newGoodsName = $$v
                                            },
                                            expression: "newGoodsName",
                                          },
                                        },
                                        _vm._l(
                                          _vm.newGoodsNameList,
                                          function (item1) {
                                            return _c("el-option", {
                                              key: item1.value,
                                              attrs: {
                                                label: item1.label,
                                                value: item1.label,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { size: "mini" },
                                          on: { click: _vm.trrigleSelect },
                                        },
                                        [
                                          _vm._v(
                                            "备选" +
                                              _vm._s(
                                                _vm.newGoodsNameList.length
                                              )
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1825352803
                            ),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "操作",
                              width: "180",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text", size: "mini" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.changeTitleList(
                                                scope.row.goodsName
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("AI换标题")]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              829979497
                            ),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.editModalType == 9
                ? _c(
                    "div",
                    { staticClass: "edit-box" },
                    [
                      _c("br"),
                      _vm._v(" "),
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("*"),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticStyle: { "margin-top": "20px" } }, [
                        _vm._v("限购数量："),
                      ]),
                      _vm._v(" "),
                      _c("el-input", {
                        staticStyle: { width: "150px" },
                        attrs: { type: "number", size: "mini" },
                        model: {
                          value: _vm.selectedItem.buyLimit,
                          callback: function ($$v) {
                            _vm.$set(_vm.selectedItem, "buyLimit", $$v)
                          },
                          expression: "selectedItem.buyLimit",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.editModalType == 8
                ? _c(
                    "div",
                    { staticClass: "edit-box" },
                    [
                      _c("br"),
                      _vm._v(" "),
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("*"),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticStyle: { "margin-top": "20px" } }, [
                        _vm._v("假一赔十："),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.selectedItem.isFolt,
                            callback: function ($$v) {
                              _vm.$set(_vm.selectedItem, "isFolt", $$v)
                            },
                            expression: "selectedItem.isFolt",
                          },
                        },
                        [_vm._v("是")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.editModalType == 7
                ? _c(
                    "div",
                    { staticClass: "edit-box" },
                    [
                      _c("br"),
                      _vm._v(" "),
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("*"),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticStyle: { "margin-top": "20px" } }, [
                        _vm._v("运费模板："),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "190px" },
                          attrs: { placeholder: "请选择", size: "mini" },
                          model: {
                            value: _vm.selectedItem.costTemplateId,
                            callback: function ($$v) {
                              _vm.$set(_vm.selectedItem, "costTemplateId", $$v)
                            },
                            expression: "selectedItem.costTemplateId",
                          },
                        },
                        _vm._l(_vm.templatePostList, function (item) {
                          return _c("el-option", {
                            key: item.templateId,
                            attrs: {
                              label: item.templateName,
                              size: "mini",
                              value: item.templateId,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.editModalType == 6,
                      expression: "editModalType==6",
                    },
                  ],
                  staticClass: "edit-box",
                },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "1" },
                      model: {
                        value: _vm.releaseTime,
                        callback: function ($$v) {
                          _vm.releaseTime = $$v
                        },
                        expression: "releaseTime",
                      },
                    },
                    [_vm._v("当日发售")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "2" },
                      model: {
                        value: _vm.releaseTime,
                        callback: function ($$v) {
                          _vm.releaseTime = $$v
                        },
                        expression: "releaseTime",
                      },
                    },
                    [_vm._v("24小时")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "3" },
                      model: {
                        value: _vm.releaseTime,
                        callback: function ($$v) {
                          _vm.releaseTime = $$v
                        },
                        expression: "releaseTime",
                      },
                    },
                    [_vm._v("48小时")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.editModalType == 4
                ? _c("div", { staticClass: "edit-box" }, [
                    _c("br"),
                    _vm._v(" "),
                    _c("span", { staticStyle: { color: "red" } }, [
                      _vm._v("*"),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticStyle: { "margin-top": "20px" } }, [
                      _vm._v("满件折扣："),
                    ]),
                    _vm._v(" "),
                    _c(
                      "span",
                      [
                        _vm.selectedItem.twoPiecesDiscount != 0
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "inline-block",
                                  "vertical-align": "top",
                                  "margin-right": "40px",
                                },
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "150px" },
                                  attrs: { type: "number", size: "mini" },
                                  model: {
                                    value: _vm.selectedItem.twoPiecesDiscount,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.selectedItem,
                                        "twoPiecesDiscount",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "selectedItem.twoPiecesDiscount",
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "p",
                                  {
                                    staticStyle: {
                                      color: "red",
                                      "margin-top": "10px",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "折后拼单价：" +
                                        _vm._s(
                                          (
                                            (_vm.selectedItem.minGroupPrice *
                                              (_vm.selectedItem
                                                .twoPiecesDiscount /
                                                100) *
                                              100) /
                                            100
                                          ).toFixed(2)
                                        ) +
                                        " ~\n              " +
                                        _vm._s(
                                          (
                                            (_vm.selectedItem.maxGroupPrice *
                                              (_vm.selectedItem
                                                .twoPiecesDiscount /
                                                100) *
                                              100) /
                                            100
                                          ).toFixed(2)
                                        ) +
                                        "元"
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _c("span", { staticStyle: { color: "#f01" } }, [
                              _vm._v("暂无折扣"),
                            ]),
                        _vm._v(
                          "\n\n          注：商品满2件折扣与店铺满2件折扣不会叠加\n          "
                        ),
                        _vm.selectedItem.twoPiecesDiscount != 0
                          ? _c(
                              "el-button",
                              {
                                attrs: { size: "mini" },
                                on: {
                                  click: function ($event) {
                                    _vm.selectedItem.twoPiecesDiscount = 0
                                  },
                                },
                              },
                              [_vm._v("取消折扣")]
                            )
                          : _c(
                              "el-button",
                              {
                                attrs: { size: "mini" },
                                on: {
                                  click: function ($event) {
                                    _vm.selectedItem.twoPiecesDiscount = 1
                                  },
                                },
                              },
                              [_vm._v("添加折扣")]
                            ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.editModalType == 5
                ? _c(
                    "div",
                    { staticClass: "edit-box" },
                    [
                      _c("br"),
                      _vm._v(" "),
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("*"),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticStyle: { "margin-top": "20px" } }, [
                        _vm._v("是否预售："),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 1 },
                          model: {
                            value: _vm.PreSaleVal,
                            callback: function ($$v) {
                              _vm.PreSaleVal = $$v
                            },
                            expression: "PreSaleVal",
                          },
                        },
                        [_vm._v("非预售")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 2 },
                          model: {
                            value: _vm.PreSaleVal,
                            callback: function ($$v) {
                              _vm.PreSaleVal = $$v
                            },
                            expression: "PreSaleVal",
                          },
                        },
                        [_vm._v("定时预售")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 3 },
                          model: {
                            value: _vm.PreSaleVal,
                            callback: function ($$v) {
                              _vm.PreSaleVal = $$v
                            },
                            expression: "PreSaleVal",
                          },
                        },
                        [_vm._v("时段预售")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 4 },
                          model: {
                            value: _vm.PreSaleVal,
                            callback: function ($$v) {
                              _vm.PreSaleVal = $$v
                            },
                            expression: "PreSaleVal",
                          },
                        },
                        [_vm._v("规格预售")]
                      ),
                      _vm._v(" "),
                      _vm.PreSaleVal == 2 || _vm.PreSaleVal == 3
                        ? _c("p", { staticStyle: { "margin-top": "20px" } }, [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticStyle: { "margin-top": "20px" } },
                              [_vm._v("预售时间：")]
                            ),
                            _vm._v(" "),
                            _vm.PreSaleVal == 3
                              ? _c(
                                  "span",
                                  [
                                    _vm._v(
                                      "\n                   支付成功后\n            "
                                    ),
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "110px" },
                                        attrs: {
                                          placeholder: "请选择",
                                          size: "mini",
                                        },
                                        model: {
                                          value: _vm.timeSale,
                                          callback: function ($$v) {
                                            _vm.timeSale = $$v
                                          },
                                          expression: "timeSale",
                                        },
                                      },
                                      _vm._l(15, function (item, i) {
                                        return _c("el-option", {
                                          attrs: {
                                            label: item + "天",
                                            size: "mini",
                                            value: item,
                                          },
                                        })
                                      }),
                                      1
                                    ),
                                    _vm._v(
                                      "\n            内发货\n            "
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.PreSaleVal == 2
                              ? _c(
                                  "span",
                                  [
                                    _c("el-date-picker", {
                                      attrs: {
                                        type: "date",
                                        placeholder: "选择日期",
                                        "picker-options": _vm.pickerOptions,
                                        "value-format": "timestamp",
                                      },
                                      on: { change: _vm.handleChange },
                                      model: {
                                        value: _vm.time,
                                        callback: function ($$v) {
                                          _vm.time = $$v
                                        },
                                        expression: "time",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _vm.isShow
                                      ? _c("span", [
                                          _vm._v(
                                            "需在" +
                                              _vm._s(_vm.showPreSaleTime) +
                                              "前完成发货"
                                          ),
                                        ])
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ])
                        : _vm._e(),
                      _vm.PreSaleVal == 4
                        ? _c("div", [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  border: "1px solid #eee",
                                  padding: "15px 25px",
                                  "margin-top": "20px",
                                },
                              },
                              [
                                _c("div", [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "border-bottom": "1px solid #eee",
                                        "padding-bottom": "20px",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "row",
                                          staticStyle: { display: "flex" },
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticStyle: { flex: "1" } },
                                            [_vm._v("SKU名称(规格值)")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticStyle: { flex: "1" } },
                                            [_vm._v("预售时间")]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm._l(
                                        _vm.selectedItem.skuList,
                                        function (item) {
                                          return _c(
                                            "div",
                                            {
                                              staticClass: "row",
                                              staticStyle: {
                                                display: "flex",
                                                "justify-content":
                                                  "space-between",
                                                "margin-top": "20px",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: { width: "30%" },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.spec[0].specName
                                                    ) + "\n                    "
                                                  ),
                                                  item.spec.length > 1
                                                    ? _c("span", [
                                                        _vm._v(
                                                          "/" +
                                                            _vm._s(
                                                              item.spec[1]
                                                                .specName
                                                            )
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: { width: "51%" },
                                                },
                                                [
                                                  _c("el-date-picker", {
                                                    attrs: {
                                                      type: "date",
                                                      placeholder: "选择日期",
                                                      "picker-options":
                                                        _vm.pickerOptions,
                                                      "value-format":
                                                        "timestamp",
                                                    },
                                                    model: {
                                                      value:
                                                        item.skuPreSaleTime,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "skuPreSaleTime",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "item.skuPreSaleTime",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ]),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("p"),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.editModalType == 24
                ? _c(
                    "div",
                    {
                      staticClass: "edit-box",
                      staticStyle: { "margin-top": "20px", display: "flex" },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            width: "130px",
                            display: "inline-block",
                          },
                        },
                        [
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v("*"),
                          ]),
                          _vm._v("商品主图："),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            border: "1px solid #eee",
                            padding: "5px",
                          },
                        },
                        [
                          _c(
                            "p",
                            { staticStyle: { "margin-bottom": "10px" } },
                            [
                              _vm._v(
                                "\n              (1. 宽高比例为1:1或3:4； 2. 宽高须超过480px，最多上传10张)\n            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("imgHandleCom", {
                            ref: "imgHandleCom",
                            attrs: {
                              skulist: _vm.selectedItem.carouselGalleryList,
                              mallId: _vm.mallId,
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.editModalType == 1
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.skuLoading,
                          expression: "skuLoading",
                        },
                      ],
                      staticClass: "edit-box",
                      attrs: { "element-loading-text": "正在生成SKU文字..." },
                    },
                    [
                      _c("br"),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            border: "1px solid #eee",
                            padding: "15px 25px",
                          },
                        },
                        [
                          _vm._l(_vm.specAndIdList, function (item, index) {
                            return _c("div", { staticClass: "span-box" }, [
                              _c(
                                "p",
                                {
                                  staticStyle: {
                                    "margin-bottom": "20px",
                                    display: "flex",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(Object.keys(item)[0]) +
                                      "\n                "
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        width: "80%",
                                        "text-align": "right",
                                        display: "inline-block",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            border: "1px solid #2978ff",
                                            "border-radius": "4px",
                                            color: "#2978ff",
                                            cursor: "pointer",
                                            padding: "2px 5px",
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.AIChangeSkuAll(
                                                item,
                                                item[Object.keys(item)[0]],
                                                index
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("一键修改sku文字")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            border: "1px solid #2978ff",
                                            "border-radius": "4px",
                                            color: "#2978ff",
                                            "margin-left": "20px",
                                            cursor: "pointer",
                                            padding: "2px 5px",
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.skuReStore(
                                                item[Object.keys(item)[0]]
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("一键还原")]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "flex-wrap": "wrap",
                                  },
                                },
                                [
                                  _c(
                                    "draggable",
                                    {
                                      on: {
                                        start: function ($event) {
                                          _vm.drag = true
                                        },
                                        end: _vm.onDragSPUEnd,
                                      },
                                      model: {
                                        value: item[Object.keys(item)[0]],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            item,
                                            Object.keys(item)[0],
                                            $$v
                                          )
                                        },
                                        expression:
                                          "item[Object.keys(item)[0]]",
                                      },
                                    },
                                    _vm._l(
                                      item[Object.keys(item)[0]],
                                      function (_i, index) {
                                        return _c("el-input", {
                                          staticStyle: {
                                            width: "380px",
                                            "margin-right": "10px",
                                            "margin-bottom": "20px",
                                          },
                                          attrs: {
                                            clearable: "",
                                            maxlength: "30",
                                            "show-word-limit": "",
                                            size: "mini",
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.changeInputSkuTil(
                                                $event,
                                                _i.id
                                              )
                                            },
                                          },
                                          model: {
                                            value: _i.value,
                                            callback: function ($$v) {
                                              _vm.$set(_i, "value", $$v)
                                            },
                                            expression: "_i.value",
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ])
                          }),
                          _vm._v(" "),
                          _c(
                            "el-table",
                            {
                              staticStyle: { "margin-top": "20px" },
                              attrs: {
                                data: _vm.selectedItem.skuList,
                                border: "",
                                "header-cell-style": {
                                  background: "#eef1f6",
                                  fontWeight: "700",
                                },
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  label:
                                    _vm.selectedItem.skuList[0].spec[0]
                                      .parentName,
                                  align: "center",
                                  width: "200",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                display: "inline-block",
                                                width: "180px",
                                                "white-space": "wrap",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  scope.row.spec[0].specName
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1804757824
                                ),
                              }),
                              _vm._v(" "),
                              _vm.selectedItem.skuList[0].spec[1]
                                ? _c("el-table-column", {
                                    attrs: {
                                      label:
                                        _vm.selectedItem.skuList[0].spec[1]
                                          .parentName,
                                      align: "center",
                                      width: "200",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    display: "inline-block",
                                                    width: "180px",
                                                    "white-space": "wrap",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      scope.row.spec[1].specName
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3121715201
                                    ),
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "库存",
                                  align: "center",
                                  width: "150",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("el-input", {
                                            attrs: { size: "mini" },
                                            on: {
                                              change: function ($event) {
                                                return _vm.ediQuatity(
                                                  $event,
                                                  scope.row
                                                )
                                              },
                                            },
                                            model: {
                                              value: scope.row.quantity,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "quantity",
                                                  $$v
                                                )
                                              },
                                              expression: "scope.row.quantity",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  73599170
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "thumbUrl",
                                  label: "预览图",
                                  align: "center",
                                  width: "160",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("img", {
                                            staticStyle: {
                                              width: "70px",
                                              height: "70px",
                                            },
                                            attrs: { src: scope.row.thumbUrl },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "upLoad-img" },
                                            [
                                              _c("div", [_vm._v("点击上传")]),
                                              _vm._v(" "),
                                              _c("input", {
                                                attrs: { type: "file" },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.changeImg(
                                                      $event,
                                                      scope.row
                                                    )
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3371105753
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  label: "上架状态",
                                  width: "160",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("el-switch", {
                                            attrs: {
                                              "active-color": "#67C23A",
                                              "inactive-color": "#ddd",
                                              "active-value": 1,
                                              "inactive-value": 0,
                                            },
                                            model: {
                                              value: scope.row.isOnsale,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "isOnsale",
                                                  $$v
                                                )
                                              },
                                              expression: "scope.row.isOnsale",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1302675766
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.editModalType == 2
                ? _c("div", { staticClass: "edit-box" }, [
                    _c("br"),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          border: "1px solid #eee",
                          padding: "15px 25px",
                        },
                      },
                      [
                        _c(
                          "div",
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "border-bottom": "1px solid #eee",
                                  "padding-bottom": "20px",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "row",
                                    staticStyle: { display: "flex" },
                                  },
                                  [
                                    _c("span", { staticStyle: { flex: "1" } }, [
                                      _vm._v("SKU名称(规格值)"),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", { staticStyle: { flex: "1" } }, [
                                      _vm._v("单买价"),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", { staticStyle: { flex: "1" } }, [
                                      _vm._v("拼单价"),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm._l(
                                  _vm.selectedItem.skuList,
                                  function (item) {
                                    return _c(
                                      "div",
                                      {
                                        staticClass: "row",
                                        staticStyle: {
                                          display: "flex",
                                          "justify-content": "space-between",
                                          "margin-top": "20px",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticStyle: { width: "30%" } },
                                          [
                                            _vm._v(
                                              _vm._s(item.spec[0].specName) +
                                                "\n                      "
                                            ),
                                            item.spec.length > 1
                                              ? _c("span", [
                                                  _vm._v(
                                                    "/" +
                                                      _vm._s(
                                                        item.spec[1].specName
                                                      )
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("el-input", {
                                          staticStyle: { width: "32%" },
                                          attrs: { size: "mini" },
                                          model: {
                                            value: item.price,
                                            callback: function ($$v) {
                                              _vm.$set(item, "price", $$v)
                                            },
                                            expression: "item.price",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-input", {
                                          staticStyle: { width: "32%" },
                                          attrs: { size: "mini" },
                                          model: {
                                            value: item.multiPrice,
                                            callback: function ($$v) {
                                              _vm.$set(item, "multiPrice", $$v)
                                            },
                                            expression: "item.multiPrice",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  }
                                ),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c("span", { staticStyle: { color: "#ff8a00" } }, [
                              _vm._v("参考价"),
                            ]),
                            _vm._v(" "),
                            _c("el-input", {
                              staticStyle: {
                                width: "280px",
                                "margin-left": "30px",
                                "margin-top": "20px",
                              },
                              model: {
                                value: _vm.selectedItem.marketPrice,
                                callback: function ($$v) {
                                  _vm.$set(_vm.selectedItem, "marketPrice", $$v)
                                },
                                expression: "selectedItem.marketPrice",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.editModalType == 3
                ? _c("div", { staticClass: "edit-box" }, [
                    _c("br"),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          border: "1px solid #eee",
                          padding: "15px 25px",
                        },
                      },
                      [
                        _c(
                          "div",
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "border-bottom": "1px solid #eee",
                                  "padding-bottom": "20px",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "row",
                                    staticStyle: { display: "flex" },
                                  },
                                  [
                                    _c("span", { staticStyle: { flex: "1" } }, [
                                      _vm._v("SKU名称(规格值)"),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", { staticStyle: { flex: "1" } }, [
                                      _vm._v("SKU编码"),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm._l(
                                  _vm.selectedItem.skuList,
                                  function (item) {
                                    return _c(
                                      "div",
                                      {
                                        staticClass: "row",
                                        staticStyle: {
                                          display: "flex",
                                          "justify-content": "space-between",
                                          "margin-top": "20px",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticStyle: { width: "30%" } },
                                          [
                                            _vm._v(
                                              _vm._s(item.spec[0].specName) +
                                                "\n                    "
                                            ),
                                            item.spec.length > 1
                                              ? _c("span", [
                                                  _vm._v(
                                                    "/" +
                                                      _vm._s(
                                                        item.spec[1].specName
                                                      )
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("el-input", {
                                          staticStyle: { width: "51%" },
                                          attrs: { size: "mini" },
                                          model: {
                                            value: item.outSkuSn,
                                            callback: function ($$v) {
                                              _vm.$set(item, "outSkuSn", $$v)
                                            },
                                            expression: "item.outSkuSn",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  }
                                ),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c("span", [_vm._v("商品编码")]),
                            _vm._v(" "),
                            _c("el-input", {
                              staticStyle: {
                                width: "280px",
                                "margin-left": "30px",
                                "margin-top": "20px",
                              },
                              model: {
                                value: _vm.selectedItem.outerGoodsId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.selectedItem,
                                    "outerGoodsId",
                                    $$v
                                  )
                                },
                                expression: "selectedItem.outerGoodsId",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.editModalType == 25
                ? _c(
                    "div",
                    { staticClass: "edit-box" },
                    [
                      _c("br"),
                      _vm._v(" "),
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("*"),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticStyle: { "margin-top": "20px" } }, [
                        _vm._v("商品标题："),
                      ]),
                      _vm._v(" "),
                      _c("el-input", {
                        staticStyle: { width: "650px" },
                        attrs: { size: "mini" },
                        on: { input: _vm.changeTitle },
                        model: {
                          value: _vm.selectedItem.goodsName,
                          callback: function ($$v) {
                            _vm.$set(_vm.selectedItem, "goodsName", $$v)
                          },
                          expression: "selectedItem.goodsName",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "txtNum" }, [
                        _vm._v(_vm._s(_vm.goodsNameLength) + "/60"),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.editModalType == 26
                ? _c(
                    "div",
                    {
                      staticClass: "edit-box",
                      staticStyle: { display: "flex" },
                    },
                    [
                      _c("br"),
                      _vm._v(" "),
                      _c("span", [
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v("*"),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticStyle: { "margin-top": "20px" } }, [
                          _vm._v("商品属性："),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticStyle: { flex: "1" } }, [
                        _c(
                          "div",
                          {
                            staticClass: "options-box",
                            staticStyle: {
                              display: "flex",
                              "flex-wrap": "wrap",
                            },
                          },
                          _vm._l(_vm.selectedItem.catList, function (item) {
                            return _c(
                              "span",
                              {
                                staticStyle: {
                                  "margin-bottom": "10px",
                                  "margin-right": "10px",
                                },
                              },
                              [
                                _c(
                                  "p",
                                  { staticStyle: { "margin-bottom": "10px" } },
                                  [_vm._v(_vm._s(item.name))]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-select",
                                  {
                                    attrs: { placeholder: "请选择" },
                                    on: {
                                      change: function ($event) {
                                        return _vm.catListSelectedChange(
                                          $event,
                                          item
                                        )
                                      },
                                    },
                                    model: {
                                      value: item.catListSelected,
                                      callback: function ($$v) {
                                        _vm.$set(item, "catListSelected", $$v)
                                      },
                                      expression: "item.catListSelected",
                                    },
                                  },
                                  _vm._l(item.values, function (v) {
                                    return _c("el-option", {
                                      key: v.vid,
                                      attrs: { label: v.value, value: v.vid },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            )
                          }),
                          0
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.editModalType == 28
                ? _c(
                    "div",
                    {
                      staticClass: "edit-box",
                      staticStyle: { display: "flex" },
                    },
                    [
                      _c("br"),
                      _vm._v(" "),
                      _c("p", [
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v("*"),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticStyle: { "margin-top": "20px" } }, [
                          _vm._v("商品详情图："),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "detail-img-box" }, [
                        _c(
                          "div",
                          { staticClass: "detail-left" },
                          [
                            _c(
                              "p",
                              { staticStyle: { "margin-bottom": "10px" } },
                              [_vm._v("页面预览总高度472px")]
                            ),
                            _vm._v(" "),
                            _vm._l(
                              _vm.selectedItem.detailGalleryList,
                              function (item) {
                                return _c("img", {
                                  staticClass: "img-l",
                                  attrs: { src: item, alt: "" },
                                })
                              }
                            ),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "detail-item-right" },
                          [
                            _c(
                              "span",
                              { staticStyle: { "margin-bottom": "10px" } },
                              [
                                _vm._v(
                                  "(图片宽度应为480-1200px，高度应为1500px以内,最多上传50张)"
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("imgHandleCom", {
                              ref: "imgHandleCom",
                              attrs: {
                                aiShow: false,
                                skulist: _vm.selectedItem.detailGalleryList,
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.editModalType == 18
                ? _c("div", { staticClass: "edit-box" }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "space-between",
                        },
                      },
                      [
                        _c(
                          "el-form",
                          {
                            ref: "formWater",
                            attrs: {
                              model: _vm.formWater,
                              "label-width": "100px",
                            },
                          },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "水印文字：" } },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "200px" },
                                  attrs: {
                                    placeholder: "请输入水印文字",
                                    size: "mini",
                                  },
                                  on: { input: _vm.handleTxtChange },
                                  model: {
                                    value: _vm.formWater.watermarkTxt,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formWater,
                                        "watermarkTxt",
                                        $$v
                                      )
                                    },
                                    expression: "formWater.watermarkTxt",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "文字大小：" } },
                              [
                                _c("el-slider", {
                                  staticStyle: { width: "130px" },
                                  attrs: { min: 1, max: 300, step: 1 },
                                  on: { input: _vm.handleTextSize },
                                  model: {
                                    value: _vm.formWater.textSize,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formWater, "textSize", $$v)
                                    },
                                    expression: "formWater.textSize",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "水印透明度：" } },
                              [
                                _c("el-slider", {
                                  staticStyle: { width: "130px" },
                                  attrs: { min: 1, max: 100, step: 1 },
                                  on: { input: _vm.handleLucency },
                                  model: {
                                    value: _vm.formWater.markLucency,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formWater,
                                        "markLucency",
                                        $$v
                                      )
                                    },
                                    expression: "formWater.markLucency",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "水印位置：" } },
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    on: { input: _vm.handleLocation },
                                    model: {
                                      value: _vm.formWater.markLocation,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formWater,
                                          "markLocation",
                                          $$v
                                        )
                                      },
                                      expression: "formWater.markLocation",
                                    },
                                  },
                                  [
                                    _c("el-radio", { attrs: { label: "1" } }, [
                                      _vm._v("满屏"),
                                    ]),
                                    _vm._v(" "),
                                    _c("el-radio", { attrs: { label: "2" } }, [
                                      _vm._v("居中"),
                                    ]),
                                    _vm._v(" "),
                                    _c("el-radio", { attrs: { label: "3" } }, [
                                      _vm._v("左上"),
                                    ]),
                                    _vm._v(" "),
                                    _c("el-radio", { attrs: { label: "4" } }, [
                                      _vm._v("右上"),
                                    ]),
                                    _vm._v(" "),
                                    _c("el-radio", { attrs: { label: "5" } }, [
                                      _vm._v("左下"),
                                    ]),
                                    _vm._v(" "),
                                    _c("el-radio", { attrs: { label: "6" } }, [
                                      _vm._v("右下"),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "水印图片：" } },
                              [
                                _c(
                                  "el-checkbox-group",
                                  {
                                    model: {
                                      value: _vm.formWater.markList,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.formWater, "markList", $$v)
                                      },
                                      expression: "formWater.markList",
                                    },
                                  },
                                  [
                                    _c("el-checkbox", {
                                      attrs: { label: "首张主图" },
                                    }),
                                    _vm._v(" "),
                                    _c("el-checkbox", {
                                      attrs: { label: "所有主图" },
                                    }),
                                    _vm._v(" "),
                                    _c("el-checkbox", {
                                      attrs: { label: "SKU图" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "previewImg" }, [
                          _vm.formWater.markLocation == "1"
                            ? _c("div", { ref: "preTxt" }, [
                                _c("img", {
                                  staticStyle: {
                                    width: "200px",
                                    height: "200px",
                                  },
                                  attrs: { src: _vm.previewImageUrl, alt: "" },
                                }),
                              ])
                            : _c("div", [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/imgs/moren.jpg"),
                                    alt: "",
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { ref: "preTxt", staticClass: "previewTxt" },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.formWater.watermarkTxt)
                                      ),
                                    ]),
                                  ]
                                ),
                              ]),
                        ]),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.editModalType == 19
                ? _c("div", { staticClass: "edit-box" }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "space-between",
                        },
                      },
                      [
                        _c(
                          "el-form",
                          { attrs: { "label-width": "100px" } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "边框样式：" } },
                              [
                                _c(
                                  "div",
                                  { staticStyle: { display: "flex" } },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { size: "mini" },
                                        on: { click: _vm.handleBorderOpen },
                                      },
                                      [_vm._v("边框库")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: { "margin-left": "15px" },
                                      },
                                      [
                                        _c("span", [_vm._v("已选")]),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticStyle: { color: "#1890ff" } },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.borderImgList.length)
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("span", [_vm._v("个")]),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "margin-left": "10px",
                                              color: "#1890ff",
                                              cursor: "pointer",
                                            },
                                            on: {
                                              click: function ($event) {
                                                _vm.borderSelectOpen = true
                                              },
                                            },
                                          },
                                          [_vm._v("查看")]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "边框图片：" } },
                              [
                                _c(
                                  "el-checkbox-group",
                                  {
                                    model: {
                                      value: _vm.borderLoc,
                                      callback: function ($$v) {
                                        _vm.borderLoc = $$v
                                      },
                                      expression: "borderLoc",
                                    },
                                  },
                                  [
                                    _c("el-checkbox", {
                                      attrs: { label: "首张主图" },
                                    }),
                                    _vm._v(" "),
                                    _c("el-checkbox", {
                                      attrs: { label: "所有主图" },
                                    }),
                                    _vm._v(" "),
                                    _c("el-checkbox", {
                                      attrs: { label: "SKU图" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "previewImg" }, [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/imgs/moren.jpg"),
                              alt: "",
                            },
                          }),
                          _vm._v(" "),
                          _vm.randomBorderImg
                            ? _c("img", {
                                staticClass: "preImg",
                                attrs: { src: _vm.randomBorderImg, alt: "" },
                              })
                            : _vm._e(),
                        ]),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.editModalType == 18 || _vm.editModalType == 19
                ? _c(
                    "span",
                    {
                      staticClass: "dialog-footer",
                      attrs: { slot: "footer" },
                      slot: "footer",
                    },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              _vm.editDialogVisible = false
                            },
                          },
                        },
                        [_vm._v("关 闭")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.handlePreviewChange },
                        },
                        [_vm._v("预 览")]
                      ),
                    ],
                    1
                  )
                : _c(
                    "span",
                    {
                      staticClass: "dialog-footer",
                      attrs: { slot: "footer" },
                      slot: "footer",
                    },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              _vm.editDialogVisible = false
                            },
                          },
                        },
                        [_vm._v("关 闭")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.submitHandle },
                        },
                        [_vm._v("提 交")]
                      ),
                    ],
                    1
                  ),
            ]
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "预览", visible: _vm.previewOpen, width: "1000px" },
          on: {
            "update:visible": function ($event) {
              _vm.previewOpen = $event
            },
          },
        },
        [
          _c(
            "el-tabs",
            {
              model: {
                value: _vm.activePreviewImg,
                callback: function ($$v) {
                  _vm.activePreviewImg = $$v
                },
                expression: "activePreviewImg",
              },
            },
            [
              _c("el-tab-pane", {
                attrs: { label: "主图预览", name: "first" },
              }),
              _vm._v(" "),
              _c("el-tab-pane", {
                attrs: { label: "SKU图预览", name: "second" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.activePreviewImg == "first"
            ? _c(
                "div",
                { staticStyle: { border: "1px solid #eee", padding: "5px" } },
                [
                  _c("p", { staticStyle: { "margin-bottom": "10px" } }, [
                    _vm._v(
                      "\n          (1. 宽高比例为1:1或3:4； 2. 宽高须超过480px，最多上传10张)\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("imgHandleCom", {
                    ref: "imgHandleCom",
                    attrs: {
                      skulist: _vm.selectedItem.carouselGalleryList,
                      mallId: _vm.mallId,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.activePreviewImg == "second"
            ? _c(
                "el-table",
                {
                  staticStyle: { "margin-top": "20px" },
                  attrs: {
                    data: _vm.selectedItem.skuList,
                    border: "",
                    height: "500",
                    "header-cell-style": {
                      background: "#eef1f6",
                      fontWeight: "700",
                    },
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "序号",
                      type: "index",
                      align: "center",
                      width: "50",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "SKU规格", align: "center" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    display: "inline-block",
                                    width: "180px",
                                    "white-space": "wrap",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(scope.row.spec[0].specName) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1297019872
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "thumbUrl",
                      label: "预览图",
                      align: "center",
                      width: "160",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-tooltip",
                                { attrs: { placement: "left" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      attrs: { slot: "content" },
                                      slot: "content",
                                    },
                                    [
                                      _c("img", {
                                        staticStyle: { width: "300px" },
                                        attrs: { src: scope.row.thumbUrl },
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("img", {
                                    staticStyle: {
                                      width: "70px",
                                      height: "70px",
                                    },
                                    attrs: { src: scope.row.thumbUrl },
                                  }),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1918580588
                    ),
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.previewOpen = false
                    },
                  },
                },
                [_vm._v("关 闭")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loadingSubmit },
                  on: { click: _vm.submitHandle },
                },
                [_vm._v("提 交")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "边框库", visible: _vm.borderOpen, width: "1000px" },
          on: {
            "update:visible": function ($event) {
              _vm.borderOpen = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { padding: "10px", background: "#e6f7ff" } },
            [
              _vm._v(
                "\n        说明：可多选边框，多选的边框会随机应用到裂变商品中。一个商品只能有一种边框效果。\n      "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-row",
            {
              staticStyle: {
                "margin-top": "20px",
                height: "500px",
                "overflow-y": "auto",
              },
              attrs: { gutter: 20 },
            },
            _vm._l(_vm.localImages, function (item, index) {
              return _c(
                "el-col",
                {
                  key: index,
                  staticStyle: { "margin-bottom": "20px" },
                  attrs: { span: 6 },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: { height: "200px", position: "relative" },
                      on: {
                        click: function ($event) {
                          return _vm.handleSelectBorder(item)
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticStyle: { height: "100%" },
                        attrs: { src: item.imgUrl, alt: "" },
                      }),
                      _vm._v(" "),
                      item.flag
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                height: "20px",
                                width: "20px",
                                background: "#409EFF",
                                "text-align": "center",
                                "line-height": "20px",
                                position: "absolute",
                                top: "0",
                                right: "25px",
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-check",
                                staticStyle: { color: "#fff" },
                              }),
                            ]
                          )
                        : _vm._e(),
                    ]
                  ),
                ]
              )
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleBorderCancel } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleBorderConfirm },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "已选择边框",
            visible: _vm.borderSelectOpen,
            width: "1000px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.borderSelectOpen = $event
            },
          },
        },
        [
          _c(
            "el-row",
            {
              staticStyle: {
                "margin-top": "20px",
                height: "500px",
                "overflow-y": "auto",
              },
              attrs: { gutter: 20 },
            },
            _vm._l(_vm.borderImgList, function (item, index) {
              return _c(
                "el-col",
                {
                  key: index,
                  staticStyle: { "margin-bottom": "20px" },
                  attrs: { span: 6 },
                },
                [
                  _c(
                    "div",
                    { staticStyle: { height: "200px", position: "relative" } },
                    [
                      _c("img", {
                        staticStyle: { height: "100%" },
                        attrs: { src: item, alt: "" },
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            height: "20px",
                            width: "20px",
                            "border-radius": "50%",
                            background: "#F56C6C",
                            "text-align": "center",
                            "line-height": "20px",
                            position: "absolute",
                            top: "0",
                            right: "25px",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.handleDeleteBorder(index)
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "el-icon-close",
                            staticStyle: { color: "#fff", cursor: "pointer" },
                          }),
                        ]
                      ),
                    ]
                  ),
                ]
              )
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.borderSelectOpen = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleBorderConfirm },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "充值",
            visible: _vm.openPay,
            width: "1248px",
            "custom-class": "pay-dialog",
          },
          on: {
            "update:visible": function ($event) {
              _vm.openPay = $event
            },
          },
        },
        [
          _c("payment", {
            ref: "payments",
            attrs: {
              shopData:
                _vm.$store.state.user.app_isClient == 0
                  ? _vm.$store.state.user.userInfo
                  : _vm.shopData,
            },
            on: {
              close: function ($event) {
                _vm.openPay = false
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("taskLogDialog1", {
        ref: "taskDetailListDataRef",
        attrs: {
          data: _vm.taskDetailListData,
          taskId: _vm.taskId,
          show: _vm.taskLogVisible,
        },
        on: {
          "update:show": function ($event) {
            _vm.taskLogVisible = $event
          },
        },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "AI换商品主图",
            visible: _vm.AIPicVisible,
            width: "1500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.AIPicVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.AIpicLoading,
                  expression: "AIpicLoading",
                },
              ],
              staticClass: "pic-box-c",
              staticStyle: { display: "flex" },
              attrs: {
                "element-loading-text": "拼命加载中",
                "element-loading-spinner": "el-icon-loading",
                "element-loading-background": "rgba(0, 0, 0, 0.4)",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "le-pic",
                  staticStyle: {
                    width: "300px",
                    "border-right": "1px solid #eee",
                    "overflow-y": "scroll",
                  },
                },
                [
                  _c("p", [_vm._v("已选图片（1/8）")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        overflow: "auto",
                        height: "600px",
                        "margin-top": "5px",
                      },
                    },
                    _vm._l(
                      _vm.selectedItem.carouselGalleryList,
                      function (item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticStyle: {
                              display: "flex",
                              "align-items": "center",
                              "margin-bottom": "10px",
                            },
                          },
                          [
                            _c("img", {
                              staticStyle: { width: "100px", height: "100px" },
                              style: {
                                border:
                                  _vm.sIndex == index
                                    ? "1px solid #3da2ff"
                                    : "none",
                              },
                              attrs: { src: item, alt: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeAIPic(item, index)
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("img", {
                              staticStyle: {
                                width: "20px",
                                height: "20px",
                                margin: "0 10px",
                              },
                              attrs: {
                                src: require("../../../assets/imgs/aI2.png"),
                                alt: "",
                                srcset: "",
                              },
                            }),
                            _vm._v(" "),
                            _vm.picAPiSelectedList[index]
                              ? _c("img", {
                                  staticStyle: {
                                    width: "100px",
                                    height: "100px",
                                  },
                                  attrs: {
                                    src: _vm.picAPiSelectedList[index],
                                    alt: "AI图片",
                                  },
                                })
                              : _c("p", {
                                  staticStyle: {
                                    width: "100px",
                                    height: "100px",
                                    border: "1px dashed #ddd",
                                  },
                                }),
                          ]
                        )
                      }
                    ),
                    0
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "ri-pic",
                  staticStyle: { padding: "0 20px", flex: "1" },
                },
                [
                  _c(
                    "p",
                    {
                      staticStyle: {
                        "margin-bottom": "10px",
                        "font-size": "15px",
                      },
                    },
                    [_vm._v("图片列表")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      ref: "onScrollAIRef",
                      staticStyle: {
                        display: "flex",
                        "flex-wrap": "wrap",
                        gap: "20px",
                        height: "600px",
                        overflow: "scroll",
                      },
                      on: { scroll: _vm.onScrollAI },
                    },
                    _vm._l(_vm.picAPiList, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: item.url,
                          staticClass: "pic-items1",
                          staticStyle: {
                            width: "200px",
                            height: "200px",
                            border: "1px solid #eee",
                            padding: "2px",
                            "border-radius": "5px",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.changeAiS(item)
                            },
                          },
                        },
                        [
                          _c("img", {
                            staticStyle: {
                              "border-radius": "5px",
                              width: "100%",
                              height: "100%",
                            },
                            attrs: { src: item.imgUrl, alt: "" },
                          }),
                        ]
                      )
                    }),
                    0
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              staticStyle: {
                display: "flex",
                "align-items": "center",
                "justify-content": "space-between",
              },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("div", {
                staticStyle: {
                  padding: "0 15px",
                  "border-radius": "14px",
                  background: "#fff2e7",
                  "font-size": "15px",
                  "font-weight": "500",
                },
              }),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitHandle } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
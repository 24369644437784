var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: { title: "批量改规格(SKU)", visible: true },
      on: { close: _vm.dialogClose },
    },
    [
      _c("div", { staticClass: "content", attrs: { id: "" } }, [
        _c("div", { staticClass: "content1" }, [
          _c(
            "div",
            { staticStyle: { "font-weight": "700", "margin-bottom": "20px" } },
            [_vm._v("已选商品数" + _vm._s(_vm.list.length))]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "edit-item" },
            [
              _c("div", { staticClass: "edit-item-title" }, [
                _vm._v("统一规格值文字替换 "),
              ]),
              _vm._v(" "),
              _c("el-input", {
                staticStyle: { width: "90px" },
                attrs: { size: "mini", placeholder: "请输入" },
                model: {
                  value: _vm.form.input1,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "input1", $$v)
                  },
                  expression: "form.input1",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("为")]),
              _vm._v(" "),
              _c("el-input", {
                staticStyle: { width: "90px" },
                attrs: { size: "mini", placeholder: "请输入" },
                model: {
                  value: _vm.form.input2,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "input2", $$v)
                  },
                  expression: "form.input2",
                },
              }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleClick(1)
                    },
                  },
                },
                [_vm._v("执行")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "edit-item" },
            [
              _c("div", { staticClass: "edit-item-title" }, [
                _vm._v("统一规格值文字清除 "),
              ]),
              _vm._v(" "),
              _c("el-input", {
                staticStyle: { width: "150px" },
                attrs: { size: "mini", placeholder: "请输入" },
                model: {
                  value: _vm.form.input3,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "input3", $$v)
                  },
                  expression: "form.input3",
                },
              }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleClick(2)
                    },
                  },
                },
                [_vm._v("执行")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "edit-item" },
            [
              _c("div", { staticClass: "edit-item-title" }, [
                _vm._v("开头加 "),
              ]),
              _vm._v(" "),
              _c("el-input", {
                staticStyle: { width: "150px" },
                attrs: { size: "mini", placeholder: "请输入" },
                model: {
                  value: _vm.form.input4,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "input4", $$v)
                  },
                  expression: "form.input4",
                },
              }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleClick(3)
                    },
                  },
                },
                [_vm._v("执行")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "edit-item" },
            [
              _c("div", { staticClass: "edit-item-title" }, [
                _vm._v("结尾加 "),
              ]),
              _vm._v(" "),
              _c("el-input", {
                staticStyle: { width: "150px" },
                attrs: { size: "mini", placeholder: "请输入" },
                model: {
                  value: _vm.form.input5,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "input5", $$v)
                  },
                  expression: "form.input5",
                },
              }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleClick(4)
                    },
                  },
                },
                [_vm._v("执行")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "edit-item" },
            [
              _c("div", { staticClass: "edit-item-title" }, [
                _vm._v("批量设置规格值包含 "),
              ]),
              _vm._v(" "),
              _c("el-input", {
                staticStyle: { width: "150px" },
                attrs: { size: "mini", placeholder: "请输入" },
                model: {
                  value: _vm.form.input6,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "input6", $$v)
                  },
                  expression: "form.input6",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("的SKU图")]),
              _vm._v(" "),
              _c("label", { staticClass: "custom-upload" }, [
                _c("span", [_vm._v("上传图并执行")]),
                _vm._v(" "),
                _c("input", {
                  ref: "fileInput",
                  attrs: { type: "file", hidden: "" },
                  on: { change: _vm.changeAllImg },
                }),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "edit-item" },
            [
              _c("span", { staticStyle: { "margin-right": "5px" } }, [
                _vm._v(" AI批量修改商品标题"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.changeAllTitle(_vm.list)
                    },
                  },
                },
                [_vm._v("执行")]
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "dialog-content" }, [
          _c(
            "div",
            {
              staticClass: "info-title",
              staticStyle: { "font-weight": "700" },
            },
            [_vm._v("商品详细信息")]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "goods-info" },
            _vm._l(_vm.list, function (item, index) {
              return _c("div", { key: index, staticClass: "goods-info-item" }, [
                _c(
                  "div",
                  {
                    staticClass: "guige",
                    staticStyle: { "align-self": "flex-start" },
                  },
                  [_vm._v(" 商品规格：")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "goods-info-item-head" },
                  [
                    _c("div", { staticClass: "item-goods-info" }, [
                      _c("div", { staticClass: "goods-name" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: item.goodsName,
                              expression: "item.goodsName",
                            },
                          ],
                          staticClass: "biaoti",
                          attrs: { type: "text" },
                          domProps: { value: item.goodsName },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(item, "goodsName", $event.target.value)
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticStyle: { color: "#737373" } }, [
                          _vm._v(
                            _vm._s(_vm.getStrLength(item.goodsName)) + "/60"
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass: "gaibiaoti",
                            on: {
                              click: function ($event) {
                                return _vm.changeTitle(item)
                              },
                            },
                          },
                          [_vm._v("AI改标题")]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "goods-id" }, [
                        _vm._v("商品ID:" + _vm._s(item.goodsId)),
                      ]),
                    ]),
                    _vm._v(" "),
                    _vm._l(item.specList, function (value, key) {
                      return _c("div", { key: key, staticClass: "xinghao" }, [
                        _c("div", { staticClass: "xing1" }, [
                          _vm._v(_vm._s(key)),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "xing2" },
                          _vm._l(value, function (item1, index1) {
                            return _c("div", { staticClass: "xingp" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: item1.specName,
                                    expression: "item1.specName",
                                  },
                                ],
                                staticStyle: {
                                  border: "none",
                                  width: "100%",
                                  color: "#6d6d6d",
                                },
                                attrs: { type: "text", placeholder: "请输入" },
                                domProps: { value: item1.specName },
                                on: {
                                  input: [
                                    function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        item1,
                                        "specName",
                                        $event.target.value
                                      )
                                    },
                                    function ($event) {
                                      return _vm.inputChange(item1, item)
                                    },
                                  ],
                                },
                              }),
                            ])
                          }),
                          0
                        ),
                      ])
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "shuju" },
                      [
                        _c(
                          "el-table",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              data: item.skuList,
                              height: "30vh",
                              "header-cell-style": {
                                background: "#e5e5e5",
                                color: "#2d2d2d",
                                height: "5vh",
                              },
                            },
                          },
                          [
                            _vm._l(item.specList, function (value, key, index) {
                              return _c("el-table-column", {
                                key: index,
                                attrs: { width: "350", label: key },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                scope.row.spec[index].specName
                                              ) +
                                              "\n\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              })
                            }),
                            _vm._v(" "),
                            _c(
                              "el-table-column",
                              {
                                attrs: { prop: "thumbUrl", label: "sku图" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                "align-items": "center",
                                              },
                                            },
                                            [
                                              _c("img", {
                                                staticStyle: {
                                                  width: "50px",
                                                  height: "50px",
                                                },
                                                attrs: {
                                                  src: scope.row.thumbUrl,
                                                  alt: "",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "label",
                                                {
                                                  staticClass: "custom-upload",
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        color:
                                                          "#f5f5f5 !important",
                                                      },
                                                    },
                                                    [_vm._v("修改图片")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("input", {
                                                    ref: "fileInput",
                                                    refInFor: true,
                                                    attrs: {
                                                      type: "file",
                                                      hidden: "",
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.changeImg(
                                                          scope.row,
                                                          $event
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                _c(
                                  "template",
                                  { slot: "header" },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.changeAll(item.skuList)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t\t\tsku图 （批量修改）\n\t\t\t\t\t\t\t\t\t\t"
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              2
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ])
            }),
            0
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "dialog-footer",
            attrs: { slot: "footer" },
            slot: "footer",
          },
          [
            _c(
              "el-button",
              { attrs: { size: "mini" }, on: { click: _vm.dialogClose } },
              [_vm._v("取 消")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                attrs: { size: "mini", type: "primary" },
                on: { click: _vm.submit },
              },
              [_vm._v("确 定")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("isLoading", { attrs: { visible: _vm.isLoading } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
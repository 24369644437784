var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: { title: "编辑是否二手", width: "680px", visible: true },
          on: { close: _vm.dialogClose },
        },
        [
          _c(
            "template",
            { slot: "title" },
            [
              _c(
                "el-tabs",
                {
                  on: { "tab-click": _vm.handleClick },
                  model: {
                    value: _vm.active,
                    callback: function ($$v) {
                      _vm.active = $$v
                    },
                    expression: "active",
                  },
                },
                [
                  _c("el-tab-pane", {
                    attrs: { label: "本地上传", name: "1" },
                  }),
                  _vm._v(" "),
                  _c("el-tab-pane", {
                    attrs: { label: "图片链接", name: "2" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.active === "1"
            ? _c("div", [
                _c(
                  "div",
                  { staticClass: "upload-box" },
                  [
                    _vm._l(_vm.imgList, function (item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "img-each" },
                        [_c("img", { attrs: { src: item, alt: "" } })]
                      )
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "img-each" }, [
                      _c("i", { staticClass: "el-icon-plus" }),
                      _vm._v(" "),
                      _c("input", {
                        attrs: { type: "file", multiple: "" },
                        on: {
                          change: function ($event) {
                            return _vm.changeImages($event)
                          },
                        },
                      }),
                    ]),
                  ],
                  2
                ),
              ])
            : _c(
                "div",
                [
                  _c("el-input", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      type: "textarea",
                      rows: 2,
                      placeholder: "直接输入图片链接, 一行一个",
                    },
                    model: {
                      value: _vm.imgLinks,
                      callback: function ($$v) {
                        _vm.imgLinks = $$v
                      },
                      expression: "imgLinks",
                    },
                  }),
                ],
                1
              ),
          _vm._v(" "),
          _c("span", { attrs: { slot: "footer" }, slot: "footer" }, [
            _c("div", { staticClass: "dialog-footer" }, [
              _c("div", { staticClass: "text" }, [
                _vm._v("已勾选了"),
                _c("span", [_vm._v(_vm._s(_vm.list.length))]),
                _vm._v("个商品，当前已上传"),
                _c("span", [_vm._v(_vm._s(_vm.imgList.length))]),
                _vm._v("个主图"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "el-button",
                    { attrs: { size: "mini" }, on: { click: _vm.dialogClose } },
                    [_vm._v("取 消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini", type: "primary" },
                      on: { click: _vm.uploadConfirm },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
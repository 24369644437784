var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: { title: "编辑商品编码", width: "60%", visible: true },
          on: { close: _vm.dialogClose },
        },
        [
          _c("div", { staticClass: "dialog-content" }, [
            _c("div", [_vm._v("已选商品数" + _vm._s(_vm.list.length))]),
            _vm._v(" "),
            _c("div", { staticClass: "edit-item" }, [
              _c("div", { staticClass: "edit-item-title" }, [
                _vm._v("修改对象："),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "edit-item-info" },
                [
                  _c("span", [_vm._v("仅修改包含")]),
                  _vm._v(" "),
                  _c("el-input", {
                    staticStyle: { width: "180px" },
                    attrs: {
                      size: "mini",
                      placeholder: "不填则修改以下所有的SKU",
                      "aria-placeholder": "",
                    },
                    on: { change: _vm.changeNum },
                    model: {
                      value: _vm.skuValue,
                      callback: function ($$v) {
                        _vm.skuValue = $$v
                      },
                      expression: "skuValue",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v("的SKU")]),
                  _vm._v(" "),
                  _c("span", [_vm._v("（共" + _vm._s(_vm.num) + "个SKU）")]),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "edit-item" }, [
              _c("div", { staticClass: "edit-item-title" }, [
                _vm._v("统一修改为"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "edit-item-info" },
                [
                  _c("el-input", {
                    staticStyle: { width: "180px" },
                    attrs: {
                      size: "mini",
                      clearable: "",
                      placeholder: "请输入",
                    },
                    model: {
                      value: _vm.unifiedCode,
                      callback: function ($$v) {
                        _vm.unifiedCode = $$v
                      },
                      expression: "unifiedCode",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100px" },
                      attrs: { size: "mini", placeholder: "请选择" },
                      model: {
                        value: _vm.unifiedOptionId,
                        callback: function ($$v) {
                          _vm.unifiedOptionId = $$v
                        },
                        expression: "unifiedOptionId",
                      },
                    },
                    _vm._l(_vm.options, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.text, value: item.id },
                      })
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini" },
                      on: {
                        click: function ($event) {
                          return _vm.executeHandle(1)
                        },
                      },
                    },
                    [_vm._v("执行")]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "edit-item" }, [
              _c("div", { staticClass: "edit-item-title" }, [
                _vm._v("统一编码文字替换"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "edit-item-info" },
                [
                  _c("el-input", {
                    staticStyle: { width: "180px" },
                    attrs: { size: "mini", placeholder: "请输入" },
                    model: {
                      value: _vm.beforeReplace,
                      callback: function ($$v) {
                        _vm.beforeReplace = $$v
                      },
                      expression: "beforeReplace",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v("为")]),
                  _vm._v(" "),
                  _c("el-input", {
                    staticStyle: { width: "180px" },
                    attrs: { size: "mini", placeholder: "请输入" },
                    model: {
                      value: _vm.afterReplace,
                      callback: function ($$v) {
                        _vm.afterReplace = $$v
                      },
                      expression: "afterReplace",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "120px" },
                      attrs: { size: "mini", placeholder: "请选择" },
                      model: {
                        value: _vm.replaceOptionId,
                        callback: function ($$v) {
                          _vm.replaceOptionId = $$v
                        },
                        expression: "replaceOptionId",
                      },
                    },
                    _vm._l(_vm.options, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.text, value: item.id },
                      })
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini" },
                      on: {
                        click: function ($event) {
                          return _vm.executeHandle(2)
                        },
                      },
                    },
                    [_vm._v("执行")]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "edit-item" }, [
              _c("div", { staticClass: "edit-item-title" }, [
                _vm._v("统一编码文字清除"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "edit-item-info" },
                [
                  _c("el-input", {
                    staticStyle: { width: "180px" },
                    attrs: { size: "mini", placeholder: "请输入" },
                    model: {
                      value: _vm.clearCode,
                      callback: function ($$v) {
                        _vm.clearCode = $$v
                      },
                      expression: "clearCode",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "120px" },
                      attrs: { size: "mini", placeholder: "请选择" },
                      model: {
                        value: _vm.clearOptionId,
                        callback: function ($$v) {
                          _vm.clearOptionId = $$v
                        },
                        expression: "clearOptionId",
                      },
                    },
                    _vm._l(_vm.options, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.text, value: item.id },
                      })
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini" },
                      on: {
                        click: function ($event) {
                          return _vm.executeHandle(3)
                        },
                      },
                    },
                    [_vm._v("执行")]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "edit-item" }, [
              _c("div", { staticClass: "edit-item-title" }, [_vm._v("开头加")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "edit-item-info" },
                [
                  _c("el-input", {
                    staticStyle: { width: "180px" },
                    attrs: { size: "mini", placeholder: "请输入" },
                    model: {
                      value: _vm.addStart,
                      callback: function ($$v) {
                        _vm.addStart = $$v
                      },
                      expression: "addStart",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "120px" },
                      attrs: { size: "mini", placeholder: "请选择" },
                      model: {
                        value: _vm.addStartOptionId,
                        callback: function ($$v) {
                          _vm.addStartOptionId = $$v
                        },
                        expression: "addStartOptionId",
                      },
                    },
                    _vm._l(_vm.options, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.text, value: item.id },
                      })
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini" },
                      on: {
                        click: function ($event) {
                          return _vm.executeHandle(4)
                        },
                      },
                    },
                    [_vm._v("执行")]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "edit-item" }, [
              _c("div", { staticClass: "edit-item-title" }, [_vm._v("结尾加")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "edit-item-info" },
                [
                  _c("el-input", {
                    staticStyle: { width: "180px" },
                    attrs: { size: "mini", placeholder: "请输入" },
                    model: {
                      value: _vm.addAfter,
                      callback: function ($$v) {
                        _vm.addAfter = $$v
                      },
                      expression: "addAfter",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "120px" },
                      attrs: { size: "mini", placeholder: "请选择" },
                      model: {
                        value: _vm.addAfterOptionId,
                        callback: function ($$v) {
                          _vm.addAfterOptionId = $$v
                        },
                        expression: "addAfterOptionId",
                      },
                    },
                    _vm._l(_vm.options, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.text, value: item.id },
                      })
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini" },
                      on: {
                        click: function ($event) {
                          return _vm.executeHandle(5)
                        },
                      },
                    },
                    [_vm._v("执行")]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "info-title" }, [_vm._v("商品")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "goods-detail" },
              _vm._l(_vm.list, function (item, index) {
                return _c(
                  "el-collapse",
                  {
                    key: index,
                    model: {
                      value: _vm.activeNames,
                      callback: function ($$v) {
                        _vm.activeNames = $$v
                      },
                      expression: "activeNames",
                    },
                  },
                  [
                    _c(
                      "el-collapse-item",
                      { attrs: { title: "", name: index } },
                      [
                        _c("template", { slot: "title" }, [
                          _c("div", { staticClass: "goods-detail-info" }, [
                            _c("img", {
                              attrs: { src: item.goodsPicture, alt: "" },
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "goods-detail-text" }, [
                              _c("div", { staticClass: "goods-name" }, [
                                _vm._v(_vm._s(item.goodsName)),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "goods-id" }, [
                                _vm._v("商品ID:" + _vm._s(item.goodsId)),
                              ]),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "price-unit" }, [
                          _c("div", { staticClass: "unit-head" }, [
                            _c("div", { staticClass: "head-title" }, [
                              _vm._v("SKU 名称（规格值）"),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "head-title" }, [
                              _vm._v("SKU编码"),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "unit-body" },
                            _vm._l(item.skuList, function (skuItem, skuIndex) {
                              return _c(
                                "div",
                                { key: skuIndex, staticClass: "row" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "name" },
                                    _vm._l(
                                      skuItem.spec,
                                      function (specItem, specIndex) {
                                        return _c("div", { key: specIndex }, [
                                          _vm._v(_vm._s(specItem.specName)),
                                        ])
                                      }
                                    ),
                                    0
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "price" },
                                    [
                                      _c("el-input", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          size: "mini",
                                          placeholder: "请输入",
                                        },
                                        model: {
                                          value: skuItem.outSkuSn,
                                          callback: function ($$v) {
                                            _vm.$set(skuItem, "outSkuSn", $$v)
                                          },
                                          expression: "skuItem.outSkuSn",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "unit-reference" },
                            [
                              _c("div", [_vm._v("商品编码")]),
                              _vm._v(" "),
                              _c("el-input", {
                                staticStyle: { width: "220px" },
                                attrs: { size: "mini", placeholder: "请输入" },
                                model: {
                                  value: item.outerGoodsId,
                                  callback: function ($$v) {
                                    _vm.$set(item, "outerGoodsId", $$v)
                                  },
                                  expression: "item.outerGoodsId",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ],
                      2
                    ),
                  ],
                  1
                )
              }),
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { size: "mini" }, on: { click: _vm.dialogClose } },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.editConfirm },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _systemSetting = require("@/api/systemSetting");
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: ['show'],
  data: function data() {
    return {
      imgs: [],
      //上传的所有图片
      fileList: [],
      dialogImageUrl: '',
      dialogVisible: false,
      upImg: '',
      //上传的base64图片
      uploadImgs: [],
      //上传的图片列表
      activeName: '1',
      linkVal: null,
      linkImgList: [] //链接生成的图片
    };
  },
  methods: {
    // 生成图片操作
    generateImg: function generateImg() {
      this.linkImgList = this.linkVal.split(/[\r\n]+/).filter(function (line) {
        return line.length > 0;
      });
      this.imgs = [].concat(_toConsumableArray(this.imgs), _toConsumableArray(this.linkImgList));
      console.log(this.imgs, 'this.fileListlinkImgListadImage', this.$parent.uploadNum);
      this.$parent.uploadNum = this.imgs.length;
      this.$parent.myfirstPic = this.imgs;
    },
    ensureHandle: function ensureHandle() {
      console.log(32423423423, this.$parent);
      this.show = false;
      this.linkImgList = [];
      this.linkVal = '';
      this.uploadImgs = [];
      this.fileList = [];
    },
    // 上传图片的逻辑
    handleUploadForm: function handleUploadForm() {
      var _this = this;
      var loading = this.$loading({
        lock: true,
        text: '上传中，请稍候...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      console.log(this.$parent, 'this.$parent==uploadImg', this.linkImgList);
      var param = {
        mallId: this.$parent.mallId,
        image: this.upImg
      };
      (0, _systemSetting.uploadImage)(param).then(function (res) {
        loading.close();
        if (res.errorResponse) {
          _this.$message.error(res.errorResponse.errorMsg);
          return;
        }
        _this.$message.success('上传成功');
        _this.uploadImgs.push(res.goodsImageUploadResponse.imageUrl);
        if (_this.$parent.selectedId == 1) {
          _this.$parent.uplistimg1 = _this.$parent.uplistimg1.length != 0 ? [].concat(_toConsumableArray(_this.$parent.uplistimg1), _toConsumableArray(_this.uploadImgs)) : _this.uploadImgs;
          _this.$parent.uplistimg1 = Array.from(new Set(_this.$parent.uplistimg1));
          _this.$parent.img1 = _this.uploadImgs[0];
          _this.$parent.seNumCopy1 = _this.$parent.uplistimg1.length;
        }
        if (_this.$parent.selectedId == 2) {
          _this.$parent.uplistimg2 = _this.$parent.uplistimg2.length != 0 ? [].concat(_toConsumableArray(_this.$parent.uplistimg2), _toConsumableArray(_this.uploadImgs)) : _this.uploadImgs;
          _this.$parent.uplistimg2 = Array.from(new Set(_this.$parent.uplistimg2));
          _this.$parent.img2 = _this.uploadImgs[0];
          _this.$parent.seNumCopy2 = _this.$parent.uplistimg2.length;
        }
        if (_this.$parent.selectedId == 3) {
          _this.$parent.uplistimg3 = _this.$parent.uplistimg3.length != 0 ? [].concat(_toConsumableArray(_this.$parent.uplistimg3), _toConsumableArray(_this.uploadImgs)) : _this.uploadImgs;
          _this.$parent.uplistimg3 = Array.from(new Set(_this.$parent.uplistimg3));
          _this.$parent.img3 = _this.uploadImgs[0];
          _this.$parent.seNumCopy3 = _this.$parent.uplistimg3.length;
        }
        if (_this.$parent.selectedId == 4) {
          _this.$parent.uplistimg4 = _this.$parent.uplistimg4.length != 0 ? [].concat(_toConsumableArray(_this.$parent.uplistimg4), _toConsumableArray(_this.uploadImgs)) : _this.uploadImgs;
          _this.$parent.uplistimg4 = Array.from(new Set(_this.$parent.uplistimg4));
          _this.$parent.img4 = _this.uploadImgs[0];
          _this.$parent.seNumCopy4 = _this.$parent.uplistimg4.length;
        }
        _this.fileList.push({
          url: res.goodsImageUploadResponse.imageUrl
        });
        console.log(_this.uploadImgs, 'uploadIm33111', _this.linkImgList);
        _this.$parent.myfirstPic = _this.imgs;
      }).catch(function (res) {
        loading.close();
      });

      // this.$forceUpdate()
    },
    beforeUpload: function beforeUpload(file) {
      var _this2 = this;
      console.log(file, 44441);
      var reader = new FileReader();
      reader.onload = function (e) {
        _this2.upImg = e.target.result;
        _this2.handleUploadForm();
      };
      reader.onerror = function (error) {
        console.error('Error reading file:', error);
      };
      reader.readAsDataURL(file);
    },
    handleRemove: function handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview: function handlePictureCardPreview(file) {
      console.log(file, 'handlePictureCardPreview');
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // 关闭弹窗
    closeDialog: function closeDialog() {
      this.$emit('update:show', false);
    }
  }
};
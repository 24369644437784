var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.pageLoading,
          expression: "pageLoading",
        },
      ],
      staticClass: "divBox",
    },
    [
      _c(
        "div",
        { staticClass: "_container" },
        [
          _c(
            "div",
            { staticClass: "tab-box" },
            [
              _c(
                "el-tabs",
                {
                  on: { "tab-click": _vm.handleClick },
                  model: {
                    value: _vm.tabActiveName,
                    callback: function ($$v) {
                      _vm.tabActiveName = $$v
                    },
                    expression: "tabActiveName",
                  },
                },
                [
                  _c("el-tab-pane", {
                    attrs: { label: "商品复制", name: "first" },
                  }),
                  _vm._v(" "),
                  _c("el-tab-pane", {
                    attrs: { label: "任务列表", name: "second" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.tabActiveName == "second"
            ? _c(
                "div",
                [
                  _c("div", { staticClass: "search-box" }, [
                    _c(
                      "div",
                      { staticClass: "search-item" },
                      [
                        _c("span", { staticClass: "title" }, [
                          _vm._v("选择店铺："),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-select",
                          {
                            staticClass: "custom-select",
                            staticStyle: { width: "200px" },
                            attrs: {
                              "popper-append-to-body": false,
                              "collapse-tags": "",
                              multiple: "",
                              placeholder: "请选择",
                              size: "mini",
                            },
                            on: {
                              change: _vm.storeChange2,
                              "remove-tag": _vm.removeTag1,
                            },
                            model: {
                              value: _vm.valueSelect2,
                              callback: function ($$v) {
                                _vm.valueSelect2 = $$v
                              },
                              expression: "valueSelect2",
                            },
                          },
                          [
                            _vm._l(_vm.optionsSelect1, function (item) {
                              return _c(
                                "el-option",
                                {
                                  key: item.id,
                                  attrs: {
                                    disabled: item.onlineStatus == 0,
                                    label: item.mallName,
                                    value: item.mallId,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "options-list",
                                      staticStyle: { width: "240px" },
                                    },
                                    [
                                      _c(
                                        "el-checkbox",
                                        {
                                          staticStyle: { width: "112%" },
                                          attrs: {
                                            disabled: item.onlineStatus == 0,
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.checkboxChange1(item)
                                            },
                                          },
                                          model: {
                                            value: item.check,
                                            callback: function ($$v) {
                                              _vm.$set(item, "check", $$v)
                                            },
                                            expression: "item.check",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                display: "inline-block",
                                                width: "130px",
                                                overflow: "hidden",
                                                "vertical-align": "middle",
                                              },
                                            },
                                            [_vm._v(_vm._s(item.mallName))]
                                          ),
                                          _vm._v(
                                            "\n                     \n                    "
                                          ),
                                          _c(
                                            "span",
                                            { staticStyle: { color: "#f01" } },
                                            [
                                              _vm._v(
                                                "  积分:" +
                                                  _vm._s(
                                                    Number(item.integral) +
                                                      Number(item.giftIntegral)
                                                  )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "checkboxWrapper" },
                              [
                                _c(
                                  "el-checkbox",
                                  {
                                    on: { change: _vm.checkChangeAll1 },
                                    model: {
                                      value: _vm.checkedAll1,
                                      callback: function ($$v) {
                                        _vm.checkedAll1 = $$v
                                      },
                                      expression: "checkedAll1",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                  全选\n                "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c("i", {
                          class: [
                            _vm.rotate1
                              ? "el-icon-refresh go"
                              : "el-icon-refresh",
                          ],
                          staticStyle: {
                            margin: "0 5px 0 5px",
                            "font-size": "18px",
                            position: "relative",
                            cursor: "pointer",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.getCloudsShopList(1)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "btn" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "primary",
                              icon: "el-icon-search",
                            },
                            on: { click: _vm.searcAccordHandle },
                          },
                          [_vm._v("搜索\n            ")]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticStyle: { height: "20px" } }),
                  _vm._v(" "),
                  _c(
                    "el-table",
                    {
                      staticStyle: { border: "1px solid red" },
                      attrs: {
                        data: _vm.taskListData,
                        "header-cell-style": { background: "#eef1f6" },
                        border: "",
                        height: "calc(100vh - 330px)",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "序号",
                          type: "index",
                          width: "50",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { align: "center", label: "商品来源店铺" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(scope.row.authStoreName) +
                                      "\n            "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3154259245
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { align: "center", label: "复制到店铺" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(scope.row.targetPddMallName) +
                                      "\n            "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1792326999
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "总数量",
                          prop: "allCount",
                          width: "110px",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "复制成功数量",
                          prop: "successCount",
                          width: "110px",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "复制失败数量",
                          prop: "failCount",
                          width: "110px",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "跳过商品数量",
                          prop: "skipCount",
                          width: "110px",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "任务创建时间",
                          prop: "createTime",
                          width: "260px",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "任务状态",
                          prop: "status",
                          width: "110px",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.status == 0
                                    ? _c("span", [_vm._v("待处理")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  scope.row.status == 1
                                    ? _c("span", [_vm._v("处理中")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  scope.row.status == 2
                                    ? _c("span", [_vm._v("已完成")])
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3054665557
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { align: "center", label: "操作", width: "220" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { size: "small", type: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.showtaskHandle(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("查看日志")]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3436802516
                        ),
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-pagination", {
                    attrs: {
                      "current-page": _vm.currentPageRecord,
                      "page-size": _vm.limitRecord,
                      "page-sizes": [10, 20, 50, 100],
                      total: _vm.totalRecord,
                      layout: "total, sizes, prev, pager, next, jumper",
                    },
                    on: {
                      "size-change": _vm.handleSizeRecordChange,
                      "current-change": _vm.handleCurrentRecordChange,
                    },
                  }),
                ],
                1
              )
            : _c("div", { staticClass: "copy-box" }, [
                _c("div", { staticClass: "bottom-table-box" }, [
                  _c("div", { staticClass: "row-bottom" }, [
                    _c("div", { staticClass: "search-box" }, [
                      _c(
                        "div",
                        { staticClass: "search-item" },
                        [
                          _c("span", { staticClass: "title" }, [
                            _vm._v("选择复制店铺："),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-select",
                            {
                              staticClass: "custom-select",
                              staticStyle: { width: "200px" },
                              attrs: {
                                "popper-append-to-body": false,
                                placeholder: "请选择",
                                size: "mini",
                              },
                              on: { change: _vm.storeChange1 },
                              model: {
                                value: _vm.valueSelect1,
                                callback: function ($$v) {
                                  _vm.valueSelect1 = $$v
                                },
                                expression: "valueSelect1",
                              },
                            },
                            _vm._l(_vm.optionsSelect, function (item) {
                              return _c(
                                "el-option",
                                {
                                  key: item.id,
                                  attrs: {
                                    disabled: item.onlineStatus == 0,
                                    label: item.mallName,
                                    value: item.mallId,
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { float: "left" } },
                                    [_vm._v(_vm._s(item.mallName))]
                                  ),
                                ]
                              )
                            }),
                            1
                          ),
                          _vm._v(" "),
                          _vm.$store.state.user.app_isClient == 1
                            ? _c("i", {
                                class: [
                                  _vm.rotate1
                                    ? "el-icon-refresh go"
                                    : "el-icon-refresh",
                                ],
                                staticStyle: {
                                  margin: "0 5px 0 5px",
                                  "font-size": "18px",
                                  position: "relative",
                                  cursor: "pointer",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.getCloudsShopList(1)
                                  },
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.statusVal == 2
                        ? _c("div", [
                            _c(
                              "div",
                              { staticClass: "search-item" },
                              [
                                _c("span", { staticClass: "title" }, [
                                  _vm._v("商品名称："),
                                ]),
                                _vm._v(" "),
                                _c("el-input", {
                                  staticStyle: { width: "180px" },
                                  attrs: {
                                    placeholder: "请输入商品名称",
                                    size: "mini",
                                  },
                                  model: {
                                    value: _vm.goodsName,
                                    callback: function ($$v) {
                                      _vm.goodsName = $$v
                                    },
                                    expression: "goodsName",
                                  },
                                }),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "search-item" },
                        [
                          _c("span", { staticClass: "title" }, [
                            _vm._v("发布时间："),
                          ]),
                          _vm._v(" "),
                          _c("el-date-picker", {
                            staticStyle: { width: "280px" },
                            attrs: {
                              "end-placeholder": "结束日期",
                              "range-separator": "至",
                              size: "mini",
                              "start-placeholder": "开始日期",
                              type: "daterange",
                              "value-format": "timestamp",
                            },
                            model: {
                              value: _vm.postTime,
                              callback: function ($$v) {
                                _vm.postTime = $$v
                              },
                              expression: "postTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row-bottom" }, [
                    _c("div", { staticClass: "search-box" }, [
                      _c(
                        "div",
                        { staticClass: "search-item" },
                        [
                          _c("span", { staticClass: "title" }, [
                            _vm._v("选择商品状态："),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "200px" },
                              attrs: { placeholder: "请选择", size: "mini" },
                              model: {
                                value: _vm.statusVal,
                                callback: function ($$v) {
                                  _vm.statusVal = $$v
                                },
                                expression: "statusVal",
                              },
                            },
                            _vm._l(_vm.statusList, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: {
                                  label: item.label,
                                  value: item.value,
                                  size: "mini",
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticStyle: { "margin-right": "24px" } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                size: "mini",
                                type: "primary",
                                icon: "el-icon-search",
                              },
                              on: { click: _vm.searchHandle },
                            },
                            [_vm._v("搜索")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { size: "mini", type: "primary" },
                              on: { click: _vm.openSet },
                            },
                            [_vm._v("铺货设置")]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row-bottom" }, [
                    _c("div", { staticClass: "search-box" }, [
                      _c(
                        "div",
                        { staticClass: "search-item" },
                        [
                          _c("span", { staticClass: "title" }, [
                            _vm._v("选择复制到店铺："),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-select",
                            {
                              staticClass: "custom-select",
                              staticStyle: { width: "240px" },
                              attrs: {
                                "popper-append-to-body": false,
                                "collapse-tags": "",
                                multiple: "",
                                placeholder: "请选择",
                                size: "mini",
                              },
                              on: {
                                change: _vm.storeChange,
                                "remove-tag": _vm.removeTag,
                              },
                              model: {
                                value: _vm.valueSelect3,
                                callback: function ($$v) {
                                  _vm.valueSelect3 = $$v
                                },
                                expression: "valueSelect3",
                              },
                            },
                            [
                              _vm._l(_vm.optionsSelect, function (item) {
                                return _c(
                                  "el-option",
                                  {
                                    key: item.mallId,
                                    attrs: {
                                      disabled: item.onlineStatus == 0,
                                      label: item.mallName,
                                      value: item.mallId,
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "options-list",
                                        staticStyle: { width: "240px" },
                                      },
                                      [
                                        _c(
                                          "el-checkbox",
                                          {
                                            staticStyle: { width: "112%" },
                                            attrs: {
                                              disabled: item.onlineStatus == 0,
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.checkboxChange(item)
                                              },
                                            },
                                            model: {
                                              value: item.check,
                                              callback: function ($$v) {
                                                _vm.$set(item, "check", $$v)
                                              },
                                              expression: "item.check",
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  display: "inline-block",
                                                  width: "130px",
                                                  overflow: "hidden",
                                                  "vertical-align": "middle",
                                                },
                                              },
                                              [_vm._v(_vm._s(item.mallName))]
                                            ),
                                            _vm._v(
                                              "\n                         \n                        "
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticStyle: { color: "#f01" },
                                              },
                                              [
                                                _vm._v(
                                                  "  积分:" +
                                                    _vm._s(
                                                      Number(item.integral) +
                                                        Number(
                                                          item.giftIntegral
                                                        )
                                                    )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "checkboxWrapper" },
                                [
                                  _c(
                                    "el-checkbox",
                                    {
                                      on: { change: _vm.checkChangeAll },
                                      model: {
                                        value: _vm.checkedAll,
                                        callback: function ($$v) {
                                          _vm.checkedAll = $$v
                                        },
                                        expression: "checkedAll",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                      全选\n                    "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c("i", {
                            class: [
                              _vm.rotate2
                                ? "el-icon-refresh go"
                                : "el-icon-refresh",
                            ],
                            staticStyle: {
                              margin: "0 5px 0 5px",
                              "font-size": "18px",
                              position: "relative",
                              cursor: "pointer",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.getCloudsShopList(2)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticStyle: { "margin-right": "24px" } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                loading: _vm.copyLoading,
                                size: "mini",
                                type: "primary",
                              },
                              on: { click: _vm.copyNow },
                            },
                            [_vm._v("立刻复制")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                loading: _vm.copyAllLoading,
                                size: "mini",
                                type: "primary",
                              },
                              on: { click: _vm.copyAllList },
                            },
                            [_vm._v("整店复制\n                ")]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {},
                    [
                      _vm._v("\n            已勾选 "),
                      _c("span", { staticStyle: { color: "#f01" } }, [
                        _vm._v(_vm._s(_vm.multipleSelection.length)),
                      ]),
                      _vm._v(" 条\n            "),
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            data: _vm.goodsList,
                            "header-cell-style": { background: "#eef1f6" },
                            "row-key": "goodsId",
                            height: "calc(100vh - 400px)",
                          },
                          on: { "selection-change": _vm.handleSelectionChange },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              "reserve-selection": true,
                              type: "selection",
                              width: "80",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "序号",
                              type: "index",
                              width: "80",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { label: "商品信息", prop: "date" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("div", { staticClass: "info-box" }, [
                                      _c("img", {
                                        staticClass: "img-g",
                                        attrs: {
                                          src: scope.row.goodsPicture,
                                          alt: "",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "info-txt" }, [
                                        _c("div", { staticClass: "txt-til" }, [
                                          _vm._v(
                                            "\n                        " +
                                              _vm._s(scope.row.goodsName) +
                                              "\n                      "
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "g-id" }, [
                                          _vm._v(
                                            "商品ID:\n                        " +
                                              _vm._s(scope.row.goodsId) +
                                              "\n                      "
                                          ),
                                        ]),
                                      ]),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { label: "商品链接", prop: "name" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm._v(
                                      "\n                  https://mobile.yangkeduo.com/goods.html?goods_id= " +
                                        _vm._s(scope.row.goodsId) +
                                        "\n                "
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "pagetation-box" },
                    [
                      _c("el-pagination", {
                        attrs: {
                          "current-page": _vm.currentPage,
                          "page-size": _vm.limitSize,
                          "page-sizes": [10, 20, 50, 100],
                          total: _vm.total,
                          layout: "total, sizes, prev, pager, next, jumper",
                        },
                        on: {
                          "size-change": _vm.handleSizeChange,
                          "current-change": _vm.handleCurrentChange,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
          _vm._v(" "),
          _c("taskLogDialog", {
            ref: "taskDetailListDataRef",
            attrs: {
              data: _vm.taskDetailListData,
              show: _vm.taskLogVisible,
              taskId: _vm.taskId,
            },
            on: {
              "update:show": function ($event) {
                _vm.taskLogVisible = $event
              },
            },
          }),
          _vm._v(" "),
          _c("openSetDialog", {
            ref: "openSetDialogRef",
            attrs: { list: _vm.valueSelect3, showSet: _vm.openSetVisible },
            on: {
              "update:showSet": function ($event) {
                _vm.openSetVisible = $event
              },
              "update:show-set": function ($event) {
                _vm.openSetVisible = $event
              },
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
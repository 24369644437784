var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: { title: "编辑商品详情图", width: "60%", visible: true },
          on: { close: _vm.dialogClose },
        },
        [
          _c("div", { staticClass: "dialog-content" }, [
            _c("div", [_vm._v("已选商品数" + _vm._s(_vm.list.length))]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "each" },
              [
                _c("span", [_vm._v("删除第")]),
                _vm._v(" "),
                _c("el-input-number", {
                  staticStyle: { width: "150px" },
                  attrs: { size: "mini", "controls-position": "right", min: 0 },
                  model: {
                    value: _vm.delIndex,
                    callback: function ($$v) {
                      _vm.delIndex = $$v
                    },
                    expression: "delIndex",
                  },
                }),
                _vm._v(" "),
                _c("span", [_vm._v("张")]),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { size: "mini", disabled: !_vm.delIndex },
                    on: {
                      click: function ($event) {
                        return _vm.executeHandle(1)
                      },
                    },
                  },
                  [_vm._v("执行")]
                ),
                _vm._v(" "),
                _c("span", { staticStyle: { color: "red" } }, [
                  _vm._v("如果不存在则会被忽略"),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "each" },
              [
                _c("span", [_vm._v("删除前")]),
                _vm._v(" "),
                _c("el-input-number", {
                  staticStyle: { width: "150px" },
                  attrs: { size: "mini", "controls-position": "right", min: 0 },
                  model: {
                    value: _vm.delFirst,
                    callback: function ($$v) {
                      _vm.delFirst = $$v
                    },
                    expression: "delFirst",
                  },
                }),
                _vm._v(" "),
                _c("span", [_vm._v("张")]),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { size: "mini", disabled: !_vm.delFirst },
                    on: {
                      click: function ($event) {
                        return _vm.executeHandle(2)
                      },
                    },
                  },
                  [_vm._v("执行")]
                ),
                _vm._v(" "),
                _c("span", { staticStyle: { color: "red" } }, [
                  _vm._v("如果不存在则会被忽略"),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "each" },
              [
                _c("span", [_vm._v("删除后")]),
                _vm._v(" "),
                _c("el-input-number", {
                  staticStyle: { width: "150px" },
                  attrs: { size: "mini", "controls-position": "right", min: 0 },
                  model: {
                    value: _vm.delLast,
                    callback: function ($$v) {
                      _vm.delLast = $$v
                    },
                    expression: "delLast",
                  },
                }),
                _vm._v(" "),
                _c("span", [_vm._v("张")]),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { size: "mini", disabled: !_vm.delLast },
                    on: {
                      click: function ($event) {
                        return _vm.executeHandle(3)
                      },
                    },
                  },
                  [_vm._v("执行")]
                ),
                _vm._v(" "),
                _c("span", { staticStyle: { color: "red" } }, [
                  _vm._v("如果不存在则会被忽略"),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "each" },
              [
                _c("span", [_vm._v("将第")]),
                _vm._v(" "),
                _c("el-input-number", {
                  staticStyle: { width: "150px" },
                  attrs: { size: "mini", "controls-position": "right", min: 0 },
                  model: {
                    value: _vm.topUpIndex,
                    callback: function ($$v) {
                      _vm.topUpIndex = $$v
                    },
                    expression: "topUpIndex",
                  },
                }),
                _vm._v(" "),
                _c("span", [_vm._v("张图置顶")]),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { size: "mini", disabled: !_vm.topUpIndex },
                    on: {
                      click: function ($event) {
                        return _vm.executeHandle(4)
                      },
                    },
                  },
                  [_vm._v("执行")]
                ),
                _vm._v(" "),
                _c("span", { staticStyle: { color: "red" } }, [
                  _vm._v("如果不存在则会被忽略"),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "each" },
              [
                _c("span", [_vm._v("将倒数第")]),
                _vm._v(" "),
                _c("el-input-number", {
                  staticStyle: { width: "150px" },
                  attrs: { size: "mini", "controls-position": "right", min: 0 },
                  model: {
                    value: _vm.topUpLastIndex,
                    callback: function ($$v) {
                      _vm.topUpLastIndex = $$v
                    },
                    expression: "topUpLastIndex",
                  },
                }),
                _vm._v(" "),
                _c("span", [_vm._v("张图置顶")]),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { size: "mini", disabled: !_vm.topUpLastIndex },
                    on: {
                      click: function ($event) {
                        return _vm.executeHandle(5)
                      },
                    },
                  },
                  [_vm._v("执行")]
                ),
                _vm._v(" "),
                _c("span", { staticStyle: { color: "red" } }, [
                  _vm._v("如果不存在则会被忽略"),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticStyle: { "margin-top": "8px" } },
              [
                _c(
                  "el-button",
                  { staticClass: "batch-up-load", attrs: { size: "mini" } },
                  [
                    _vm._v("\n          批量添加图片至开头\n          "),
                    _c("input", {
                      ref: "fileInput",
                      attrs: { type: "file", multiple: "" },
                      on: {
                        change: function ($event) {
                          return _vm.changeImages($event, 1)
                        },
                      },
                    }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  { staticClass: "batch-up-load", attrs: { size: "mini" } },
                  [
                    _vm._v("\n          批量添加图片至末尾\n          "),
                    _c("input", {
                      ref: "fileInput",
                      attrs: { type: "file", multiple: "" },
                      on: {
                        change: function ($event) {
                          return _vm.changeImages($event, 2)
                        },
                      },
                    }),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "info-title" }, [_vm._v("商品详细信息")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "goods-info" },
              _vm._l(_vm.list, function (item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "goods-info-item" },
                  [
                    _c("div", { staticClass: "goods-info-item-head" }, [
                      _c("div", { staticClass: "item-head-left" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "item-goods-info" }, [
                        _c("div", { staticClass: "goods-name" }, [
                          _vm._v(_vm._s(item.goodsName)),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "goods-id" }, [
                          _vm._v("商品ID:" + _vm._s(item.goodsId)),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "goods-info-item-img" }, [
                      _c("div", { staticClass: "item-img-title" }, [
                        _c("span", [_vm._v("*")]),
                        _vm._v("商品详情图："),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "item-img-content" }, [
                        _c(
                          "div",
                          { staticClass: "item-img-show" },
                          [
                            _c("div", { staticClass: "tips" }, [
                              _vm._v("页面预览总高度3099px"),
                            ]),
                            _vm._v(" "),
                            _vm._l(
                              item.detailGalleryList,
                              function (imgItem, index) {
                                return _c("img", {
                                  key: index,
                                  attrs: { src: imgItem, alt: "" },
                                })
                              }
                            ),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "item-img-operate" }, [
                          _c("div", { staticClass: "tips" }, [
                            _vm._v(
                              "(图片宽度应为480-1200px，高度应为1500px以内,最多上传50张)"
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "img-content" },
                            [
                              _vm._l(
                                item.detailGalleryList,
                                function (imgItem, imgIndex) {
                                  return _c(
                                    "div",
                                    { key: imgIndex, staticClass: "img-item" },
                                    [
                                      _c("div", { staticClass: "img" }, [
                                        _c("img", {
                                          attrs: { src: imgItem, alt: "" },
                                        }),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "operate" }, [
                                        _c(
                                          "i",
                                          { staticClass: "el-icon-upload2" },
                                          [
                                            _c("input", {
                                              ref: "fileInput",
                                              refInFor: true,
                                              attrs: { type: "file" },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.changeImg(
                                                    $event,
                                                    index,
                                                    imgIndex
                                                  )
                                                },
                                              },
                                            }),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("i", {
                                          staticClass: "el-icon-download",
                                          on: {
                                            click: function ($event) {
                                              return _vm.downloadImg(imgItem)
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("i", {
                                          staticClass:
                                            "el-icon-picture-outline",
                                          on: {
                                            click: function ($event) {
                                              return _vm.previewImg(imgItem)
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("i", {
                                          staticClass: "el-icon-delete",
                                          on: {
                                            click: function ($event) {
                                              return _vm.delImg(index, imgIndex)
                                            },
                                          },
                                        }),
                                      ]),
                                    ]
                                  )
                                }
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "img-item-upload" }, [
                                _c("div", [_vm._v("+")]),
                                _vm._v(" "),
                                _c("div", [_vm._v("上传图片")]),
                                _vm._v(" "),
                                _c("input", {
                                  ref: "fileInput",
                                  refInFor: true,
                                  attrs: { type: "file" },
                                  on: {
                                    change: function ($event) {
                                      return _vm.uploadImg($event, item)
                                    },
                                  },
                                }),
                              ]),
                            ],
                            2
                          ),
                        ]),
                      ]),
                    ]),
                  ]
                )
              }),
              0
            ),
          ]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { size: "mini" }, on: { click: _vm.dialogClose } },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.editConfirm },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "预览图片",
            width: "45%",
            visible: _vm.previewDialog,
          },
          on: {
            "update:visible": function ($event) {
              _vm.previewDialog = $event
            },
          },
        },
        [
          _c("div", { staticClass: "preview-dialog-img" }, [
            _c("img", { attrs: { src: _vm.img, alt: "" } }),
          ]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.copyImgAddress },
                },
                [_vm._v("复制原图地址")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "danger" },
                  on: {
                    click: function ($event) {
                      _vm.previewDialog = false
                    },
                  },
                },
                [_vm._v("关闭窗口")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: { title: "编辑承诺发货时间", width: "60%", visible: true },
          on: { close: _vm.dialogClose },
        },
        [
          _c("div", { staticClass: "dialog-content" }, [
            _c("div", [_vm._v("已选商品数" + _vm._s(_vm.list.length))]),
            _vm._v(" "),
            _c("div", { staticClass: "edit-item" }, [
              _c("div", { staticClass: "edit-item-title" }, [
                _vm._v("批量设置承诺发货时间"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "edit-item-info" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.shipmentLimitSecond,
                        callback: function ($$v) {
                          _vm.shipmentLimitSecond = $$v
                        },
                        expression: "shipmentLimitSecond",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "" } }, [
                        _vm._v("当天发售"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 86400 } }, [
                        _vm._v("24小时"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 172800 } }, [
                        _vm._v("48小时"),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini" },
                      on: { click: _vm.executeHandle },
                    },
                    [_vm._v("执行")]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "info-title" }, [_vm._v("商品详细信息")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "goods-info" },
              _vm._l(_vm.list, function (item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "goods-info-item" },
                  [
                    _c("div", { staticClass: "goods-info-item-head" }, [
                      _c("div", { staticClass: "item-head-left" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "item-goods-info" }, [
                        _c("div", { staticClass: "goods-name" }, [
                          _vm._v(_vm._s(item.goodsName)),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "goods-id" }, [
                          _vm._v("商品ID:" + _vm._s(item.goodsId)),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "goods-info-item-title" }, [
                      _c("div", { staticClass: "item-title-title" }, [
                        _c("span", [_vm._v("*")]),
                        _vm._v("承诺发货时间："),
                      ]),
                      _vm._v(" "),
                      item.presaleWay === "非预售"
                        ? _c(
                            "div",
                            { staticClass: "item-title-info" },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: item.shipmentLimitSecond,
                                    callback: function ($$v) {
                                      _vm.$set(item, "shipmentLimitSecond", $$v)
                                    },
                                    expression: "item.shipmentLimitSecond",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: "" } }, [
                                    _vm._v("当天发售"),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-radio", { attrs: { label: 86400 } }, [
                                    _vm._v("24小时"),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-radio", { attrs: { label: 172800 } }, [
                                    _vm._v("48小时"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _c(
                            "div",
                            {
                              staticClass: "item-title-info",
                              staticStyle: { color: "#FF0000" },
                            },
                            [
                              _vm._v(
                                "\n              只有非预售商品支持设置承诺发货时间\n            "
                              ),
                            ]
                          ),
                    ]),
                  ]
                )
              }),
              0
            ),
          ]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { size: "mini" }, on: { click: _vm.dialogClose } },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.editConfirm },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.pageLoading,
          expression: "pageLoading",
        },
      ],
      staticClass: "divBox",
    },
    [
      _c("div", { staticClass: "_container" }, [
        _c(
          "div",
          { staticClass: "tab-box" },
          [
            _c(
              "el-tabs",
              {
                on: { "tab-click": _vm.handleClick },
                model: {
                  value: _vm.activeName,
                  callback: function ($$v) {
                    _vm.activeName = $$v
                  },
                  expression: "activeName",
                },
              },
              [
                _c("el-tab-pane", { attrs: { label: "导入修改", name: "2" } }),
                _vm._v(" "),
                _c("el-tab-pane", { attrs: { label: "导出商品", name: "1" } }),
                _vm._v(" "),
                _c("el-tab-pane", { attrs: { label: "编辑记录", name: "0" } }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _vm.activeName === "2"
          ? _c("div", { staticClass: "exportIn-box" }, [
              _c(
                "div",
                { staticClass: "cat-list-box" },
                _vm._l(_vm.catList, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: item.id,
                      staticClass: "cat-box",
                      class: _vm.currentIndex == index ? "active" : "",
                      on: {
                        click: function ($event) {
                          return _vm.catHandle(index, item)
                        },
                      },
                    },
                    [_c("span", [_vm._v(_vm._s(item.name))])]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _vm._m(0),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "content-box" },
                _vm._l(10, function (item) {
                  return _c("div", { staticClass: "box-item" }, [
                    _c("p", { staticClass: "title" }, [_vm._v("自定义标题")]),
                    _vm._v(" "),
                    _c("p", { staticClass: "txt-til" }, [
                      _vm._v(
                        "看见快不快乐不看不看不看可编辑版本空间科技部可怜见看来吧刻录机老客户"
                      ),
                    ]),
                    _vm._v(" "),
                    _c("img", {
                      staticClass: "img-c",
                      attrs: {
                        src: "https://cz.chuangyujie.com/pdd_commodity/img/excel_export.f9e87a77.png",
                        alt: "",
                      },
                    }),
                    _vm._v(" "),
                    _c("p", { staticClass: "btn" }, [
                      _vm._v("\n            导入EXCLE修改商品\n          "),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "bot-1" }, [
                      _vm._v("请先前往到处山攻击哦i啊手动阀哦怕就是阿斯蒂芬"),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "bot-1" }, [
                      _vm._v("请先前往到处山攻击哦i啊手动阀哦怕就是阿斯蒂芬"),
                    ]),
                  ])
                }),
                0
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.activeName === "1"
          ? _c(
              "div",
              { staticClass: "exportOut-box" },
              [
                _c(
                  "div",
                  { staticClass: "cat-list-box" },
                  _vm._l(_vm.menuList, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: item.id,
                        staticClass: "cat-box",
                        class: _vm.exportCurrentIndex == index ? "active" : "",
                        on: {
                          click: function ($event) {
                            return _vm.catExportHandle(index, item)
                          },
                        },
                      },
                      [_c("span", [_vm._v(_vm._s(item.name))])]
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _vm._m(1),
                _vm._v(" "),
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%", "margin-top": "20px" },
                    attrs: {
                      data: _vm.tableData,
                      "header-cell-style": {
                        background: "#eef1f6",
                        fontWeight: "700",
                      },
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { type: "selection", width: "55" },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "商品信息",
                        align: "center",
                        width: "300",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("div", { staticClass: "info-box" }, [
                                  _c("img", {
                                    staticClass: "img-g",
                                    attrs: {
                                      src: scope.row.goodsPicture,
                                      alt: "",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "info-txt" }, [
                                    _c("div", { staticClass: "txt-til" }, [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(scope.row.goodsName) +
                                          "\n                "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "g-id" }, [
                                      _vm._v(
                                        "商品ID:\n                  " +
                                          _vm._s(scope.row.goodsId) +
                                          "\n                "
                                      ),
                                    ]),
                                  ]),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3833601382
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "price",
                        align: "center",
                        width: "250",
                        label: "价格",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("div", { staticClass: "price" }, [
                                  _c("div", { staticClass: "price-box" }, [
                                    _c(
                                      "p",
                                      { staticStyle: { color: "#FF003E" } },
                                      [
                                        _vm._v(
                                          "拼单价：" +
                                            _vm._s(scope.row.minGroupPrice) +
                                            "-" +
                                            _vm._s(scope.row.maxGroupPrice) +
                                            "￥"
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("p", [
                                      _vm._v(
                                        "单买价：" +
                                          _vm._s(
                                            scope.row.minSinglePurchasePrice
                                          ) +
                                          "-" +
                                          _vm._s(
                                            scope.row.maxSinglePurchasePrice
                                          ) +
                                          "￥"
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("p", [
                                      _vm._v(
                                        "参考价：" +
                                          _vm._s(scope.row.marketPrice) +
                                          "￥"
                                      ),
                                    ]),
                                  ]),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        4054569381
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "SKU", align: "center" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(scope.row.sku) +
                                    "\n          "
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3634699888
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "状态", align: "center" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.isOnsale == 0
                                  ? _c(
                                      "el-tag",
                                      { attrs: { type: "danger" } },
                                      [_vm._v("已下架")]
                                    )
                                  : _c(
                                      "el-tag",
                                      { attrs: { type: "success" } },
                                      [_vm._v("上架中")]
                                    ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1614311178
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "goodsQuantity",
                        label: "库存",
                        align: "center",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "presaleWay",
                        label: "是否预售",
                        align: "center",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.activeName === "0"
          ? _c(
              "div",
              { staticClass: "exportOut-box" },
              [
                _vm._m(2),
                _vm._v(" "),
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%", "margin-top": "20px" },
                    attrs: {
                      data: _vm.accordData,
                      border: "",
                      "header-cell-style": {
                        background: "#e2f2fd",
                        fontWeight: "700",
                      },
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        prop: "goodsQuantity",
                        label: "序号",
                        width: "50",
                        align: "center",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "编辑商品数量",
                        width: "160",
                        align: "center",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        width: "140",
                        prop: "price",
                        align: "center",
                        label: "提交成功数量",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "提交失败数量",
                        width: "140",
                        align: "center",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "任务类型",
                        width: "140",
                        align: "center",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "goodsQuantity",
                        label: "任务创建时间",
                        width: "160",
                        align: "center",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "presaleWay",
                        label: "任务状态",
                        width: "130",
                        align: "center",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { fixed: "right", align: "center", label: "操作" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary", size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.goodsSplit(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("AI裂变")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary", size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openSplitDiolag(scope.row)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v("裂变记录\n              "),
                                    scope.row.fissionAddAmount > 0
                                      ? _c("span", [
                                          _vm._v(
                                            "(" +
                                              _vm._s(
                                                scope.row.fissionAddAmount >= 99
                                                  ? "99+"
                                                  : scope.row.fissionAddAmount
                                              ) +
                                              ")"
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3540931046
                      ),
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tip-box" }, [
      _c("p", [
        _vm._v("1.导入功能分为 "),
        _c("strong", [_vm._v("自定义导入修改")]),
        _vm._v(" 和其他 "),
        _c("strong", [_vm._v("固定模板导入修改 。")]),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v("2.先在“导出商品”页面中导出想修改的商品信息，导出后修改数据。"),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v("3.若使用 "),
        _c("strong", [_vm._v("自定义导入修改")]),
        _vm._v("（推荐使用）则直接导入修改，若使用其它 "),
        _c("strong", [_vm._v("\n          固定模板的导入修改 ")]),
        _vm._v("则先将导出的数据更改为对应模板再进行导入修改。"),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v("4.使用中还有其他疑问/建议，可以"),
        _c("a", { staticStyle: { color: "#0C8AFF" }, attrs: { href: "#" } }, [
          _vm._v("查看教程"),
        ]),
        _vm._v("或联系客服。 "),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tip-box" }, [
      _c("p", [
        _vm._v("1.导入功能分为 "),
        _c("strong", [_vm._v("自定义导入修改")]),
        _vm._v(" 和其他 "),
        _c("strong", [_vm._v("固定模板导入修改 。")]),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v("2.先在“导出商品”页面中导出想修改的商品信息，导出后修改数据。"),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v("3.若使用 "),
        _c("strong", [_vm._v("自定义导入修改")]),
        _vm._v("（推荐使用）则直接导入修改，若使用其它 "),
        _c("strong", [_vm._v("\n          固定模板的导入修改 ")]),
        _vm._v("则先将导出的数据更改为对应模板再进行导入修改。"),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v("4.使用中还有其他疑问/建议，可以"),
        _c("a", { staticStyle: { color: "#0C8AFF" }, attrs: { href: "#" } }, [
          _vm._v("查看教程"),
        ]),
        _vm._v("或联系客服。 "),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "tip-box",
        staticStyle: {
          border: "1px solid #e2f2fd",
          height: "34px",
          "line-height": "10px",
        },
      },
      [
        _c("p", [
          _vm._v(
            "任务进行时请勿刷新浏览器页面，否则可能导致任务中的部分商品未被修改。"
          ),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }
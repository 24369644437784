var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "divBox" }, [
    _c(
      "div",
      { staticClass: "wrapper-box" },
      [
        _c(
          "div",
          { staticClass: "bannerBox" },
          [
            _c(
              "el-carousel",
              { attrs: { height: "430px" } },
              _vm._l(_vm.bannerList, function (item) {
                return _c("el-carousel-item", { key: item.id }, [
                  _c("img", {
                    staticStyle: { width: "100%", height: "100%" },
                    attrs: { src: item.picture, alt: "" },
                  }),
                ])
              }),
              1
            ),
            _vm._v(" "),
            _vm.noticeList.length > 0
              ? _c("div", { staticClass: "notice" }, [
                  _c(
                    "div",
                    { staticClass: "noticeInfo" },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/imgs/notice.png"),
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "el-carousel",
                        {
                          staticStyle: { width: "530px" },
                          attrs: {
                            height: "55px",
                            "indicator-position": "none",
                            direction: "vertical",
                            autoplay: true,
                          },
                        },
                        _vm._l(_vm.noticeList, function (item, index) {
                          return _c("el-carousel-item", { key: index }, [
                            _c("div", { staticClass: "medium" }, [
                              _c(
                                "div",
                                { staticStyle: { padding: "0 20px" } },
                                [_vm._v(_vm._s(item))]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "noticeMore" }, [
                                _vm._v("查看更多"),
                              ]),
                            ]),
                          ])
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-row",
          { staticStyle: { "margin-top": "20px" }, attrs: { gutter: 20 } },
          [
            _c("el-col", { attrs: { span: 9 } }, [
              _c("div", { staticClass: "contentItem contentLeft" }, [
                _vm.app_isClient == 0
                  ? _c("div", { staticClass: "leftTop" }, [
                      _c("img", {
                        staticClass: "userImg",
                        attrs: {
                          src: this.$store.state.user.userInfo.logo,
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "userInfo" }, [
                        _c("div", { staticClass: "userPhone" }, [
                          _vm._v(
                            "\n                  当前店铺：" +
                              _vm._s(this.$store.state.user.userInfo.mallName) +
                              "\n                "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "userMall" }, [
                          _vm._v(
                            "\n                  店铺ID：" +
                              _vm._s(this.$store.state.user.userInfo.mallId) +
                              "\n                "
                          ),
                        ]),
                      ]),
                    ])
                  : _c("div", { staticClass: "leftTop" }, [
                      _c("div", { staticClass: "userIcon" }, [
                        _c("div", { staticClass: "userN" }),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "userInfo" }, [
                        _c("div", { staticClass: "userPhone" }, [
                          _vm._v(_vm._s(_vm.userName)),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "userMall" }, [
                          _vm._v("已授权店铺:" + _vm._s(_vm.shopNum) + "个"),
                        ]),
                      ]),
                    ]),
                _vm._v(" "),
                _vm.businessState && this.$store.state.user.app_isClient === 1
                  ? _c("div", { staticClass: "business" }, [
                      _c("div", { staticClass: "businessTxt" }, [
                        _vm._v("企业版用户"),
                      ]),
                      _vm._v(" "),
                      _c("img", {
                        attrs: {
                          src: require("@/assets/imgs/vipqy.png"),
                          alt: "",
                        },
                      }),
                    ])
                  : _vm.businessState === false &&
                    this.$store.state.user.app_isClient === 1
                  ? _c("div", { staticClass: "businessNo" }, [
                      _c("div", { staticClass: "businessTxt" }, [
                        _c("div", [_vm._v("您还不是企业版哟~")]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "margin-top": "10px",
                              "font-size": "16px",
                              "font-weight": "400",
                            },
                          },
                          [_vm._v("开通企业版，享机会商品裂变")]
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "ktBtn", on: { click: _vm.openSet } },
                        [_vm._v("立即开通")]
                      ),
                      _vm._v(" "),
                      _c("img", {
                        staticClass: "businessImg",
                        attrs: {
                          src: require("@/assets/imgs/vipno.png"),
                          alt: "",
                        },
                      }),
                    ])
                  : _c("div", { staticClass: "business" }, [
                      _c("img", {
                        staticClass: "businessImg",
                        attrs: {
                          src: require("@/assets/imgs/downloadApp.png"),
                          alt: "",
                        },
                      }),
                    ]),
                _vm._v(" "),
                this.$store.state.user.app_isClient == 1
                  ? _c(
                      "div",
                      {
                        staticClass: "equity",
                        staticStyle: {
                          display: "flex",
                          "flex-direction": "column",
                          "align-content": "center",
                          "justify-content": "center",
                          padding: "0 30px",
                        },
                      },
                      [
                        _c(
                          "p",
                          {
                            staticStyle: {
                              "font-family": "Source Han Sans CN",
                              "font-weight": "500",
                              "font-size": "14px",
                              color: "#2C2C2C",
                              "font-style": "italic",
                              "margin-bottom": "20px",
                            },
                          },
                          [_vm._v("企业版功能")]
                        ),
                        _vm._v(" "),
                        _c(
                          "p",
                          {
                            staticStyle: {
                              "font-weight": "400",
                              "font-size": "12px",
                              color: "#878686",
                              "margin-bottom": "15px",
                            },
                          },
                          [
                            _vm._v(
                              "\n                1.机会商品裂变，必得机会商品标"
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "p",
                          {
                            staticStyle: {
                              "font-weight": "400",
                              "font-size": "12px",
                              color: "#878686",
                              "margin-bottom": "15px",
                            },
                          },
                          [_vm._v("2.所有功能，积分免费用")]
                        ),
                        _vm._v(" "),
                        _c(
                          "p",
                          {
                            staticStyle: {
                              "text-align": "right",
                              "font-size": "12px",
                              color: "#373737",
                              cursor: "pointer",
                            },
                            on: { click: _vm.upHandle },
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-arrow-right",
                              staticStyle: { "font-weight": "600" },
                            }),
                            _c("i", {
                              staticClass: "el-icon-arrow-right",
                              staticStyle: {
                                "margin-left": "-6px",
                                "font-weight": "600",
                              },
                            }),
                            _vm._v("立即升级"),
                          ]
                        ),
                      ]
                    )
                  : _c(
                      "div",
                      {
                        staticClass: "equity",
                        staticStyle: {
                          display: "flex",
                          "flex-direction": "column",
                          "align-content": "center",
                          "justify-content": "center",
                          padding: "0 30px",
                        },
                      },
                      [
                        _c(
                          "p",
                          {
                            staticStyle: {
                              "font-family": "Source Han Sans CN",
                              "font-weight": "500",
                              "font-size": "14px",
                              color: "#2C2C2C",
                              "font-style": "italic",
                              "margin-bottom": "20px",
                            },
                          },
                          [_vm._v("企业版功能")]
                        ),
                        _vm._v(" "),
                        _c(
                          "p",
                          {
                            staticStyle: {
                              "font-weight": "400",
                              "ont-size": "12px",
                              color: "#878686",
                              "margin-bottom": "15px",
                            },
                          },
                          [
                            _vm._v(
                              "\n                1.机会商品裂变，必得机会商品标"
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "p",
                          {
                            staticStyle: {
                              "font-weight": "400",
                              "font-size": "12px",
                              color: "#878686",
                              "margin-bottom": "15px",
                            },
                          },
                          [_vm._v("2.所有功能，积分免费用")]
                        ),
                        _vm._v(" "),
                        _c(
                          "p",
                          {
                            staticStyle: {
                              "text-align": "right",
                              "font-size": "12px",
                              color: "#373737",
                              cursor: "pointer",
                            },
                            on: { click: _vm.upDownHandle },
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-arrow-right",
                              staticStyle: { "font-weight": "600" },
                            }),
                            _c("i", {
                              staticClass: "el-icon-arrow-right",
                              staticStyle: {
                                "margin-left": "-6px",
                                "font-weight": "600",
                              },
                            }),
                            _vm._v("立即下载"),
                          ]
                        ),
                      ]
                    ),
              ]),
            ]),
            _vm._v(" "),
            _c("el-col", { attrs: { span: 9 } }, [
              _c(
                "div",
                { staticClass: "contentItem contentMiddle" },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    _vm._l(_vm.iconList, function (item) {
                      return _c(
                        "el-col",
                        {
                          key: item.id,
                          staticClass: "iconItem",
                          attrs: { span: 6 },
                        },
                        [
                          _c("img", {
                            attrs: { src: item.img, alt: "" },
                            on: {
                              click: function ($event) {
                                return _vm.handlePageClick(item)
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("div", { staticStyle: { "margin-top": "8px" } }, [
                            _vm._v(_vm._s(item.title)),
                          ]),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("div", { staticClass: "contentItem contentRight" }, [
                _c("div", { staticClass: "kefu" }, [
                  _c("img", {
                    attrs: { src: require("@/assets/imgs/kefu.png"), alt: "" },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "kefuTxt" }, [_vm._v("联系客服")]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "kefuInfo" }, [
                  _vm._v("\n              联系客服领取100积分\n            "),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "kefuInfo" }, [
                  _vm._v("\n              在线时间：9:00-18:00\n            "),
                ]),
                _vm._v(" "),
                _c("img", {
                  staticStyle: {
                    "margin-top": "20px",
                    "max-width": "330px",
                    width: "100%",
                  },
                  attrs: {
                    src: require("@/assets/imgs/a794187c13d5e49448d240aae6fce27.jpg"),
                    alt: "",
                  },
                }),
              ]),
            ]),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
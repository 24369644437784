var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "编辑是否二手",
            width: "60%",
            "z-index": 1000,
            visible: _vm.dialogId === 19,
          },
          on: { open: _vm.dialogOpen, close: _vm.dialogClose },
        },
        [
          _c("template", { slot: "title" }, [
            _c(
              "span",
              { staticStyle: { "font-size": "16px", "line-height": "25px" } },
              [_vm._v("编辑是否二手")]
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                staticStyle: {
                  color: "red",
                  "margin-left": "20px",
                  "font-size": "16px",
                  "line-height": "25px",
                },
              },
              [
                _vm._v(
                  " 本功能对商品分类有要求，大部分分类下的商品不支持修改！"
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "dialog-content" }, [
            _c("div", [_vm._v("已选商品数" + _vm._s(_vm.list.length))]),
            _vm._v(" "),
            _c("div", { staticClass: "edit-item" }, [
              _c("div", { staticClass: "edit-item-title" }, [
                _vm._v("批量是否二手"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "edit-item-info" },
                [
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.checked,
                        callback: function ($$v) {
                          _vm.checked = $$v
                        },
                        expression: "checked",
                      },
                    },
                    [_vm._v("二手")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini" },
                      on: {
                        click: function ($event) {
                          return _vm.executeHandle(1)
                        },
                      },
                    },
                    [_vm._v("执行")]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "info-title" }, [_vm._v("商品详细信息")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "goods-info" },
              _vm._l(_vm.list, function (item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "goods-info-item" },
                  [
                    _c("div", { staticClass: "goods-info-item-head" }, [
                      _c("div", { staticClass: "item-head-left" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "item-goods-info" }, [
                        _c("div", { staticClass: "goods-name" }, [
                          _vm._v(_vm._s(item.goodsName)),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "goods-id" }, [
                          _vm._v("商品ID:" + _vm._s(item.goodsId)),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "goods-info-item-title" }, [
                      _c("div", { staticClass: "item-title-title" }, [
                        _c("span", [_vm._v("*")]),
                        _vm._v("是否二手："),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "item-title-info" },
                        [
                          _c(
                            "el-checkbox",
                            {
                              model: {
                                value: item.secondHand,
                                callback: function ($$v) {
                                  _vm.$set(item, "secondHand", $$v)
                                },
                                expression: "item.secondHand",
                              },
                            },
                            [_vm._v("二手")]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]
                )
              }),
              0
            ),
          ]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { size: "mini" }, on: { click: _vm.dialogClose } },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.editConfirm(1)
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "编辑7天无理由退换货",
            width: "60%",
            "z-index": 1000,
            visible: _vm.dialogId === 20,
          },
          on: { open: _vm.dialogOpen, close: _vm.dialogClose },
        },
        [
          _c("template", { slot: "title" }, [
            _c(
              "span",
              { staticStyle: { "font-size": "16px", "line-height": "25px" } },
              [_vm._v("编辑7天无理由退换货")]
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                staticStyle: {
                  color: "red",
                  "margin-left": "20px",
                  "font-size": "16px",
                  "line-height": "25px",
                },
              },
              [_vm._v(" 大部分商品不支持修改！")]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "dialog-content" }, [
            _c("div", [_vm._v("已选商品数" + _vm._s(_vm.list.length))]),
            _vm._v(" "),
            _c("div", { staticClass: "edit-item" }, [
              _c(
                "div",
                {
                  staticClass: "edit-item-title",
                  staticStyle: { width: "180px" },
                },
                [_vm._v("批量设置7天无理由退换货")]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "edit-item-info" },
                [
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.checked,
                        callback: function ($$v) {
                          _vm.checked = $$v
                        },
                        expression: "checked",
                      },
                    },
                    [_vm._v("是")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini" },
                      on: {
                        click: function ($event) {
                          return _vm.executeHandle(2)
                        },
                      },
                    },
                    [_vm._v("执行")]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "info-title" }, [_vm._v("商品详细信息")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "goods-info" },
              _vm._l(_vm.list, function (item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "goods-info-item" },
                  [
                    _c("div", { staticClass: "goods-info-item-head" }, [
                      _c("div", { staticClass: "item-head-left" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "item-goods-info" }, [
                        _c("div", { staticClass: "goods-name" }, [
                          _vm._v(_vm._s(item.goodsName)),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "goods-id" }, [
                          _vm._v("商品ID:" + _vm._s(item.goodsId)),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "goods-info-item-title" }, [
                      _c(
                        "div",
                        {
                          staticClass: "item-title-title",
                          staticStyle: { width: "160px" },
                        },
                        [_c("span", [_vm._v("*")]), _vm._v("7天无理由退换货：")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "item-title-info" },
                        [
                          _c(
                            "el-checkbox",
                            {
                              model: {
                                value: item.isRefundable,
                                callback: function ($$v) {
                                  _vm.$set(item, "isRefundable", $$v)
                                },
                                expression: "item.isRefundable",
                              },
                            },
                            [_vm._v("是")]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]
                )
              }),
              0
            ),
          ]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { size: "mini" }, on: { click: _vm.dialogClose } },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.editConfirm(2)
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "编辑坏了包赔",
            width: "60%",
            "z-index": 1000,
            visible: _vm.dialogId === 21,
          },
          on: { close: _vm.dialogClose },
        },
        [
          _c("div", { staticClass: "dialog-content" }, [
            _c("div", [_vm._v("已选商品数" + _vm._s(_vm.list.length))]),
            _vm._v(" "),
            _c("div", { staticClass: "edit-item" }, [
              _c("div", { staticClass: "edit-item-title" }, [
                _vm._v("批量设置坏了包赔"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "edit-item-info" },
                [
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.checked,
                        callback: function ($$v) {
                          _vm.checked = $$v
                        },
                        expression: "checked",
                      },
                    },
                    [_vm._v("是")]
                  ),
                  _vm._v(" "),
                  _c("el-button", { attrs: { size: "mini" } }, [
                    _vm._v("执行"),
                  ]),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "info-title" }, [_vm._v("商品详细信息")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "goods-info" },
              _vm._l(_vm.list, function (item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "goods-info-item" },
                  [
                    _c("div", { staticClass: "goods-info-item-head" }, [
                      _c("div", { staticClass: "item-head-left" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "item-goods-info" }, [
                        _c("div", { staticClass: "goods-name" }, [
                          _vm._v(_vm._s(item.goodsName)),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "goods-id" }, [
                          _vm._v("商品ID:" + _vm._s(item.goodsId)),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "goods-info-item-title" }, [
                      _c("div", { staticClass: "item-title-title" }, [
                        _c("span", [_vm._v("*")]),
                        _vm._v("坏了包赔："),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "item-title-info" },
                        [
                          _c(
                            "el-checkbox",
                            {
                              model: {
                                value: item.badFruitClaim,
                                callback: function ($$v) {
                                  _vm.$set(item, "badFruitClaim", $$v)
                                },
                                expression: "item.badFruitClaim",
                              },
                            },
                            [_vm._v("是")]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]
                )
              }),
              0
            ),
          ]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { size: "mini" }, on: { click: _vm.dialogClose } },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.editConfirm(3)
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "根据商品ID批量选择商品",
            visible: _vm.dialogShow === 26,
            width: "40%",
          },
          on: { close: _vm.dialogClose },
        },
        [
          _c(
            "div",
            [
              _c("el-alert", {
                attrs: {
                  title:
                    "可以输入商品ID(一行一个商品ld)，必须是该店铺的商品才可以",
                  type: "info",
                  "show-icon": "",
                  closable: false,
                },
              }),
              _vm._v(" "),
              _c("div", { staticStyle: { height: "10px" } }),
              _vm._v(" "),
              _c("el-input", {
                staticStyle: { width: "100%" },
                attrs: { type: "textarea", rows: 2, placeholder: "请输入" },
                model: {
                  value: _vm.goodsIds,
                  callback: function ($$v) {
                    _vm.goodsIds = $$v
                  },
                  expression: "goodsIds",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                { attrs: { size: "mini" }, on: { click: _vm.dialogClose } },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.submitConfirm },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
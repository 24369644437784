"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _validate = require("@/utils/validate");
require("@/assets/js/canvas-nest.min.js");
var _user = require("@/api/user");
var _public = require("@/libs/public");
var _wechat = require("@/libs/wechat");
var _wxApi = require("@/api/wxApi");
var _auth = require("@/utils/auth");
var _jsCookie = _interopRequireDefault(require("js-cookie"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'Login',
  data: function data() {
    var validateUsername = function validateUsername(rule, value, callback) {
      if (!(0, _validate.validUsername)(value)) {
        callback(new Error('Please enter the correct user name'));
      } else {
        callback();
      }
    };
    var validatePassword = function validatePassword(rule, value, callback) {
      if (value.length < 6 || value.length > 12) {
        callback(new Error('密码位数为6-12位'));
      } else {
        callback();
      }
    };
    return {
      rememberMe: false,
      countdown: 60,
      hasAccount: true,
      //是否有账号
      captchatImg: '',
      swiperList: [],
      loginLogo: '',
      countState: true,
      backgroundImages: require('@/assets/imgs/bg.jpg'),
      backgroundImageMo: require('@/assets/imgs/bg.jpg'),
      fullWidth: document.body.clientWidth,
      swiperOption: {
        pagination: {
          el: '.pagination'
        },
        autoplay: {
          enabled: true,
          disableOnInteraction: false,
          delay: 3000
        }
      },
      registerForm: {
        tel: '',
        // admin
        psd: '',
        code1: '',
        key: ''
      },
      registerRules: {
        tel: [{
          required: true,
          trigger: 'blur',
          message: '请输入手机号'
        }],
        // validator: validateUsername
        psd: [{
          required: true,
          trigger: 'blur',
          message: '请输入密码'
        }],
        code1: [{
          required: true,
          message: '请输入正确的验证码',
          trigger: 'blur'
        }]
      },
      loginForm: {
        account: '',
        // admin
        pwd: ''
      },
      loginRules: {
        account: [{
          required: true,
          trigger: 'blur',
          message: '请输入用户名'
        }],
        // validator: validateUsername
        pwd: [{
          required: true,
          trigger: 'blur',
          message: '请输入密码'
        }]
        // code: [{ required: true, message: '请输入正确的验证码', trigger: 'blur' }]
      },
      passwordType: 'password',
      capsTooltip: false,
      loading: false,
      showDialog: false,
      redirect: undefined,
      otherQuery: {}
    };
  },
  watch: {
    fullWidth: function fullWidth(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.screenWidth = val;
        this.timer = true;
        var that = this;
        setTimeout(function () {
          // 打印screenWidth变化的值
          that.timer = false;
        }, 400);
      }
    },
    $route: {
      handler: function handler(route) {
        var query = route.query;
        if (query) {
          this.redirect = query.redirect;
          this.otherQuery = this.getOtherQuery(query);
        }
      },
      immediate: true
    }
  },
  created: function created() {
    var _this = this;
    document.onkeydown = function (e) {
      if (_this.$route.path.indexOf('login') !== -1) {
        var key = window.event.keyCode;
        if (key === 13) {
          _this.handleLogin();
        }
      }
    };
    window.addEventListener('resize', this.handleResize);
    var _data = JSON.parse(localStorage.getItem('loginForm'));
    if (_data) {
      this.loginForm = {
        account: _data.account,
        // admin
        pwd: _data.pwd,
        key: '',
        code: '',
        wxCode: ''
      };
      this.rememberMe = true;
    }
  },
  mounted: function mounted() {
    var _this2 = this;
    this.getInfo();
    this.$nextTick(function () {
      if (_this2.screenWidth < 768) {
        document.getElementsByTagName('canvas')[0].removeAttribute('class', 'index_bg');
      } else {
        document.getElementsByTagName('canvas')[0].className = 'index_bg';
      }
    });
    // if (this.loginForm.account === '') {
    //   this.$refs.account.focus()
    // } else if (this.loginForm.pwd === '') {
    //   this.$refs.pwd.focus()
    // }
    // this.getCaptcha()
    // this.agentWeiXinLogin()
  },
  beforeCreate: function beforeCreate() {
    if (this.fullWidth < 768) {
      document.getElementsByTagName('canvas')[0].removeAttribute('class', 'index_bg');
    } else {
      document.getElementsByTagName('canvas')[0].className = 'index_bg';
    }
  },
  destroyed: function destroyed() {
    // window.removeEventListener('storage', this.afterQRScan)
  },
  beforeDestroy: function beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
    document.getElementsByTagName('canvas')[0].removeAttribute('class', 'index_bg');
  },
  methods: {
    // 倒计时60秒
    startCountdown: function startCountdown() {
      var _this3 = this;
      if (!this.timer) {
        this.countdown = 60;
        this.countdown1 = 60;
        this.timer = setInterval(function () {
          if (_this3.countdown > 0) {
            _this3.countdown--;
          } else {
            clearInterval(_this3.timer);
            _this3.timer = null;
          }
          if (_this3.countdown1 > 0) {
            _this3.countdown1--;
          } else {
            clearInterval(_this3.timer);
            _this3.timer = null;
          }
        }, 1000);
      }
    },
    // 获取手机验证码
    getTelCode: function getTelCode() {
      var _this4 = this;
      var regex = /^1[3-9]\d{9}$/;
      if (regex.test(this.registerForm.tel) == false) {
        this.$message.error('请正确输入手机号码');
        return;
      }
      this.countState = false;
      this.startCountdown();
      var param = {
        phone: this.registerForm.tel
      };
      (0, _user.sendSms)(param).then(function (res) {
        _this4.registerForm.key = res.key;
      });
      console.log(this.registerForm.tel, 7777);
    },
    agentWeiXinLogin: function agentWeiXinLogin() {
      var _this5 = this;
      // 判断是否需要微信公众号登陆
      var _isWechat = this.$wechat.isWeixin();
      if (_isWechat) {
        var code = this.$route.query.code;
        var state = this.$route.query.state;
        var wxAuthPath = location.origin + '/login';
        //  如果没有code 去获取
        if (null == code) {
          (0, _wechat.getWXCodeByUrl)(wxAuthPath, 'step1');
        }
        // 如果有state=step1 根据code去登陆
        if (state === 'step1') {
          (0, _wechat.loginByWxCode)(code).then(function (res) {
            sessionStorage.setItem('token', res.token);
            _this5.$router.push({
              path: _this5.redirect || '/',
              query: _this5.otherQuery
            });
          }).catch(function (err) {
            // 如果登陆失败，那么输入账号登陆，重新获取code传递给后端做绑定
            (0, _wechat.getWXCodeByUrl)(wxAuthPath, 'step2');
          });
        } else if (state === 'step2') {
          this.loginForm.wxCode = code;
        }
      }
    },
    onWechat: function onWechat() {
      var url = this.$route.query.redirect ? this.$route.query.redirect : '/dashboard';
      this.$wechat.oAuth(url, 'login');
    },
    handleResize: function handleResize(event) {
      this.fullWidth = document.body.clientWidth;
      if (this.fullWidth < 768) {
        document.getElementsByTagName('canvas')[0].removeAttribute('class', 'index_bg');
      } else {
        document.getElementsByTagName('canvas')[0].className = 'index_bg';
      }
    },
    getInfo: function getInfo() {
      var _this6 = this;
      (0, _user.getLoginPicApi)().then(function (res) {
        _this6.swiperList = res.banner;
        _this6.loginLogo = res.loginLogo;
        // this.backgroundImages = res.backgroundImage
        // Cookies.set('MerInfo', JSON.stringify(data))
      });
    },
    checkCapslock: function checkCapslock(e) {
      var key = e.key;
      this.capsTooltip = key && key.length === 1 && key >= 'A' && key <= 'Z';
    },
    showPwd: function showPwd() {
      var _this7 = this;
      if (this.passwordType === 'password') {
        this.passwordType = '';
      } else {
        this.passwordType = 'password';
      }
      this.$nextTick(function () {
        _this7.$refs.pwd.focus();
      });
    },
    registerLogin: function registerLogin() {
      var _this8 = this;
      if (this.registerForm.tel == '') {
        this.$message.error('请输入手机号码');
        return;
      }
      if (this.registerForm.code1 == '') {
        this.$message.error('请输入验证码');
        return;
      }
      if (this.registerForm.psd == '') {
        this.$message.error('请输入密码');
        return;
      }
      this.registerForm.account = this.registerForm.tel;
      this.registerForm.pwd = this.registerForm.psd;
      this.registerForm.code = this.registerForm.code1;
      this.registerForm.key = this.registerForm.key;
      (0, _user.registerAuth)(this.registerForm).then(function (res) {
        console.log(res);
        _this8.$message.success('注册成功');
        _this8.hasAccount = true;
      });
    },
    handleLogin: function handleLogin() {
      var _this9 = this;
      if (this.rememberMe) {
        localStorage.setItem('loginForm', JSON.stringify(this.loginForm));
      } else {
        localStorage.removeItem('loginForm');
      }
      var code = this.$route.query.code;
      this.$refs.loginForm.validate(function (valid) {
        if (valid) {
          _this9.loading = true;
          if (_this9.$wechat.isWeixin()) {
            _this9.loginForm.wxCode = code;
          }
          _this9.$store.dispatch('user/login', _this9.loginForm).then(function () {
            _this9.$router.push({
              path: _this9.redirect || '/',
              query: _this9.otherQuery
            });
            (0, _public.getStoreStaff)();
            _this9.loading = false;
          }).catch(function (err) {
            _this9.loading = false;
            if (_this9.$wechat.isPhone()) _this9.$dialog.error(err.message);
            _this9.getCaptcha();
          });
        } else {
          return false;
        }
      });
    },
    getCaptcha: function getCaptcha() {
      var _this10 = this;
      (0, _user.captchaApi)().then(function (data) {
        _this10.captchatImg = data.code;
        _this10.loginForm.key = data.key;
      }).catch(function (_ref) {
        var message = _ref.message;
        _this10.$message.error(message);
      });
    },
    getOtherQuery: function getOtherQuery(query) {
      return Object.keys(query).reduce(function (acc, cur) {
        if (cur !== 'redirect') {
          acc[cur] = query[cur];
        }
        return acc;
      }, {});
    }
  },
  computed: {
    remainingTime: function remainingTime() {
      if (this.countdown == 0) {
        this.countState = true;
      } else {
        this.countState = false;
      }
      return this.countdown > 0 ? this.countdown : 0;
    }
  }
};
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  name: 'MenuItem',
  functional: true,
  props: {
    icon: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    }
  },
  render: function render(h, context) {
    // console.log('context.props======>', context);
    // console.log('context.parent.$route======>', context.parent.$route);
    // 获取当前路由
    var routeName = context.parent.$route.meta.title;
    var _context$props = context.props,
      icon = _context$props.icon,
      title = _context$props.title;
    var vnodes = [];
    var src = '';
    if (icon) {
      if (icon == 's-home') {
        if (routeName == '首页' || routeName == '主页') {
          src = require('@/assets/imgs/iconImg/home_1.png');
        } else {
          src = require('@/assets/imgs/iconImg/home.png');
        }
      }
      if (icon == 's-shop') {
        if (routeName == '店铺管理') {
          src = require('@/assets/imgs/iconImg/shop_1.png');
        } else {
          src = require('@/assets/imgs/iconImg/shop.png');
        }
      }
      if (icon == 's-operation') {
        if (routeName == '商品裂变') {
          src = require('@/assets/imgs/iconImg/3334.png');
        } else {
          src = require('@/assets/imgs/iconImg/liebian.png');
        }
      }
      if (icon == 's-marketing') {
        if (routeName == 'AI商品裂变') {
          src = require('@/assets/imgs/iconImg/liebian_1.png');
        } else {
          src = require('@/assets/imgs/iconImg/jhliebian1.png');
        }
      }
      if (icon == 's-cooperation') {
        if (routeName == '机会商品裂变') {
          src = require('@/assets/imgs/iconImg/jhliebian_1.png');
        } else {
          src = require('@/assets/imgs/iconImg/jhliebian.png');
        }
      }
      if (icon == 's-release') {
        if (routeName == 'AI机会商品裂变') {
          src = require('@/assets/imgs/iconImg/AI001.png');
        } else {
          src = require('@/assets/imgs/iconImg/ai0011.png');
        }
      }
      if (icon == 's-order') {
        if (routeName == 'AI商品运营') {
          src = require('@/assets/imgs/iconImg/ProductModifications_1.png');
        } else {
          src = require('@/assets/imgs/iconImg/ProductModifications.png');
        }
      }
      if (icon == 'postcard') {
        if (routeName == '机会授权店铺复制') {
          src = require('@/assets/imgs/iconImg/Opportunitygoodsmulti-storemove_1.png');
        } else {
          src = require('@/assets/imgs/iconImg/Opportunitygoodsmulti-storemove.png');
        }
      }
      if (icon == 'suitcase') {
        if (routeName == '授权店铺复制') {
          src = require('@/assets/imgs/iconImg/storemoves_1.png');
        } else {
          src = require('@/assets/imgs/iconImg/storemoves.png');
        }
      }
      if (icon == 'upload') {
        if (routeName == '一店搬多店') {
          src = require('@/assets/imgs/iconImg/multipleStores_1.png');
        } else {
          src = require('@/assets/imgs/iconImg/multipleStores.png');
        }
      }
      if (icon == 'folder-opened') {
        src = require('@/assets/imgs/151.png');
      }
      if (icon == 's-grid') {
        src = require('@/assets/imgs/6661.png');
      }
      if (icon == 'printer') {
        src = require('@/assets/imgs/toool1.png');
      }
      if (icon == 'caret-bottom') {
        if (routeName == 'AI换SKU文字') {
          src = require('@/assets/imgs/iconImg/SKUCopywritinAI_1.png');
        } else {
          src = require('@/assets/imgs/iconImg/SKUCopywritinAI.png');
        }
      }
      vnodes.push(h("img", {
        "attrs": {
          "src": src
        },
        "style": "width:20px;height:20px;margin-right:6px",
        "class": "icon-l"
      }));
    }
    if (title) {
      if (title == '机会商品裂变' || title == '机会商品多店搬家') {
        vnodes.push(h("span", {
          "slot": "title"
        }, [title, " ", h("img", {
          "style": "width:15px;margin-top:-4px",
          "attrs": {
            "src": "https://oss.feiniao123.com/file/feiNiaoProductManagement/icon/80ff24e7637c7823e9701cb679033ea.png",
            "alt": ""
          }
        }), "       "]));
      } else {
        vnodes.push(h("span", {
          "slot": "title"
        }, [title]));
      }
    }
    return vnodes;
  }
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c("div", { staticClass: "loading-overlay" }, [
        _c("div", { staticClass: "loading-spinner" }),
        _vm._v(" "),
        _c(
          "div",
          {
            staticStyle: {
              color: "#424242",
              "margin-top": "10px",
              "font-size": "22px !important",
            },
          },
          [_vm._v("数据处理中...")]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "编辑商品标题",
            width: "60%",
            "destroy-on-close": "",
            visible: true,
          },
          on: { close: _vm.dialogClose },
        },
        [
          _c("div", { staticClass: "dialog-content" }, [
            _c("div", [_vm._v("已选商品数" + _vm._s(_vm.list.length))]),
            _vm._v(" "),
            _c("div", { staticClass: "edit-item" }, [
              _c("div", { staticClass: "edit-item-title" }, [
                _vm._v("文字替换:"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "edit-item-info" },
                [
                  _c("el-input", {
                    staticStyle: { width: "90px" },
                    attrs: { size: "mini", placeholder: "请输入" },
                    model: {
                      value: _vm.replaceBefore,
                      callback: function ($$v) {
                        _vm.replaceBefore = $$v
                      },
                      expression: "replaceBefore",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v("为")]),
                  _vm._v(" "),
                  _c("el-input", {
                    staticStyle: { width: "90px" },
                    attrs: { size: "mini", placeholder: "请输入" },
                    model: {
                      value: _vm.replaceAfter,
                      callback: function ($$v) {
                        _vm.replaceAfter = $$v
                      },
                      expression: "replaceAfter",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini" },
                      on: {
                        click: function ($event) {
                          return _vm.executeHandle(1)
                        },
                      },
                    },
                    [_vm._v("执行")]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "edit-item" }, [
              _c("div", { staticClass: "edit-item-title" }, [
                _vm._v("开头加:"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "edit-item-info" },
                [
                  _c("el-input", {
                    staticStyle: { width: "150px" },
                    attrs: { size: "mini", placeholder: "请输入" },
                    model: {
                      value: _vm.startText,
                      callback: function ($$v) {
                        _vm.startText = $$v
                      },
                      expression: "startText",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini" },
                      on: {
                        click: function ($event) {
                          return _vm.executeHandle(2)
                        },
                      },
                    },
                    [_vm._v("执行")]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "edit-item" }, [
              _c("div", { staticClass: "edit-item-title" }, [
                _vm._v("结尾加:"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "edit-item-info" },
                [
                  _c("el-input", {
                    staticStyle: { width: "150px" },
                    attrs: { size: "mini", placeholder: "请输入" },
                    model: {
                      value: _vm.endText,
                      callback: function ($$v) {
                        _vm.endText = $$v
                      },
                      expression: "endText",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini" },
                      on: {
                        click: function ($event) {
                          return _vm.executeHandle(3)
                        },
                      },
                    },
                    [_vm._v("执行")]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticStyle: { "margin-top": "8px" } },
              [_c("el-button", { attrs: { size: "mini" } }, [_vm._v("清除")])],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "info-title" }, [_vm._v("商品详细信息")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "goods-info" },
              _vm._l(_vm.list, function (item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "goods-info-item" },
                  [
                    _c("div", { staticClass: "goods-info-item-head" }, [
                      _c("div", { staticClass: "item-head-left" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "item-goods-info" }, [
                        _c("div", { staticClass: "goods-name" }, [
                          _vm._v(_vm._s(item.goodsName)),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "goods-id" }, [
                          _vm._v("商品ID:" + _vm._s(item.goodsId)),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "goods-info-item-title" }, [
                      _c("div", { staticClass: "item-title-title" }, [
                        _c("span", [_vm._v("*")]),
                        _vm._v("商品标题："),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "item-title-info" },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: {
                                size: "mini",
                                placeholder: "请输入",
                                maxlength: "60",
                              },
                              model: {
                                value: item.goodsName,
                                callback: function ($$v) {
                                  _vm.$set(item, "goodsName", $$v)
                                },
                                expression: "item.goodsName",
                              },
                            },
                            [
                              _c("template", { slot: "append" }, [
                                _c(
                                  "span",
                                  {
                                    class:
                                      _vm.charactersCount(item.goodsName) > 60
                                        ? "title-num"
                                        : "",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.charactersCount(item.goodsName)
                                      ) + "/60"
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]
                )
              }),
              0
            ),
          ]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: {
                    click: function ($event) {
                      return _vm.dialogClose()
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.editConfirm },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "编辑商品属性",
            width: "60%",
            visible: true,
            "z-index": 1000,
          },
          on: { open: _vm.dialogOpen, close: _vm.dialogClose },
        },
        [
          _c("div", { staticClass: "dialog-content" }, [
            _c("div", [_vm._v("已选商品数" + _vm._s(_vm.list.length))]),
            _vm._v(" "),
            _c("div", { staticClass: "edit-item" }, [
              _c(
                "div",
                { staticClass: "edit-item-info" },
                [
                  _c("span", [_vm._v("统一设置属性值:")]),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      attrs: { size: "mini", placeholder: "请选择" },
                      on: { change: _vm.changeAttr },
                      model: {
                        value: _vm.attrId,
                        callback: function ($$v) {
                          _vm.attrId = $$v
                        },
                        expression: "attrId",
                      },
                    },
                    _vm._l(_vm.attrList, function (item) {
                      return _c("el-option", {
                        key: item.refPid,
                        attrs: { label: item.name, value: item.refPid },
                      })
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _c("span", [_vm._v("：")]),
                  _vm._v(" "),
                  !_vm.attrId
                    ? _c("span", [_vm._v("请先在左边选择属性名")])
                    : _c(
                        "el-select",
                        {
                          attrs: {
                            size: "mini",
                            filterable: "",
                            placeholder: "请选择",
                          },
                          on: { change: _vm.changeAttrObj },
                          model: {
                            value: _vm.attrStrName,
                            callback: function ($$v) {
                              _vm.attrStrName = $$v
                            },
                            expression: "attrStrName",
                          },
                        },
                        _vm._l(_vm.attrStrList, function (item) {
                          return _c("el-option", {
                            key: item.vid,
                            attrs: { label: item.value, value: item },
                          })
                        }),
                        1
                      ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini" },
                      on: {
                        click: function ($event) {
                          return _vm.executeHandle(1)
                        },
                      },
                    },
                    [_vm._v("执行")]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "edit-item" }, [
              _c(
                "div",
                { staticClass: "edit-item-info" },
                [
                  _c("span", [_vm._v("统一清除属性值:")]),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      attrs: { size: "mini", placeholder: "请选择" },
                      model: {
                        value: _vm.clearAttr,
                        callback: function ($$v) {
                          _vm.clearAttr = $$v
                        },
                        expression: "clearAttr",
                      },
                    },
                    _vm._l(_vm.attrList, function (item) {
                      return _c("el-option", {
                        key: item.refPid,
                        attrs: { label: item.name, value: item.refPid },
                      })
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini" },
                      on: {
                        click: function ($event) {
                          return _vm.executeHandle(2)
                        },
                      },
                    },
                    [_vm._v("执行")]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "info-title" }, [_vm._v("商品详细信息")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "goods-info" },
              _vm._l(_vm.list, function (item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "goods-info-item" },
                  [
                    _c("div", { staticClass: "goods-info-item-head" }, [
                      _c("div", { staticClass: "item-head-left" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "item-goods-info" }, [
                        _c("div", { staticClass: "goods-name" }, [
                          _vm._v(_vm._s(item.goodsName)),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "goods-id" }, [
                          _vm._v("商品ID:" + _vm._s(item.goodsId)),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "goods-attr-item" }, [
                      _c("div", { staticClass: "item-title" }, [
                        _c("span", [_vm._v("*")]),
                        _vm._v("商品标题："),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "item-attr" }, [
                        _c("div", { staticClass: "other" }, [
                          _vm._v("其他属性"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "attr-each-box" },
                          _vm._l(item.catList, function (catItem, catIndex) {
                            return _c(
                              "div",
                              { key: catIndex, staticClass: "attr-each" },
                              [
                                _c("div", [_vm._v(_vm._s(catItem.name))]),
                                _vm._v(" "),
                                _c(
                                  "el-select",
                                  {
                                    ref: "select",
                                    refInFor: true,
                                    attrs: {
                                      size: "mini",
                                      "value-key": "vid",
                                      filterable: "",
                                      placeholder: "请选择",
                                    },
                                    on: {
                                      change: function (e) {
                                        _vm.handleSelectChange(e, catItem)
                                      },
                                    },
                                    model: {
                                      value: catItem.catListSelected,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          catItem,
                                          "catListSelected",
                                          $$v
                                        )
                                      },
                                      expression: "catItem.catListSelected",
                                    },
                                  },
                                  _vm._l(catItem.values, function (optionItem) {
                                    return _c("el-option", {
                                      key: optionItem.vid,
                                      attrs: {
                                        label: optionItem.value,
                                        value: Number(optionItem.vid),
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            )
                          }),
                          0
                        ),
                      ]),
                    ]),
                  ]
                )
              }),
              0
            ),
          ]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { size: "mini" }, on: { click: _vm.dialogClose } },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.editConfirm },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
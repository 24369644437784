"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.exchangeApi = exchangeApi;
exports.exchangeCodeApi = exchangeCodeApi;
exports.getOderMegApi = getOderMegApi;
exports.getPointListApi = getPointListApi;
exports.getPostListApi = getPostListApi;
exports.paymentApi = paymentApi;
exports.paymentWXApi = paymentWXApi;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * 获取订单
 * @param prams
 */
function getOderMegApi(data) {
  return (0, _request.default)({
    url: '/admin/pdd/setMeal/getOrderStatus',
    method: 'POST',
    data: data
  });
}

/**
 * 获取支付列表
 * @param prams
 */
function getPostListApi(params) {
  return (0, _request.default)({
    url: '/admin/pdd/setMeal/list',
    method: 'GET',
    params: params
  });
}

/**
 * 获取积分列表
 * @param prams
 */
function getPointListApi(params) {
  return (0, _request.default)({
    url: '/admin/pdd/integralConfig/list',
    method: 'GET',
    params: params
  });
}

/**
 * 兑换积分
 * @param prams
 */
function exchangeApi(data) {
  return (0, _request.default)({
    url: '/admin/redeemcode/exchange',
    method: 'POST',
    data: data
  });
}

/**
 * 支付方法--wx
 * @param prams
 */
function paymentWXApi(data) {
  return (0, _request.default)({
    url: '/admin/pdd/wxPay/pay',
    method: 'POST',
    data: data
  });
}

/**
 * 兑换卡券
 */

function exchangeCodeApi(data) {
  return (0, _request.default)({
    url: '/admin/redeemcode/exchange',
    method: 'POST',
    data: data
  });
}

/**
 * 支付方法
 * @param prams
 */
function paymentApi(data) {
  return (0, _request.default)({
    url: '/admin/pdd/alipay/pay',
    method: 'POST',
    data: data
  });
}
/**
 * 支付方法
 * @param prams
 */
// export function paymentApi(data) {
//   return request({
//     url: '/admin/pdd/alipay/returnUrl',
//     method: 'GET',
//     data
//   })
// }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: { title: "编辑商品价格", width: "60%", visible: true },
          on: { close: _vm.dialogClose },
        },
        [
          _c("div", { staticClass: "dialog-content" }, [
            _c("div", [_vm._v("已选商品数" + _vm._s(_vm.list.length))]),
            _vm._v(" "),
            _c("div", { staticClass: "edit-item" }, [
              _c(
                "div",
                { staticClass: "edit-item-info" },
                [
                  _c("span", [_vm._v("自定义公式")]),
                  _vm._v(" "),
                  _c("el-input", {
                    staticStyle: { width: "250px" },
                    attrs: { size: "mini", placeholder: "请输入" },
                    model: {
                      value: _vm.customize,
                      callback: function ($$v) {
                        _vm.customize = $$v
                      },
                      expression: "customize",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      attrs: { size: "mini", placeholder: "请选择" },
                      model: {
                        value: _vm.priceOptionsId,
                        callback: function ($$v) {
                          _vm.priceOptionsId = $$v
                        },
                        expression: "priceOptionsId",
                      },
                    },
                    _vm._l(_vm.priceOptions, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.text, value: item.id },
                      })
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini", disabled: !_vm.customize },
                      on: {
                        click: function ($event) {
                          return _vm.executeHandle(1)
                        },
                      },
                    },
                    [_vm._v("执行")]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "edit-item" }, [
              _c(
                "div",
                { staticClass: "edit-item-info" },
                [
                  _c("span", [_vm._v("统一处理价格")]),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100px" },
                      attrs: { size: "mini", placeholder: "请选择" },
                      model: {
                        value: _vm.countText1,
                        callback: function ($$v) {
                          _vm.countText1 = $$v
                        },
                        expression: "countText1",
                      },
                    },
                    _vm._l(_vm.count1, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.text, value: item.id },
                      })
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _c("el-input", {
                    staticStyle: { width: "120px" },
                    attrs: {
                      size: "mini",
                      placeholder: "请输入",
                      "aria-placeholder": "",
                    },
                    model: {
                      value: _vm.multiple,
                      callback: function ($$v) {
                        _vm.multiple = $$v
                      },
                      expression: "multiple",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v("倍")]),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100px" },
                      attrs: { size: "mini", placeholder: "请选择" },
                      model: {
                        value: _vm.countText2,
                        callback: function ($$v) {
                          _vm.countText2 = $$v
                        },
                        expression: "countText2",
                      },
                    },
                    _vm._l(_vm.count2, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.text, value: item.id },
                      })
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _c("el-input", {
                    staticStyle: { width: "120px" },
                    attrs: {
                      size: "mini",
                      placeholder: "请输入",
                      "aria-placeholder": "",
                    },
                    model: {
                      value: _vm.difference,
                      callback: function ($$v) {
                        _vm.difference = $$v
                      },
                      expression: "difference",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v("元")]),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100px" },
                      attrs: { size: "mini", placeholder: "请选择" },
                      model: {
                        value: _vm.handlePriceType,
                        callback: function ($$v) {
                          _vm.handlePriceType = $$v
                        },
                        expression: "handlePriceType",
                      },
                    },
                    _vm._l(_vm.priceOptions, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.text, value: item.id },
                      })
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini" },
                      on: {
                        click: function ($event) {
                          return _vm.executeHandle(2)
                        },
                      },
                    },
                    [_vm._v("执行")]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "edit-item" }, [
              _c(
                "div",
                { staticClass: "edit-item-info" },
                [
                  _c("span", [_vm._v("统一价格小数处理")]),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "150px" },
                      attrs: { size: "mini", placeholder: "请选择" },
                      model: {
                        value: _vm.decimalOptionId,
                        callback: function ($$v) {
                          _vm.decimalOptionId = $$v
                        },
                        expression: "decimalOptionId",
                      },
                    },
                    _vm._l(_vm.decimalOptions, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.text, value: item.id },
                      })
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini" },
                      on: {
                        click: function ($event) {
                          return _vm.executeHandle(3)
                        },
                      },
                    },
                    [_vm._v("执行")]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "edit-item" }, [
              _c(
                "div",
                { staticClass: "edit-item-info" },
                [
                  _c("span", [_vm._v("统一设置价格")]),
                  _vm._v(" "),
                  _c("el-input", {
                    staticStyle: { width: "120px" },
                    attrs: {
                      size: "mini",
                      placeholder: "请输入",
                      "aria-placeholder": "",
                    },
                    model: {
                      value: _vm.uniformPrice,
                      callback: function ($$v) {
                        _vm.uniformPrice = $$v
                      },
                      expression: "uniformPrice",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "150px" },
                      attrs: { size: "mini", placeholder: "请选择" },
                      model: {
                        value: _vm.uniformPriceOptionId,
                        callback: function ($$v) {
                          _vm.uniformPriceOptionId = $$v
                        },
                        expression: "uniformPriceOptionId",
                      },
                    },
                    _vm._l(_vm.priceOptions, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.text, value: item.id },
                      })
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini" },
                      on: {
                        click: function ($event) {
                          return _vm.executeHandle(4)
                        },
                      },
                    },
                    [_vm._v("执行")]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "edit-item" }, [
              _c(
                "div",
                { staticClass: "edit-item-info" },
                [
                  _c("span", [_vm._v("价格增减随机值")]),
                  _vm._v(" "),
                  _c("el-input", {
                    staticStyle: { width: "120px" },
                    attrs: {
                      size: "mini",
                      placeholder: "请输入",
                      "aria-placeholder": "",
                    },
                    model: {
                      value: _vm.randomNumMin,
                      callback: function ($$v) {
                        _vm.randomNumMin = $$v
                      },
                      expression: "randomNumMin",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v("~")]),
                  _vm._v(" "),
                  _c("el-input", {
                    staticStyle: { width: "120px" },
                    attrs: {
                      size: "mini",
                      placeholder: "请输入",
                      "aria-placeholder": "",
                    },
                    model: {
                      value: _vm.randomNumMax,
                      callback: function ($$v) {
                        _vm.randomNumMax = $$v
                      },
                      expression: "randomNumMax",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "120px" },
                      attrs: { size: "mini", placeholder: "请选择" },
                      model: {
                        value: _vm.randomPriceOptionId,
                        callback: function ($$v) {
                          _vm.randomPriceOptionId = $$v
                        },
                        expression: "randomPriceOptionId",
                      },
                    },
                    _vm._l(_vm.priceOptions, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.text, value: item.id },
                      })
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini" },
                      on: {
                        click: function ($event) {
                          return _vm.executeHandle(5)
                        },
                      },
                    },
                    [_vm._v("执行")]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "edit-item" }, [
              _c(
                "div",
                { staticClass: "edit-item-info" },
                [
                  _c("span", [_vm._v("批量设置规格值包含")]),
                  _vm._v(" "),
                  _c("el-input", {
                    staticStyle: { width: "180px" },
                    attrs: {
                      size: "mini",
                      placeholder: "请输入",
                      "aria-placeholder": "",
                    },
                    model: {
                      value: _vm.skuValue,
                      callback: function ($$v) {
                        _vm.skuValue = $$v
                      },
                      expression: "skuValue",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v("的SKU价格设置为")]),
                  _vm._v(" "),
                  _c("el-input", {
                    staticStyle: { width: "120px" },
                    attrs: {
                      size: "mini",
                      placeholder: "请输入",
                      "aria-placeholder": "",
                    },
                    model: {
                      value: _vm.skuPrice,
                      callback: function ($$v) {
                        _vm.skuPrice = $$v
                      },
                      expression: "skuPrice",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "120px" },
                      attrs: { size: "mini", placeholder: "请选择" },
                      model: {
                        value: _vm.skuValueOptionId,
                        callback: function ($$v) {
                          _vm.skuValueOptionId = $$v
                        },
                        expression: "skuValueOptionId",
                      },
                    },
                    _vm._l(_vm.priceOptions, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.text, value: item.id },
                      })
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini" },
                      on: {
                        click: function ($event) {
                          return _vm.executeHandle(6)
                        },
                      },
                    },
                    [_vm._v("执行")]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "info-title" }, [_vm._v("商品")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "goods-detail" },
              _vm._l(_vm.list, function (item, index) {
                return _c(
                  "el-collapse",
                  {
                    key: index,
                    model: {
                      value: _vm.activeNames,
                      callback: function ($$v) {
                        _vm.activeNames = $$v
                      },
                      expression: "activeNames",
                    },
                  },
                  [
                    _c(
                      "el-collapse-item",
                      { attrs: { title: "", name: index } },
                      [
                        _c("template", { slot: "title" }, [
                          _c("div", { staticClass: "goods-detail-info" }, [
                            _c("img", {
                              attrs: { src: item.goodsPicture, alt: "" },
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "goods-detail-text" }, [
                              _c("div", { staticClass: "goods-name" }, [
                                _vm._v(_vm._s(item.goodsName)),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "goods-id" }, [
                                _vm._v("商品ID:" + _vm._s(item.goodsId)),
                              ]),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "price-unit" }, [
                          _c("div", { staticClass: "unit-head" }, [
                            _c("div", { staticClass: "head-title" }, [
                              _vm._v("SKU 名称（规格值）"),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "head-title" }, [
                              _vm._v("拼单价"),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "head-title" }, [
                              _vm._v("单买价"),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "unit-body" },
                            _vm._l(item.skuList, function (skuItem) {
                              return _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "name" }, [
                                  _vm._v(_vm._s(skuItem.spec[0].specName)),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "price" },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        size: "mini",
                                        placeholder: "请输入",
                                      },
                                      model: {
                                        value: skuItem.multiPrice,
                                        callback: function ($$v) {
                                          _vm.$set(skuItem, "multiPrice", $$v)
                                        },
                                        expression: "skuItem.multiPrice",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "price" },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        size: "mini",
                                        placeholder: "请输入",
                                      },
                                      model: {
                                        value: skuItem.price,
                                        callback: function ($$v) {
                                          _vm.$set(skuItem, "price", $$v)
                                        },
                                        expression: "skuItem.price",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ])
                            }),
                            0
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "unit-reference" },
                            [
                              _c("div", [_vm._v("参考价")]),
                              _vm._v(" "),
                              _c("el-input", {
                                staticStyle: { width: "220px" },
                                attrs: { size: "mini", placeholder: "请输入" },
                                model: {
                                  value: item.marketPrice,
                                  callback: function ($$v) {
                                    _vm.$set(item, "marketPrice", $$v)
                                  },
                                  expression: "item.marketPrice",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ],
                      2
                    ),
                  ],
                  1
                )
              }),
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { size: "mini" }, on: { click: _vm.dialogClose } },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.editConfirm },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }